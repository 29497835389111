import { gql } from "graphql.macro";
import * as JWT from "jwt-client";
import { cordovaHttpFetchImpl } from "../../utils/cordovaFetch";
import { getServerUrl } from "../core/api";
import { executeQuery } from "./apolloClient";
import { IDatabase } from "./database/IDatabase";

const GET_TEMPLATES = gql`
  {
    templates {
      id
      type
      version
    }
  }
`;
export async function updateTemplates(database: IDatabase, types?: string[]) {
  const currentTemplates = await database.getTemplates();
  const latestTemplates: Array<{
    version: number;
    type: string;
  }> = (await executeQuery(GET_TEMPLATES)).templates;
  const updatedTemplates = latestTemplates.filter(
    (template) =>
      !currentTemplates.some(
        (localTemplate) =>
          localTemplate.type === template.type &&
          localTemplate.version === template.version
      )
  );

  for (const template of updatedTemplates) {
    if (types != null && !types.includes(template.type)) {
      continue;
    }
    try {
      const res = await cordovaHttpFetchImpl(
        `${await getServerUrl()}/api/templates/${template.type}`,
        {
          mode: "cors",
          headers: {
            Authorization: JWT.get() ?? "",
          },
        }
      );
      if (res.status === 200) {
        await database.putTemplate({
          type: template.type,
          version: template.version,
          blob: await res.blob(),
        });
      } else {
        console.error(
          `Could not get template for type ${template.type}`,
          res.status
        );
      }
    } catch (e) {
      console.error(`Could not update template for type ${template.type}`, e);
    }
  }
}
