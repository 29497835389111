import { openDB } from "idb";
import { nanoid } from "nanoid";
import {
  Configuration,
  createAttachmentId,
  emptyConfiguration,
} from "@wurzel/uzb-sync";
import {
  Attachment,
  IDatabase,
  LocalMove,
  RemoteMove,
  SynchronizedMove,
  Template,
} from "./IDatabase";

export class IndexedDB implements IDatabase {
  async getConfiguration(): Promise<Configuration & { lastUpdated: Date }> {
    const db = await this.getDatabase();
    const configuration = await db.getAll("configuration");
    db.close();
    return {
      packingMaterials:
        configuration.find((c) => c.key === "packingMaterials")?.value ?? [],
      rooms: configuration.find((c) => c.key === "rooms")?.value ?? [],
      staffCategories:
        configuration.find((c) => c.key === "staffCategories")?.value ??
        emptyConfiguration.staffCategories,
      calculationParameters:
        configuration.find((c) => c.key === "calculationParameters")?.value ??
        emptyConfiguration.calculationParameters,
      vehicles:
        configuration.find((c) => c.key === "vehicles")?.value ??
        emptyConfiguration.vehicles,
      lastUpdated:
        (configuration.find((c) => c.key === "lastUpdated")?.value as Date) ||
        null,
      contractors:
        configuration.find((c) => c.key === "contractors")?.value ?? [],
      invoiceItems:
        configuration.find((c) => c.key === "invoiceItems")?.value ?? [],
    };
  }

  async setConfiguration(configuration: Configuration): Promise<void> {
    const db = await this.getDatabase();
    const tx = await db.transaction("configuration", "readwrite");
    const store = tx.objectStore("configuration");
    for (const key of [
      "rooms",
      "packingMaterials",
      "staffCategories",
      "calculationParameters",
      "vehicles",
      "contractors",
      "invoiceItems",
    ] as Array<keyof Configuration>) {
      await store.put({ key, value: configuration[key] });
    }
    await store.put({ key: "lastUpdated", value: new Date() });
    await tx.done;
    db.close();
  }

  async getMoves(): Promise<Array<RemoteMove | LocalMove>> {
    const db = await this.getDatabase();
    const tx = await db.transaction(["moves", "remoteMoves"], "readonly");
    const localMoves = (await tx.objectStore("moves").getAll()) as LocalMove[];
    const remoteMoves = (await tx
      .objectStore("remoteMoves")
      .getAll()) as RemoteMove[];
    await tx.done;
    db.close();

    const localMovesByRemoteId = new Map<string, LocalMove>();
    for (const move of localMoves) {
      if (move.remoteId != null) {
        localMovesByRemoteId.set(move.remoteId, move);
      }
    }
    const remoteMovesById = new Map<string, RemoteMove>();
    for (const move of remoteMoves) {
      remoteMovesById.set(move.id, move);
    }

    const knownMoves: Array<RemoteMove | LocalMove> = [];
    for (const move of localMoves) {
      const remoteMove = move.remoteId
        ? remoteMovesById.get(move.remoteId)
        : null;
      if (remoteMove) {
        knownMoves.push({
          ...move,
          lastUpdated: remoteMove.lastUpdated,
        });
      } else {
        knownMoves.push(move);
      }
    }
    for (const move of remoteMoves) {
      if (!localMovesByRemoteId.has(move.id)) {
        knownMoves.push(move);
      }
    }

    return knownMoves;
  }

  async setRemoteMoves(moves: RemoteMove[]) {
    const db = await this.getDatabase();
    const tx = await db.transaction(["remoteMoves"], "readwrite");
    const remoteMovesStore = tx.objectStore("remoteMoves");
    await remoteMovesStore.clear();
    for (const move of moves) {
      await remoteMovesStore.put(move);
    }
    await tx.done;
    db.close();
  }

  async getRecentMoves(count: number): Promise<LocalMove[]> {
    const db = await this.getDatabase();
    const tx = await db.transaction("moves", "readonly");
    const moves = tx.objectStore("moves");
    const index = moves.index("lastOpened");

    const recentMoves: LocalMove[] = [];
    let cursor = await index.openCursor(null, "prev");
    while (cursor && recentMoves.length < count) {
      recentMoves.push(cursor.value);
      cursor = await cursor.continue();
    }

    await tx.done;
    db.close();
    return recentMoves;

    // TODO map lastUpdated dates to the dates of the corresponding remote moves, as in getMoves()
  }

  async setMoveLastOpened(move: LocalMove, lastOpened: Date): Promise<void> {
    const db = await this.getDatabase();
    await db.put("moves", {
      ...move,
      lastOpened,
    });
    db.close();
  }

  async getMove(id: string): Promise<LocalMove> {
    const db = await this.getDatabase();
    const move = await db.get("moves", id);
    db.close();
    return move;
  }

  async addMoveAction(move: LocalMove, action: any) {
    const db = await this.getDatabase();
    if (move.remoteId == null) {
      // move not yet synchronized, no need to store the action
      await db.put("moves", move);
    } else {
      const tx = await db.transaction(["moveActions", "moves"], "readwrite");
      const moveActions = tx.objectStore("moveActions");
      await moveActions.put({
        moveId: move.id,
        actionId: nanoid(8),
        date: new Date(),
        action,
      });
      const moves = tx.objectStore("moves");
      await moves.put(move);
      await tx.done;
    }
    db.close();
  }

  async getMoveActions(id: string): Promise<any[]> {
    const db = await this.getDatabase();
    const actions = await db.getAllFromIndex("moveActions", "moveId", id);
    db.close();
    return actions;
  }

  async setMoveSynchronized(
    move: SynchronizedMove,
    synchronizedActions: Array<{ moveId: string; actionId: string }> = []
  ): Promise<void> {
    const db = await this.getDatabase();
    const tx = await db.transaction(["moveActions", "moves"], "readwrite");
    await tx.objectStore("moves").put(move);
    if (synchronizedActions.length > 0) {
      const moveActions = tx.objectStore("moveActions");
      for (const action of synchronizedActions) {
        await moveActions.delete([action.moveId, action.actionId]);
      }
    }
    await tx.done;
    db.close();
  }

  async getAttachment(id: string): Promise<Blob> {
    const db = await this.getDatabase();
    const attachment = await db.get("moveAttachments", id);
    db.close();
    return attachment?.blob;
  }

  async addAttachment(move: LocalMove, blob: Blob): Promise<Attachment> {
    return this.storeAttachment({
      id: createAttachmentId(),
      moveId: move.id,
      blob,
    });
  }

  async storeAttachment(attachment: Attachment): Promise<Attachment> {
    const db = await this.getDatabase();
    await db.put("moveAttachments", attachment);
    db.close();
    return attachment;
  }

  async removeAttachment(id: string): Promise<void> {
    const db = await this.getDatabase();
    await db.delete("moveAttachments", id);
    db.close();
  }

  async getAttachmentIds(
    move: LocalMove,
    localOnly?: boolean
  ): Promise<string[]> {
    const db = await this.getDatabase();
    const attachments = (await db.getAllKeysFromIndex(
      "moveAttachments",
      "moveId",
      move.id
    )) as string[];
    db.close();
    return attachments;
  }

  async getLocalOnlyAttachments(move: LocalMove): Promise<Attachment[]> {
    const db = await this.getDatabase();
    const attachments = (await db.getAllFromIndex(
      "moveAttachments",
      "moveId",
      move.id
    )) as Attachment[];

    db.close();
    return attachments.filter((a) => !a.uploaded);
  }

  async setAttachmentUploaded(attachment: Attachment): Promise<Attachment> {
    const updatedAttachment: Attachment = {
      ...attachment,
      uploaded: true,
    };
    const db = await this.getDatabase();
    await db.put("moveAttachments", updatedAttachment);
    db.close();
    return updatedAttachment;
  }

  async getTemplates(): Promise<Template[]> {
    const db = await this.getDatabase();
    const templates = (await db.getAll("templates")) as Template[];
    db.close();
    return templates;
  }

  async getTemplate(type: string): Promise<Template> {
    const db = await this.getDatabase();
    const template = (await db.get("templates", type)) as Template;
    db.close();
    return template;
  }

  async putTemplate(template: Template): Promise<void> {
    const db = await this.getDatabase();
    await db.put("templates", template);
    db.close();
  }

  private getDatabase() {
    return openDB("uzb", 8, {
      upgrade(db, oldVersion, newVersion, tx) {
        if (oldVersion < 1) {
          db.createObjectStore("configuration", {
            keyPath: "key",
          });
        }
        if (oldVersion < 2) {
          db.createObjectStore("moves", {
            keyPath: "id",
          });
          db.createObjectStore("moveActions", {
            keyPath: ["moveId", "actionId"],
          });
        }
        if (oldVersion < 4) {
          const objectStore = tx.objectStore("moves");
          objectStore.createIndex("lastOpened", "lastOpened", {
            unique: false,
          });
        }
        if (oldVersion < 5) {
          const objectStore = tx.objectStore("moveActions");
          objectStore.createIndex("moveId", "moveId", {
            unique: false,
          });
        }
        if (oldVersion < 6) {
          db.createObjectStore("remoteMoves", {
            keyPath: "id",
          });
        }
        if (oldVersion < 7) {
          const objectStore = db.createObjectStore("moveAttachments", {
            keyPath: "id",
          });
          objectStore.createIndex("moveId", "moveId", {
            unique: false,
          });
        }
        if (oldVersion < 8) {
          db.createObjectStore("templates", {
            keyPath: "type",
          });
        }
      },
    });
  }
}
