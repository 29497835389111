import React from "react";
import {
  withStyles,
  Button,
  createStyles,
  WithStyles,
  Theme,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const styles = (theme: Theme) =>
  createStyles({
    resetButton: {
      margin: theme.spacing(1, -1, -1, 0),
      float: "right",
      zIndex: 42,
    },
    startDate: {
      width: "100%",
      marginBottom: theme.spacing(1),
    },
    endDate: {
      width: "100%",
      flex: 1,
    },
  });

interface Props {
  filter: {
    type: "dateRange";
    minDate?: Date | null;
    maxDate?: Date | null;
    inactive: boolean;
  };
  onUpdateFilter: (filter: {
    type: "dateRange";
    minDate?: Date | null;
    maxDate?: Date | null;
    inactive: boolean;
  }) => void;
  onClose: () => void;
}

let DateRangeFilter = class extends React.Component<
  Props & WithStyles<typeof styles>
> {
  handleChangeStartDate = (
    date: MaterialUiPickersDate,
    value?: string | null
  ) => {
    if (value) {
      this.props.onUpdateFilter({
        type: "dateRange",
        minDate: date, // TODO use picker's date utils to get the date from this date?
        maxDate: this.props.filter.maxDate,
        inactive: false,
      });
    } else {
      this.props.onUpdateFilter({
        type: "dateRange",
        minDate: undefined,
        maxDate: this.props.filter.maxDate,
        inactive: this.props.filter.maxDate == null,
      });
    }
  };

  handleChangeEndDate = (
    date: MaterialUiPickersDate,
    value?: string | null
  ) => {
    if (value) {
      this.props.onUpdateFilter({
        type: "dateRange",
        minDate: this.props.filter.minDate,
        maxDate: date, // TODO use picker's date utils to get the date from this date?
        inactive: false,
      });
    } else {
      this.props.onUpdateFilter({
        type: "dateRange",
        minDate: this.props.filter.minDate,
        maxDate: undefined,
        inactive: this.props.filter.minDate == null,
      });
    }
  };

  handleReset = () => {
    this.props.onUpdateFilter({
      type: "dateRange",
      minDate: undefined,
      maxDate: undefined,
      inactive: true,
    });
    this.props.onClose();
  };

  render() {
    const {
      classes,
      filter: { minDate, maxDate },
    } = this.props;

    // Setting the value of KeyboardDatePicker to null or "" would display the current date,
    // setting it to \u200b (zero width space) shows an error. This is a workaround.
    const minKeyboardDatePickerNullFix: Partial<KeyboardDatePickerProps> = {};
    if (minDate == null) {
      minKeyboardDatePickerNullFix.helperText = undefined;
      minKeyboardDatePickerNullFix.error = false;
      minKeyboardDatePickerNullFix.InputLabelProps = { shrink: false };
    }
    const maxKeyboardDatePickerNullFix: Partial<KeyboardDatePickerProps> = {};
    if (maxDate == null) {
      maxKeyboardDatePickerNullFix.helperText = undefined;
      maxKeyboardDatePickerNullFix.error = false;
      maxKeyboardDatePickerNullFix.InputLabelProps = { shrink: false };
    }

    return (
      <React.Fragment>
        <KeyboardDatePicker
          variant="inline"
          label="Startdatum"
          value={minDate}
          inputValue={minDate == null ? "\u200b" : undefined}
          onChange={this.handleChangeStartDate}
          format="dd.MM.yyyy"
          className={classes.startDate}
          invalidDateMessage="Ungültiges Datum"
          {...minKeyboardDatePickerNullFix}
        />
        <KeyboardDatePicker
          variant="inline"
          label="Enddatum"
          value={maxDate}
          inputValue={maxDate == null ? "\u200b" : undefined}
          onChange={this.handleChangeEndDate}
          format="dd.MM.yyyy"
          className={classes.endDate}
          invalidDateMessage="Ungültiges Datum"
          {...maxKeyboardDatePickerNullFix}
        />
        <Button
          variant="text"
          size="small"
          className={classes.resetButton}
          onClick={this.handleReset}
        >
          Filter zurücksetzen
        </Button>
      </React.Fragment>
    );
  }
};

/**
 * Check if Date A is greater or equal to Date B, ignoring the time.
 * @param {Date} a Date A
 * @param {Date} b Date B
 */
function isDateGreaterOrEqual(
  a: Date | undefined | null,
  b: Date | undefined | null
) {
  if (b == null) return true;
  if (a == null) return false; // a == null, b != null

  // Copy dates to not affect originals
  const d0 = new Date(a);
  const d1 = new Date(b);

  // Set to noon to ignore time
  d0.setHours(12, 0, 0, 0);
  d1.setHours(12, 0, 0, 0);

  return d0.getTime() >= d1.getTime();
}

function filter(
  filter: typeof DateRangeFilterConfig["defaultFilterValue"],
  column: {
    filter: { getDate: (row: any) => Date; minDate?: Date; maxDate?: Date };
  },
  row: any
) {
  return (
    (filter.minDate == null ||
      isDateGreaterOrEqual(column.filter.getDate(row), filter.minDate)) &&
    (filter.maxDate == null ||
      isDateGreaterOrEqual(filter.maxDate, column.filter.getDate(row)))
  );
}

const DateRangeFilterConfig = {
  popoverComponent: withStyles(styles)(DateRangeFilter),
  defaultFilterValue: {
    type: "dateRange",
    minDate: null,
    maxDate: null,
    inactive: true,
  },
  filter,
};

export default DateRangeFilterConfig;
