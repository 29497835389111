import React, { FocusEvent, useCallback, useState } from "react";
import {
  createStyles,
  fade,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { OverflowMenu } from "@wa/werkstoff-core";
import { ChevronRight, Plus, Minus, TrashCanOutline } from "mdi-material-ui";
import { Room, RoomItem } from "@wurzel/uzb-sync";
import { useCurrentMove } from "../containers/Move";
import AddRoomDialog from "./AddRoomDialog";
import AddFurnitureDialog from "./AddFurnitureDialog";
import { useSnackbar } from "material-ui-snackbar-provider";
import { ApplyOnBlurTextField } from "../../../components/ApplyOnBlurTextField";
import EditRoomDialog from "./EditRoomDialog";
import EditFurnitureDialog from "./EditFurnitureDialog";
import { useSynchronizedData } from "../../synchronization/SynchronizedDataContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "hidden",
      marginRight: theme.spacing(-1),
      paddingRight: theme.spacing(1),
    },
    columnView: {
      display: "flex",
      flex: 1,
      margin: theme.spacing(0, -2, 0, 0),
      overflow: "hidden",
      "& > *:not(:last-child)": {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    listColumn: {
      flex: 1,
      justifySelf: "stretch",
      overflowY: "auto",
      paddingTop: 0,
      paddingBottom: 0,
      "&:first-child": {
        maxWidth: 360,
      },
      "& > .MuiListSubheader-root": {
        background: theme.palette.background.default,
      },
      "& > .MuiListItem-root": {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },
    },
    rightListIcon: {
      minWidth: theme.spacing(3),
    },
    listHeaderIconButtons: {
      position: "absolute",
      right: theme.spacing(0.5),
      top: 0,
      "& > $listHeaderIconButton": {
        position: "relative",
        right: "unset",
      },
    },
    listHeaderIconButton: {
      padding: theme.spacing(1.5),
      position: "absolute",
      right: theme.spacing(0.5),
    },
    listPlaceholder: {
      padding: theme.spacing(2),
    },
    itemsHeader: {
      display: "flex",
      margin: theme.spacing(0, 1, 0, 2),
      color: theme.palette.text.hint,
      "& div:first-child": {
        flex: 1,
      },
      "& div:last-child": {
        width: 140,
        textAlign: "center",
      },
    },
    itemListItem: {
      padding: theme.spacing(0, 1),
      marginLeft: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(2)}px)`,
      "&:nth-child(odd)": {
        background: fade(theme.palette.action.hover, 0.05),
        borderRadius: theme.shape.borderRadius,
      },
    },
    amountInput: {
      display: "flex",
      alignItems: "center",
      width: 140,
      marginRight: theme.spacing(-1),
      "& .MuiTextField-root": {
        margin: theme.spacing(0),
        "& input": {
          textAlign: "center",
          lineHeight: `${theme.spacing(5)}px`,
          height: theme.spacing(5),
          padding: 0,
        },
      },
      "& .MuiIconButton-root": {
        padding: theme.spacing(1),
      },
    },
  });

interface FurnitureProps {
  children: React.ReactNode;
}

function Furniture({ classes }: FurnitureProps & WithStyles<typeof styles>) {
  const snackbar = useSnackbar();
  const { move: localMove, update } = useCurrentMove();
  const move = localMove.data;
  const { database } = useSynchronizedData();

  const [selectedRoomId, setSelectedRoomId] = useState<string | null>(null);
  const selectedRoom =
    selectedRoomId != null
      ? move.rooms.find((r) => r.id === selectedRoomId)
      : null;

  const [showAddRoomDialog, setShowAddRoomDialog] = useState(false);
  const handleAddRoom = useCallback(
    (room: Room) => {
      update({
        type: "addRoom",
        payload: room,
      });
      setSelectedRoomId(room.id);
      setShowAddRoomDialog(false);
    },
    [update]
  );

  const [showEditRoomDialog, setShowEditRoomDialog] = useState(false);
  const handleEditRoom = useCallback(
    ({ name }) => {
      update({
        type: "setRoomField",
        payload: {
          roomId: selectedRoomId,
          field: "name",
          value: name,
        },
      });
      setShowEditRoomDialog(false);
    },
    [update, selectedRoomId]
  );

  const handleDeleteRoom = (id: string) => {
    if (id === selectedRoomId) {
      setSelectedRoomId(null);
    }
    const room = move.rooms.find((r) => r.id === id);
    if (room != null) {
      update({
        type: "removeRoom",
        payload: id,
      });
      snackbar.showMessage(
        `Der Raum "${room.name}" wurde entfernt.`,
        "Rückgängig",
        () => {
          update({
            type: "addRoom",
            payload: room,
          });
          snackbar.showMessage(
            `Der Raum "${room.name}" wurde wiederhergestellt.`
          );
          setSelectedRoomId(room.id);
        },
        undefined,
        () => {
          Promise.all(
            room.items
              .flatMap((item) => item.photos ?? [])
              .map((photo) => database.removeAttachment(photo.id))
          ).catch((e) => {
            console.warn(
              "Could not remove old furniture photos of a deleted room",
              e
            );
          });
        }
      );
    }
  };

  const handleSelectRoom = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setSelectedRoomId(e.currentTarget.dataset.room || null);
  }, []);

  const [showAddItemDialog, setShowAddItemDialog] = useState(false);
  const handleAddFurniture = useCallback(
    async (
      item: Omit<RoomItem, "photos"> & {
        photos: Array<{ id: string; blob?: Blob }>;
      }
    ) => {
      const newAttachments = await Promise.all(
        item.photos
          .filter((photo) => photo.blob != null)
          .map((photo) => database.addAttachment(localMove, photo.blob as Blob))
      );

      update({
        type: "addRoomItem",
        payload: {
          roomId: selectedRoomId,
          item: {
            ...item,
            photos: newAttachments.map((attachment) => ({ id: attachment.id })),
          },
        },
      });
      setShowAddItemDialog(false);
    },
    [update, selectedRoomId, database, localMove]
  );

  const [selectedItem, setSelectedItem] = useState<RoomItem | null>(null);
  const [showEditItemDialog, setShowEditItemDialog] = useState(false);
  const handleEditFurniture = useCallback(
    async ({
      id,
      ...item
    }: Partial<Omit<RoomItem, "photos">> & {
      id: string;
      photos?: Array<{ id: string; blob?: Blob }>;
    }) => {
      const oldPhotos = selectedItem?.id === id ? selectedItem.photos : null;
      const photos = item.photos;
      if (photos) {
        // photos were updated
        const newAttachments = await Promise.all(
          item.photos
            ?.filter((photo) => photo.blob != null)
            .map((photo) =>
              database.addAttachment(localMove, photo.blob as Blob)
            ) ?? []
        );

        // remove removed photos from the database
        if (oldPhotos != null) {
          Promise.all(
            oldPhotos
              .filter((photo) => !photos.some((p) => p.id === photo.id))
              .map((photo) => database.removeAttachment(photo.id))
          ).catch((e) => {
            console.warn("Could not remove old furniture photos", e);
          });
        }

        item.photos = [
          ...photos.filter((photo) => photo.blob == null), // old photos
          ...newAttachments.map((attachment) => ({ id: attachment.id })), // new photos
        ];
      }

      update({
        type: "editRoomItem",
        payload: {
          roomId: selectedRoomId,
          itemId: id,
          updates: item,
        },
      });
      setShowEditItemDialog(false);
    },
    [selectedRoomId, update, database, localMove, selectedItem]
  );

  const handleChangeAmount = useCallback(
    (
      value: string | number,
      e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const roomId = e.currentTarget.dataset.room;
      const itemId = e.currentTarget.dataset.item;
      update({
        type: "setRoomItemAmount",
        payload: {
          roomId,
          itemId,
          amount: Math.max(
            0,
            Math.round((parseFloat(`${value}`) || 0) * 100) / 100
          ),
        },
      });
    },
    [update]
  );

  const handleDeleteItem = useCallback(
    (roomId: string, item: RoomItem) => {
      update({
        type: "removeRoomItem",
        payload: {
          roomId,
          itemId: item.id,
        },
      });
      snackbar.showMessage(
        `Der Gegenstand "${item.name}" wurde entfernt`,
        "Rückgängig",
        () => {
          update({
            type: "addRoomItem",
            payload: {
              roomId,
              item,
            },
          });
        },
        undefined,
        () => {
          if (item.photos) {
            Promise.all(
              item.photos.map((photo) => database.removeAttachment(photo.id))
            ).catch((e) => {
              console.warn(
                "Could not remove old furniture photos of a deleted room item",
                e
              );
            });
          }
        }
      );
    },
    [database, snackbar, update]
  );

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary">
        Umzugsgutliste
      </Typography>
      <div className={classes.columnView}>
        <List className={classes.listColumn}>
          <ListSubheader color="primary">
            Räume ({move.rooms.length} Räume,{" "}
            {move.rooms
              .reduce(
                (sum, room) =>
                  sum +
                  room.items.reduce(
                    (roomSum, item) => roomSum + (item.amount * item.size || 0),
                    0
                  ),
                0
              )
              .toLocaleString()}{" "}
            RE)
            <IconButton
              className={classes.listHeaderIconButton}
              onClick={() => setShowAddRoomDialog(true)}
            >
              <Plus />
            </IconButton>
          </ListSubheader>
          {move.rooms.map((room) => (
            <ListItem
              key={room.id}
              dense
              selected={selectedRoomId === room.id}
              data-room={room.id}
              onClick={handleSelectRoom}
              button
            >
              <ListItemText
                primary={room.name}
                secondary={`${room.items.reduce(
                  (sum, item) => sum + item.amount,
                  0
                )} Gegenstände, ${room.items
                  .reduce(
                    (sum, item) => sum + (item.amount * item.size || 0),
                    0
                  )
                  .toLocaleString()} RE`}
              />
              <ListItemIcon className={classes.rightListIcon}>
                <ChevronRight />
              </ListItemIcon>
            </ListItem>
          ))}
          {move.rooms.length === 0 && (
            <Typography
              className={classes.listPlaceholder}
              variant="body2"
              align="center"
            >
              Sie haben für diesen Umzug noch keine Räume konfiguriert.
              <br />
              Klicken Sie auf &bdquo;+&ldquo;, um den ersten Raum hinzuzufügen.
            </Typography>
          )}
        </List>
        <List className={classes.listColumn}>
          {selectedRoom != null && (
            <>
              <ListSubheader color="primary">
                {selectedRoom.name} (
                {selectedRoom.items
                  .reduce(
                    (sum, item) => sum + (item.amount * item.size || 0),
                    0
                  )
                  .toLocaleString()}{" "}
                RE)
                <div className={classes.listHeaderIconButtons}>
                  <IconButton
                    className={classes.listHeaderIconButton}
                    onClick={() => setShowAddItemDialog(true)}
                  >
                    <Plus />
                  </IconButton>
                  <OverflowMenu
                    className={classes.listHeaderIconButton}
                    color="default"
                  >
                    <MenuItem onClick={() => setShowEditRoomDialog(true)}>
                      Raum umbenennen
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteRoom(selectedRoom.id)}>
                      Raum entfernen
                    </MenuItem>
                  </OverflowMenu>
                </div>
              </ListSubheader>
              {selectedRoom?.items.length === 0 ? (
                <Typography
                  className={classes.listPlaceholder}
                  variant="body2"
                  align="center"
                >
                  Sie haben für diesen Raum noch keine Gegenstände ausgewählt.
                  <br />
                  Klicken Sie auf &bdquo;+&ldquo;, um den ersten Gegenstand für
                  diesen Raum hinzuzufügen.
                </Typography>
              ) : (
                <div className={classes.itemsHeader}>
                  <Typography variant="caption" component="div">
                    Gegenstand
                  </Typography>
                  <Typography variant="caption" component="div">
                    Stück
                  </Typography>
                </div>
              )}
              {selectedRoom?.items.map((item) => (
                <ListItem
                  dense
                  key={item.id}
                  className={classes.itemListItem}
                  onClick={() => {
                    setSelectedItem(item);
                    setShowEditItemDialog(true);
                  }}
                >
                  <ListItemText>
                    {item.name}
                    {(item.assembly || item.disassembly || item.disposal) && (
                      <>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="div"
                        >
                          {[
                            item.disassembly && "Demontage",
                            item.assembly && "Montage",
                            item.disposal && "Entsorgung",
                          ]
                            .filter((task) => !!task)
                            .join(", ")}
                        </Typography>
                      </>
                    )}
                  </ListItemText>
                  <div className={classes.amountInput}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.amount > 0) {
                          update({
                            type: "setRoomItemAmount",
                            payload: {
                              roomId: selectedRoom.id,
                              itemId: item.id,
                              amount: Math.max(
                                0,
                                Math.round((item.amount - 1) * 100) / 100
                              ),
                            },
                          });
                        } else {
                          handleDeleteItem(selectedRoom.id, item);
                        }
                      }}
                      onContextMenu={(e) => {
                        // TODO unter iOS funktioniert das nicht für long-press
                        e.preventDefault();
                        handleDeleteItem(selectedRoom.id, item);
                      }}
                    >
                      {item.amount === 0 ? <TrashCanOutline /> : <Minus />}
                    </IconButton>
                    <ApplyOnBlurTextField
                      value={item.amount}
                      onChange={handleChangeAmount}
                      type="number"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        "data-item": item.id,
                        "data-room": selectedRoom.id,
                      }}
                      margin="none"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        update({
                          type: "setRoomItemAmount",
                          payload: {
                            roomId: selectedRoom.id,
                            itemId: item.id,
                            amount: Math.round((item.amount + 1) * 100) / 100,
                          },
                        });
                      }}
                    >
                      <Plus />
                    </IconButton>
                  </div>
                </ListItem>
              ))}
            </>
          )}
        </List>
      </div>

      <AddRoomDialog
        fullWidth
        open={showAddRoomDialog}
        onClose={() => setShowAddRoomDialog(false)}
        onAddRoom={handleAddRoom}
        maxWidth="lg"
        disableBackdropClick
      />

      <EditRoomDialog
        room={selectedRoom}
        fullWidth
        open={showEditRoomDialog}
        onClose={() => setShowEditRoomDialog(false)}
        onEditRoom={handleEditRoom}
        maxWidth="lg"
        disableBackdropClick
      />

      <AddFurnitureDialog
        open={showAddItemDialog}
        onClose={() => setShowAddItemDialog(false)}
        onAddFurniture={handleAddFurniture}
        maxWidth="lg"
        fullWidth
        predefinedRoomId={selectedRoom?.predefinedId}
        disableBackdropClick
      />

      <EditFurnitureDialog
        open={showEditItemDialog}
        onClose={() => setShowEditItemDialog(false)}
        onEditItem={handleEditFurniture}
        item={selectedItem}
        maxWidth="lg"
        fullWidth
        disableBackdropClick
      />
    </div>
  );
}

export default withStyles(styles)(Furniture);
