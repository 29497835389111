import { getApolloClient } from "./apolloClient";
import { gql } from "graphql.macro";
import {
  Attachment,
  IDatabase,
  LocalMove,
  RemoteMove,
  SynchronizedMove,
} from "./database/IDatabase";
import { moveReducer } from "@wurzel/uzb-sync";
import * as JWT from "jwt-client";
import { getServerUrl } from "../core/api";
import { cordovaHttpFetchImpl } from "../../utils/cordovaFetch";

/**
 * Create a new move.
 * @param move Move
 * @returns New move
 */
async function createMove(move: LocalMove): Promise<SynchronizedMove> {
  const apolloClient = await getApolloClient();
  const createdMove = await apolloClient?.mutate({
    mutation: gql`
      mutation CreateMove($move: JSONObject!) {
        createMove(move: $move) {
          id
          updatedAt
          data
        }
      }
    `,
    variables: { move: move.data },
    errorPolicy: "all",
  });
  if ((createdMove.errors?.length ?? 0) > 0) {
    throw createdMove.errors?.[0];
  }
  const remoteMove = createdMove.data.createMove;
  return {
    id: move.id,
    lastOpened: move.lastOpened,
    lastEdited: move.lastEdited,
    remoteId: remoteMove.id,
    lastUpdated: remoteMove.lastUpdated,
    data: remoteMove.data,
  };
}

/**
 * Synchronize the given move (applying the given actions).
 * @param move Move
 * @param actions Actions
 * @returns Updated move
 */
async function patchMove(
  move: LocalMove,
  actions: any[]
): Promise<SynchronizedMove> {
  const apolloClient = await getApolloClient();
  const createdMove = await apolloClient?.mutate({
    mutation: gql`
      mutation CreateMove($id: ID!, $actions: [JSONObject!]!) {
        synchronizeMove(id: $id, actions: $actions) {
          id
          updatedAt
          data
        }
      }
    `,
    variables: { id: move.remoteId, actions },
    errorPolicy: "all",
  });
  if ((createdMove.errors?.length ?? 0) > 0) {
    throw createdMove.errors?.[0];
  }
  const remoteMove = createdMove.data.synchronizeMove;
  return {
    id: move.id,
    lastOpened: move.lastOpened,
    lastEdited: move.lastEdited,
    remoteId: remoteMove.id,
    lastUpdated: remoteMove.lastUpdated,
    data: remoteMove.data,
  };
}

/**
 * Fetch the move with the given ID from the server.
 * @param id ID of a move
 * @returns Move or null if it doesn't exist
 */
export async function fetchMove(id: string): Promise<SynchronizedMove | null> {
  const apolloClient = await getApolloClient();
  const getMove = await apolloClient?.query({
    query: gql`
      query GetMove($id: ID!) {
        move(id: $id) {
          id
          updatedAt
          data
        }
      }
    `,
    variables: { id },
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });
  if ((getMove.errors?.length ?? 0) > 0) {
    throw getMove.errors?.[0];
  }
  if (getMove.data?.move) {
    const remoteMove = getMove.data.move;
    return {
      id,
      lastOpened: new Date(),
      data: remoteMove.data,
      remoteId: remoteMove.id,
      lastUpdated: remoteMove.lastUpdated,
    };
  }
  return null;
}

/**
 * Synchronize the given move and return the updated move.
 * @param move Move
 * @param database Local database
 * @returns Synchronized move
 */
export async function synchronizeMove(
  move: LocalMove,
  database: IDatabase
): Promise<{ move: SynchronizedMove; failedAttachments: string[] }> {
  let synchronizedMove: SynchronizedMove;
  if (move.remoteId == null) {
    console.log("Create move", move);
    synchronizedMove = await createMove(move);
    await database.setMoveSynchronized(synchronizedMove);
  } else {
    let actions = await database.getMoveActions(move.id);
    console.log("Synchronize move", { move, actions });
    synchronizedMove = await patchMove(move, actions);
    await database.setMoveSynchronized(synchronizedMove, actions);
  }

  const failedAttachments: string[] = [];
  const attachments = await database.getLocalOnlyAttachments(move);
  for (const attachment of attachments) {
    try {
      await uploadAttachment(synchronizedMove, attachment);
    } catch (e) {
      if (e.statusCode === 409) {
        // conflict, i.e. already uploaded but somehow not marked as uploaded locally
      } else {
        console.error(`Could not upload attachment ${attachment.id}`, { e });
        failedAttachments.push(attachment.id);
        continue;
      }
    }
    try {
      await database.setAttachmentUploaded(attachment);
    } catch (e) {
      console.error(
        `Could not mark attachment ${attachment.id} as uploaded`,
        e
      );
    }
  }

  // the move could have been modified while syncing
  const actions = await database.getMoveActions(move.id);
  if (actions.length > 0) {
    console.log(`${actions.length} actions missed while syncing`);
    const data = actions.reduce(
      (acc, action) => moveReducer(acc, action.action),
      synchronizedMove.data
    );
    if (data !== synchronizedMove.data) {
      synchronizedMove = {
        ...synchronizedMove,
        data,
      };
      await database.setMoveSynchronized(synchronizedMove);
    }
  }
  return { move: synchronizedMove, failedAttachments };
}

async function uploadAttachment(
  move: SynchronizedMove,
  attachment: Attachment
) {
  const serverUrl = await getServerUrl();

  const fd = new FormData();
  fd.append("moveId", move.remoteId);
  fd.append("attachmentId", attachment.id);
  fd.append("file", attachment.blob);

  const response = await cordovaHttpFetchImpl(
    `${serverUrl}/api/moves/attachments`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: JWT.get(),
      },
      body: fd,
    }
  );
  if (response.status !== 201) {
    const err: any = new Error(
      `${response.status} ${response.statusText}: ${await response
        .text()
        .catch(() => "no response")}`
    );
    err.statusCode = response.status;
    throw err;
  }
}

export async function downloadAttachment(
  moveId: string,
  attachmentId: string
): Promise<Attachment> {
  const serverUrl = await getServerUrl();
  const response = await cordovaHttpFetchImpl(
    `${serverUrl}/api/moves/attachments/${attachmentId}`,
    {
      mode: "cors",
      headers: {
        Authorization: JWT.get() ?? "",
      },
    }
  );
  if (response.status === 200) {
    const blob = await response.blob();
    return {
      id: attachmentId,
      blob,
      moveId,
      uploaded: true,
    };
  } else {
    throw new Error(
      `Downloading the attachment failed with status ${response.status}`
    );
  }
}

/**
 * Get all moves from the server. The IDs are not mapped to local IDs.
 * @returns All moves that the server knows
 */
export async function getMoves(): Promise<RemoteMove[]> {
  const apolloClient = await getApolloClient();
  const getMoves = await apolloClient?.query({
    query: gql`
      query GetMoves {
        moves {
          id
          updatedAt
          customer {
            firstname
            lastname
          }
          loadingAddresses {
            city
          }
          unloadingAddresses {
            city
          }
        }
      }
    `,
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });
  if ((getMoves.errors?.length ?? 0) > 0) {
    throw getMoves.errors?.[0];
  }
  return (
    getMoves.data.moves?.map((move: any) => ({
      id: move.id,
      lastUpdated: move.updatedAt,
      customer: move.customer,
      loadingAddresses: move.loadingAddresses,
      unloadingAddresses: move.unloadingAddresses,
    })) ?? []
  );
}
