import { createContext, useContext } from "react";

export interface User {
  id: string;
  username: string;
  fullName: string;
  signaturePrefix: string;
}

export interface IAuthenticationContext {
  showLoginDialog: (loginCallback?: () => void) => void;
  isLoggedIn: boolean;
  user: User | null;
  logout: () => void;
}

export const AuthenticationContext = createContext<IAuthenticationContext | null>(
  null
);

export function useAuthenticationContext() {
  const value = useContext(AuthenticationContext);
  if (value == null) {
    throw new Error(
      "useAuthenticationContext may only be used inside of AuthenticationContext"
    );
  }
  return value;
}
