import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#2D3586",
    },
    secondary: {
      main: "#88B831",
    },
    type: "light",
    background: {
      default: "#f2f2f7",
      paper: "#fefffe",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
    ].join(","),
    button: {
      textTransform: "none",
    },
  },
});
