import React from "react";
import { DatePicker, DatePickerProps } from "@material-ui/pickers";

export default function LocalizedDatePicker(props: DatePickerProps) {
  return (
    <DatePicker
      format="dd.MM.yyyy"
      clearLabel="Leeren"
      cancelLabel="Abbrechen"
      {...props}
    />
  );
}
