import React from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  IconButton,
  createStyles,
  AppBarProps,
  Theme,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden",
      paddingTop: "var(--safe-area-inset-top)",
      color: theme.palette.primary.main,
      position: "fixed", // prevent moving on overflow in Safari
      top: 0,
      backgroundColor: theme.palette.background.paper,
      minHeight: theme.spacing(5.5),
    },
    "@global": {
      body: {
        paddingTop: `calc(${theme.spacing(
          5.5
        )}px + var(--safe-area-inset-top))`,
      },
      "#root": {
        height: `calc(100vh - ${theme.spacing(5.5)}px - var(--safe-area-inset-top))`,
      },
    },
    flex: {
      flex: 1,
    },
    drawerButton: {
      marginLeft: theme.spacing(-3),
      marginRight: 12,
      padding: theme.spacing(1.25),
    },
    rightButton: {
      marginLeft: 12,
      marginRight: theme.spacing(-3),
      padding: theme.spacing(1.25),
    },
    toolbar: {
      minHeight: theme.spacing(5),
    },
    title: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      flex: 1,
      whiteSpace: "nowrap",
      textAlign: "center",
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: `${theme.spacing(5.5)}px`,
    },
  });

interface Props extends AppBarProps {
  title?: string;
  onButtonClick?: React.MouseEventHandler;
  onRightButtonClick?: React.MouseEventHandler;
  children?: React.ReactNode;
  leftIcon?: React.ElementType;
  rightIcon?: React.ElementType;
}

function AppBar({
  classes,
  className,
  title,
  onButtonClick,
  onRightButtonClick,
  children,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  ...other
}: Props & WithStyles<typeof styles>) {
  return (
    <MuiAppBar
      position="static"
      className={clsx(classes.root, className)}
      {...other}
    >
      <Toolbar className={classes.toolbar}>
        {LeftIcon && (
          <IconButton
            onClick={onButtonClick}
            color="inherit"
            className={classes.drawerButton}
          >
            <LeftIcon />
          </IconButton>
        )}
        <div className={classes.title}>
          {title && (
            <Typography variant="h6" color="inherit" className={classes.title}>
              {title}
            </Typography>
          )}
        </div>
        {children}
        {RightIcon && (
          <IconButton
            onClick={onRightButtonClick}
            color="inherit"
            className={classes.rightButton}
          >
            <RightIcon />
          </IconButton>
        )}
      </Toolbar>
    </MuiAppBar>
  );
}

export default withStyles(styles)(AppBar);
