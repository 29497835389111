import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Configuration, emptyConfiguration } from "@wurzel/uzb-sync";
import PQueue from "p-queue";
import { IDatabase, LocalMove, Template } from "./database/IDatabase";
import { IndexedDB } from "./database/IndexedDB";
import { updateConfiguration } from "./synchronizedConfiguration";
import { updateTemplates } from "./synchronizedTemplates";

interface SynchronizedContextValue {
  configuration: Configuration & { lastUpdated?: Date };
  isConfigurationLoaded: boolean;
  synchronize: () => Promise<void>;
  database: IDatabase;
  updateMove: (move: LocalMove, action: any) => Promise<void>;
  updateTemplate: (type: string) => Promise<Template>;
}

const SynchronizedDataContext = createContext<SynchronizedContextValue | null>(
  null
);

export function SynchronizedDataProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const moveActionsQueue = useRef(new PQueue({ concurrency: 1 }));
  const database = useRef<IDatabase>(new IndexedDB());
  const [configuration, setConfiguration] = useState<Configuration>(
    emptyConfiguration
  );
  useEffect(() => {
    async function updateConfig() {
      try {
        setConfiguration(await database.current.getConfiguration());
      } catch (e) {
        console.error("Could not load configuration", e);
      }
    }
    updateConfig();
  }, []);
  const synchronize = useCallback(async () => {
    try {
      await updateConfiguration(database.current);
      setConfiguration(await database.current.getConfiguration());
      await updateTemplates(database.current);
    } catch (e) {
      console.error("Synchronization error", e);
      throw e;
    }
  }, []);
  const updateMove = useCallback((move: LocalMove, action: any) => {
    return moveActionsQueue.current.add(() =>
      database.current.addMoveAction(move, action)
    );
  }, []);
  const updateTemplate = useCallback(async (type: string) => {
    await updateTemplates(database.current, [type]);
    return database.current.getTemplate(type);
  }, []);
  const contextValue = useMemo(() => {
    return {
      configuration,
      isConfigurationLoaded: configuration !== emptyConfiguration,
      synchronize,
      database: database.current,
      updateMove,
      updateTemplate,
    };
  }, [configuration, synchronize, updateMove, updateTemplate]);

  return (
    <SynchronizedDataContext.Provider value={contextValue}>
      {children}
    </SynchronizedDataContext.Provider>
  );
}

export function useSynchronizedData(): SynchronizedContextValue {
  const value = useContext(SynchronizedDataContext);
  if (value == null) {
    throw new Error(
      "useSynchronizedData can only be used inside the SynchronizedDataProvider"
    );
  }
  return value;
}
