import { FilesystemDirectory } from "@capacitor/core";
import * as ExcelJS from "exceljs";
import safeAreaInsets from "safe-area-insets";
import { getCordovaPlugin, isNative } from "../../../../utils/capacitor";
import { Template } from "../../../synchronization/database/IDatabase";
import { documents } from "./documentTypes";

export const xlsxMimeType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export async function getWorkbook(
  template: Template
): Promise<ExcelJS.Workbook> {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(await getBlobAsArrayBuffer(template.blob));
  return workbook;
}

export async function shareFile(
  blob: Blob,
  filename: string,
  uniqueFilename: string,
  targetElement?: HTMLElement
) {
  if (isNative()) {
    const { writeFile } = await import("capacitor-blob-writer");
    const { uri } = await writeFile({
      path: uniqueFilename,
      directory: FilesystemDirectory.Cache,
      data: blob,
    });
    await new Promise((resolve, reject) => {
      getCordovaPlugin("fileOpener2").showOpenWithDialog(uri, blob.type, {
        error: () => {
          reject();
        },
        success: () => {
          resolve(uri);
        },
        position: targetElement
          ? [
              targetElement.getBoundingClientRect().right,
              targetElement.getBoundingClientRect().top +
                targetElement.getBoundingClientRect().height / 2 +
                safeAreaInsets.bottom,
            ]
          : [0, 0],
      });
    });
  } else {
    const { saveAs } = await import("file-saver");
    saveAs(blob, filename);
  }
}

export async function shareWorkbook(
  workbook: ExcelJS.Workbook,
  template: Template,
  targetElement?: HTMLElement
) {
  const data = await workbook.xlsx.writeBuffer();
  const blob = new Blob([data], { type: xlsxMimeType });
  return shareFile(
    blob,
    `${getTemplateName(template.type)}.xlsx`,
    `${getTemplateName(template.type)}-${Date.now()}.xlsx`,
    targetElement
  );
}

function getBlobAsArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  if (!Blob.prototype.arrayBuffer) {
    // there is no Blob.prototype.arrayBuffer in iOS <14
    // https://gist.github.com/hanayashiki/8dac237671343e7f0b15de617b0051bd
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result as ArrayBuffer);
      };
      fr.onerror = () => {
        reject(new Error("Reading the blob failed"));
      };
      fr.readAsArrayBuffer(blob);
    });
  }
  return blob.arrayBuffer();
}

export function getTemplateName(type: string) {
  return documents.find((d) => d.type === type)?.title ?? "Unbekannte Vorlage";
}
