export const euro = (e: number | null | undefined) =>
  `${(e || 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} €`;

export const percentage = (e: number, digits = 2) =>
  `${(e * 100).toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })} %`;

export const hours = (e: number) =>
  `${e.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} Std.`;
