import React from "react";
import { CloudOffOutline, CloudOutline } from "mdi-material-ui";
// @ts-ignore
import EnhancedTable, { createEnhancedTable } from "@wa/werkstoff-data-table";
// @ts-ignore
import withLocalStorageConfig from "@wa/werkstoff-data-table/lib/withLocalStorageConfig";
// @ts-ignore
import withVerticalScroll from "@wa/werkstoff-data-table/lib/withVerticalScroll";
// @ts-ignore
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import {
  LocalMove,
  RemoteMove,
} from "../../synchronization/database/IDatabase";
import { Move } from "@wurzel/uzb-sync";
import DateRangeFilter from "../../../components/DateRangeFilter";

const movesTableColumns = [
  {
    id: "status",
    title: "",
    hideable: false,
    sortable: false,
    content: (move: LocalMove | RemoteMove): React.ReactNode => {
      const isLocalOnly = !("customer" in move) && move.remoteId == null;
      const isRemoteOnly = "customer" in move && move.localId == null;
      if (isLocalOnly) {
        return (
          <CloudOffOutline
            style={{ width: 18, height: 18, verticalAlign: "middle" }}
          />
        );
      } else if (isRemoteOnly) {
        return (
          <CloudOutline
            style={{ width: 18, height: 18, verticalAlign: "middle" }}
          />
        );
      } else {
        return null;
      }
    },
  },
  {
    id: "customer",
    title: "Kunde",
    contentString: (move: LocalMove | RemoteMove): string => {
      const moveData: Move | RemoteMove = "customer" in move ? move : move.data;
      return moveData.customer?.firstname || moveData.customer?.lastname
        ? `${moveData.customer?.firstname ?? ""} ${moveData.customer
            ?.lastname ?? ""}`.trim()
        : "(Unbekannter Kunde)";
    },
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "from",
    title: "Beladestelle",
    contentString: (move: LocalMove | RemoteMove): string => {
      const moveData: Move | RemoteMove = "customer" in move ? move : move.data;
      return (
        (moveData.loadingAddresses as Array<{ city?: string }>)
          .map((address) => address.city)
          .filter((city) => !!city)
          .join("/") || "(unbekannt)"
      );
    },
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "to",
    title: "Entladestelle",
    contentString: (move: LocalMove | RemoteMove): string => {
      const moveData: Move | RemoteMove = "customer" in move ? move : move.data;
      return (
        (moveData.unloadingAddresses as Array<{ city?: string }>)
          .map((address) => address.city)
          .filter((city) => !!city)
          .join("/") || "(unbekannt)"
      );
    },
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "lastUpdated",
    title: "Zuletzt bearbeitet",
    contentString: (move: LocalMove | RemoteMove): string =>
      getLastUpdatedDate(move)?.toLocaleString() ?? "",
    compare: (a: LocalMove | RemoteMove, b: LocalMove | RemoteMove) =>
      (getLastUpdatedDate(a)?.getTime() ?? 0) -
      (getLastUpdatedDate(b)?.getTime() ?? 0),
    filter: {
      type: DateRangeFilter,
      getDate: getLastUpdatedDate,
    },
  },
];

export default createEnhancedTable(movesTableColumns, {
  component: withLocalStorageConfig("uzb-moves")(
    withVerticalScroll()(EnhancedTable)
  ),
  rowId: (row: LocalMove | RemoteMove) => row.id,
  orderBy: "lastUpdated",
  order: "desc",
});

function getLastUpdatedDate(move: LocalMove | RemoteMove): Date | null {
  if (move.lastUpdated) {
    if (!("customer" in move) && move.lastEdited) {
      // synchronized move
      return new Date(
        Math.max(
          new Date(move.lastEdited).getTime(),
          new Date(move.lastUpdated).getTime()
        )
      );
    }
    return new Date(move.lastUpdated);
  } else if (!("customer" in move) && move.lastEdited) {
    // local-only move
    return new Date(move.lastEdited);
  }
  return null;
}
