import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Room } from "@wurzel/uzb-sync";

interface EditRoomDialogProps extends DialogProps {
  room?: Room | null;
  onEditRoom: (update: { name: string }) => void;
  onClose: () => void;
}

export default function EditRoomDialog({
  classes,
  room,
  onEditRoom,
  onClose,
  open,
  ...other
}: EditRoomDialogProps) {
  const [name, setName] = useState("");
  useEffect(() => {
    if (open) {
      setName(room?.name ?? "");
    }
  }, [room, open]);

  return (
    <Dialog {...other} open={open} onClose={onClose}>
      <DialogTitle>Raum bearbeiten</DialogTitle>
      <DialogContent>
        <TextField
          variant="filled"
          margin="dense"
          label="Bezeichnung"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          autoFocus
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} tabIndex={1}>
          Abbrechen
        </Button>
        <Button
          onClick={() => onEditRoom({ name })}
          disabled={name.trim().length === 0}
          color="primary"
          tabIndex={0}
        >
          Übernehmen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
