import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonBase,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { AsyncButton } from "@wa/werkstoff-core";
import { useSynchronizedData } from "../../synchronization/SynchronizedDataContext";
import { Room } from "@wurzel/uzb-sync";
import { CloudSyncOutline } from "mdi-material-ui";
import { useSnackbar } from "material-ui-snackbar-provider";
import useIsOnline from "../../../hooks/useIsOnline";
import { useAuthenticationContext } from "../../core/AuthenticationContext";

const styles = (theme: Theme) =>
  createStyles({
    roomList: {
      display: "grid",
      gridGap: theme.spacing(2),
      gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
      overflowY: "auto",
    },
    roomListItem: {
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
      textAlign: "left",
      justifyContent: "left",
      height: theme.spacing(5),
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "block",
      ...theme.typography.body2,
    },
    listPlaceholder: {
      padding: theme.spacing(2),
    },
    dialogTitleButton: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  });

interface AddRoomDialogProps extends DialogProps {
  onAddRoom: (room: Room) => void;
  onClose: () => void;
}

function AddRoomDialog({
  classes,
  onAddRoom,
  onClose,
  open,
  ...other
}: AddRoomDialogProps & WithStyles<typeof styles>) {
  const snackbar = useSnackbar();
  const { configuration, synchronize } = useSynchronizedData();
  const isOnline = useIsOnline();

  const handleAddPredefinedRoom = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const predefinedId = e.currentTarget.dataset.room;
      const predefinedRoom = configuration.rooms.find(
        (r) => r.id === predefinedId
      );
      if (predefinedRoom != null) {
        onAddRoom({
          id: uuidv4(),
          name: predefinedRoom.name,
          predefinedId,
          items: [],
        });
      }
    },
    [configuration, onAddRoom]
  );

  const [isCustomRoom, setIsCustomRoom] = useState(false);
  const [name, setName] = useState("");
  useEffect(() => {
    if (open) {
      setIsCustomRoom(false);
      setName("");
    }
  }, [open]);

  const handleAddCustomRoom = useCallback(() => {
    onAddRoom({
      id: uuidv4(),
      name,
      items: [],
    });
  }, [onAddRoom, name]);

  const auth = useAuthenticationContext();

  const handleUpdateRooms = useCallback(async () => {
    try {
      await synchronize();
      snackbar.showMessage("Die vordefinierte Räume wurden aktualisiert.");
    } catch (e) {
      if (e.extensions?.exception?.status === 401) {
        snackbar.showMessage(
          "Zur Aktualisierung der vordefinierten Räume ist eine Anmeldung erforderlich."
        );
        auth.showLoginDialog(handleUpdateRooms);
      } else {
        snackbar.showMessage(
          "Die vordefinierten Räume konnten nicht aktualisiert werden."
        );
      }
    }
  }, [synchronize, snackbar, auth]);

  return (
    <Dialog {...other} open={open} onClose={onClose}>
      <DialogTitle>
        Raum hinzufügen
        <AsyncButton
          component={IconButton}
          onClick={handleUpdateRooms}
          className={classes.dialogTitleButton}
          disabled={!isOnline}
        >
          <CloudSyncOutline />
        </AsyncButton>
      </DialogTitle>
      <DialogContent className={classes.roomList}>
        {isCustomRoom ? (
          <TextField
            variant="filled"
            margin="dense"
            label="Bezeichnung"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            autoFocus
            required
          />
        ) : (
          <>
            {[...configuration.rooms]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((room) => (
                <ButtonBase
                  key={room.id}
                  className={classes.roomListItem}
                  onClick={handleAddPredefinedRoom}
                  data-room={room.id}
                  component="button"
                >
                  {room.name}
                </ButtonBase>
              ))}
            <ButtonBase
              className={classes.roomListItem}
              onClick={() => setIsCustomRoom(true)}
            >
              Etwas anderes…
            </ButtonBase>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} tabIndex={1}>
          Abbrechen
        </Button>
        {isCustomRoom && (
          <Button
            onClick={handleAddCustomRoom}
            disabled={name.trim().length === 0}
            color="primary"
            tabIndex={0}
          >
            Hinzufügen
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(AddRoomDialog);
