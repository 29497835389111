import React, { useCallback, useState } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { login } from "../api";
import { useSnackbar } from "material-ui-snackbar-provider";
import { User } from "../AuthenticationContext";

const styles = (theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
    },
    formField: {
      "&:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },
    actions: {
      margin: theme.spacing(0, 2, 1),
      display: "flex",
      justifyContent: "space-between",
    },
  });

interface Props {
  open: boolean;
  onClose: () => void;
  onLoggedIn: (user: User) => void;
}

function LoginDialog({
  classes,
  open,
  onClose,
  onLoggedIn,
}: Props & WithStyles<typeof styles>) {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = useCallback(async () => {
    setLoading(true);
    try {
      const user = await login(username, password);
      onLoggedIn(user);
    } catch (e) {
      console.error("Login failed", e);
      snackbar.showMessage("Die Anmeldung ist fehlgeschlagen.");
    } finally {
      setLoading(false);
    }
  }, [snackbar, username, password, onLoggedIn]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Anmelden</DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          label="Benutzername"
          variant="filled"
          fullWidth
          className={classes.formField}
          autoFocus
          disabled={loading}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          inputProps={{
            autoComplete: "username",
            autoCapitalize: "off",
            autoCorrect: "off",
            spellCheck: "false",
          }}
        />
        <TextField
          label="Passwort"
          variant="filled"
          fullWidth
          className={classes.formField}
          type="password"
          disabled={loading}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} tabIndex={1}>
          Überspringen
        </Button>
        <Button
          color="primary"
          tabIndex={0}
          onClick={handleLogin}
          disabled={loading}
        >
          Anmelden
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(LoginDialog);
