import { useEffect, useState } from "react";
import { downloadAttachment } from "../../synchronization/moveSynchronization";
import { useSynchronizedData } from "../../synchronization/SynchronizedDataContext";
import { useCurrentMove } from "../containers/Move";

export function useAttachmentPhoto({
  id,
  skip,
}: {
  id: string | null;
  skip?: boolean;
}) {
  const { database } = useSynchronizedData();
  const { move, getTemporaryBlobUrl } = useCurrentMove();
  const [url, setUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(!skip && id != null);

  useEffect(() => {
    let stale = false;

    async function loadPhoto(id: string) {
      setLoading(true);
      try {
        let blob = await database.getAttachment(id);
        if (!stale) {
          if (!blob && move.remoteId) {
            // download and cache the attachment from the server
            const attachment = await downloadAttachment(move.remoteId, id);
            await database.storeAttachment(attachment);
            blob = attachment.blob;
          }
          if (!stale) {
            setUrl(getTemporaryBlobUrl({ id, blob }));
          }
        }
      } catch (e) {
        console.error("Could not load the photo", e);
      } finally {
        if (!stale) {
          setLoading(false);
        }
      }
    }

    if (!skip && id != null) {
      setUrl(null);
      loadPhoto(id);
      return () => {
        stale = true;
      };
    } else if (!skip) {
      setLoading(false);
      setUrl(null);
    }
  }, [id, database, move.remoteId, getTemporaryBlobUrl, skip]);
  return { url, loading: loading && !skip };
}

export function AttachmentPhoto({
  id,
  children,
}: {
  id: string | null;
  children: (args: { loading: boolean; url: string | null }) => any;
}) {
  const { url, loading } = useAttachmentPhoto({ id });
  return children({ url, loading });
}
