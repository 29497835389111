import React from "react";
import {
  Button,
  CircularProgress,
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import AppBar from "../../../components/AppBar";
import { useHistory } from "react-router-dom";
import { ChevronLeft } from "mdi-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
    container: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(3),
    },
    appBar: {
      // set the padding so that it matches the padding with one button
      // so that the title doesn't move after loading
      paddingRight: theme.spacing(2.5),
    },
  });

interface MoveLoadingProps {
  loading: boolean;
  isNew: boolean;
  title?: string;
  onRetryLoading: () => void;
}

function MoveLoading({
  classes,
  loading,
  isNew,
  title,
  onRetryLoading,
}: MoveLoadingProps & WithStyles<typeof styles>) {
  const { push } = useHistory();

  return (
    <div className={classes.root}>
      <AppBar
        title={isNew ? "Neuer Umzug" : title ?? "Umzug bearbeiten"}
        leftIcon={ChevronLeft}
        onButtonClick={() => push("/moves")}
        className={classes.appBar}
      />
      <div className={classes.container}>
        {loading ? (
          <CircularProgress disableShrink /> // without disableShrink the animation doesn't work while loading
        ) : (
          <Typography variant="body2" align="center" component="div">
            Der ausgewählte Umzug konnte nicht geöffnet werden.
            <br />
            Bitte überprüfen Sie die Internetverbindung und versuchen Sie es
            erneut.
            <br />
            <Button color="primary" onClick={onRetryLoading}>
              Wiederholen
            </Button>
          </Typography>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(MoveLoading);
