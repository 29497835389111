import React from "react";
import {
  Button,
  Checkbox,
  createStyles,
  FilledInput,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { useCurrentMove } from "../containers/Move";
import { ApplyOnBlurTextField as TextField } from "../../../components/ApplyOnBlurTextField";
import { useSynchronizedData } from "../../synchronization/SynchronizedDataContext";
import { moveActions } from "@wurzel/uzb-sync";
import clsx from "clsx";
import { Plus, TrashCanOutline } from "mdi-material-ui";
import { nanoid } from "nanoid";
import { TooltipIconButton } from "@wa/werkstoff-core";
import { useSnackbar } from "material-ui-snackbar-provider";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    formSection: {
      display: "flex",
      marginBottom: theme.spacing(3),
      "& > *:first-child": {
        width: theme.spacing(16),
        textAlign: "right",
        paddingRight: theme.spacing(3),
        lineHeight: 1,
        paddingTop: theme.spacing(1),
        "& > .MuiTypography-root": {
          lineHeight: 1,
        },
      },
      "& > div:first-child": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      },
      "& > *:last-child": {
        paddingTop: theme.spacing(0.5),
        flex: 1,
      },
    },
    flexRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1.5),
      },
    },
    mediumInput: {
      maxWidth: theme.spacing(25),
    },
    shortInput: {
      maxWidth: theme.spacing(15),
    },
    checkboxLabel: {
      marginRight: theme.spacing(3),
    },
    contractorName: {
      width: 150,
      overflow: "none",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    contractorNameInput: {
      width: `calc(150px - ${theme.spacing(2)}px)`,
      marginRight: theme.spacing(2),
    },
    contractorInput: {
      marginRight: theme.spacing(2),
    },
    removeContractorButton: {
      marginTop: theme.spacing(),
    },
  });

function Staff({ classes }: WithStyles<typeof styles>) {
  const {
    move: { data: move },
    update,
  } = useCurrentMove();
  const { configuration } = useSynchronizedData();
  const snackbar = useSnackbar();

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary">
        Personal
      </Typography>
      <div className={classes.formSection}>
        <Typography variant="overline">Personal Wurzel</Typography>
        <div>
          <div className={classes.flexRow}>
            <TextField
              fullWidth
              label="Berater"
              variant="filled"
              margin="dense"
              className={classes.shortInput}
              value={move.staff?.consultantTime ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "staff.consultantTime",
                    value,
                  },
                })
              }
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Std.</InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="PKW berechnen"
              checked={move.staff?.consultantCar !== false}
              onChange={(e, checked) =>
                update({
                  type: "setField",
                  payload: {
                    field: "staff.consultantCar",
                    value: checked,
                  },
                })
              }
              className={classes.checkboxLabel}
            />
          </div>
          <div className={classes.flexRow}>
            <TextField
              fullWidth
              label="Teamleiter"
              variant="filled"
              margin="dense"
              className={classes.shortInput}
              value={move.staff?.teamLeaders ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "staff.teamLeaders",
                    value,
                  },
                })
              }
              type="number"
            />
            <TextField
              fullWidth
              label="Träger"
              variant="filled"
              margin="dense"
              className={classes.shortInput}
              value={move.staff?.carriers ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "staff.carriers",
                    value,
                  },
                })
              }
              type="number"
            />
            <TextField
              fullWidth
              label="Aushilfen"
              variant="filled"
              margin="dense"
              className={classes.shortInput}
              value={move.staff?.temporaryEmployees ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "staff.temporaryEmployees",
                    value,
                  },
                })
              }
              type="number"
            />
            <TextField
              fullWidth
              label="Azubis"
              variant="filled"
              margin="dense"
              className={classes.shortInput}
              value={move.staff?.trainees ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "staff.trainees",
                    value,
                  },
                })
              }
              type="number"
            />
          </div>
          <div className={classes.flexRow}>
            <TextField
              fullWidth
              label="Glaspacker"
              variant="filled"
              margin="dense"
              className={classes.shortInput}
              value={move.staff?.glassPackers ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "staff.glassPackers",
                    value,
                  },
                })
              }
              type="number"
            />
            <TextField
              fullWidth
              label="Zeit"
              variant="filled"
              margin="dense"
              className={classes.shortInput}
              value={move.staff?.glassPackerTime ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "staff.glassPackerTime",
                    value,
                  },
                })
              }
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Std.</InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>{" "}
      <div className={classes.formSection}>
        <Typography variant="overline">Personal Fremdfirmen</Typography>
        <div>
          <Table size="small" padding="none">
            <TableBody>
              {configuration.contractors.map((contractor) => (
                <TableRow key={contractor.id}>
                  <TableCell className={classes.contractorName}>
                    {contractor.name}
                  </TableCell>
                  <TableCell>
                    {contractor.workType === "other" ? (
                      <TextField
                        fullWidth
                        label="Kosten"
                        variant="filled"
                        margin="dense"
                        className={classes.shortInput}
                        value={
                          move.contractors?.find((c) => c.id === contractor.id)
                            ?.cost ?? ""
                        }
                        onChange={(value) =>
                          update(
                            moveActions.setContractorCost({
                              contractorId: contractor.id,
                              cost: value as number,
                            })
                          )
                        }
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        label={
                          contractor.workType === "assembly"
                            ? "Monteure"
                            : "Träger"
                        }
                        variant="filled"
                        margin="dense"
                        className={classes.shortInput}
                        value={
                          move.contractors?.find((c) => c.id === contractor.id)
                            ?.count ?? ""
                        }
                        onChange={(value) =>
                          update(
                            moveActions.setContractorCount({
                              contractorId: contractor.id,
                              count: value as number,
                            })
                          )
                        }
                        type="number"
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {move.customContractors?.map((contractor) => (
                <TableRow key={contractor.id}>
                  <TableCell>
                    <TextField
                      fullWidth
                      label="Firma"
                      variant="filled"
                      margin="dense"
                      className={classes.contractorNameInput}
                      value={contractor.name}
                      onChange={(value) =>
                        update(
                          moveActions.updateCustomContractor({
                            id: contractor.id,
                            name: `${value}`,
                          })
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      label="Kosten"
                      variant="filled"
                      margin="dense"
                      className={clsx(
                        classes.shortInput,
                        classes.contractorInput
                      )}
                      value={contractor.cost ?? ""}
                      onChange={(value) =>
                        update(
                          moveActions.updateCustomContractor({
                            id: contractor.id,
                            cost: value as number,
                          })
                        )
                      }
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                    />
                    <FormControl
                      variant="filled"
                      margin="dense"
                      fullWidth
                      className={clsx(
                        classes.shortInput,
                        classes.contractorInput
                      )}
                    >
                      <InputLabel>Art</InputLabel>
                      <Select
                        input={<FilledInput />}
                        value={contractor.workType}
                        onChange={(e) =>
                          update(
                            moveActions.updateCustomContractor({
                              id: contractor.id,
                              workType: e.target.value as
                                | "carrier"
                                | "assembly"
                                | "other",
                            })
                          )
                        }
                      >
                        <MenuItem value="carrier">Träger</MenuItem>
                        <MenuItem value="assembly">Montage</MenuItem>
                        <MenuItem value="other">Sonstige</MenuItem>
                      </Select>
                    </FormControl>
                    {contractor.workType !== "other" && (
                      <TextField
                        fullWidth
                        label="Anzahl"
                        variant="filled"
                        margin="dense"
                        className={clsx(
                          classes.shortInput,
                          classes.contractorInput
                        )}
                        value={contractor.count ?? ""}
                        onChange={(value) =>
                          update(
                            moveActions.updateCustomContractor({
                              id: contractor.id,
                              count: value as number,
                            })
                          )
                        }
                        type="number"
                      />
                    )}
                    <TooltipIconButton
                      className={classes.removeContractorButton}
                      tooltip="Fremdfirma entfernen"
                      onClick={() => {
                        update(
                          moveActions.removeCustomContractor({
                            id: contractor.id,
                          })
                        );
                        snackbar.showMessage(
                          `Die Fremdfirma ${
                            contractor.name ? `"${contractor.name}"` : ""
                          }wurde entfernt.`,
                          "Rückgängig",
                          () => {
                            update(moveActions.addCustomContractor(contractor));
                          }
                        );
                      }}
                    >
                      <TrashCanOutline />
                    </TooltipIconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            variant="text"
            color="primary"
            onClick={() =>
              update(
                moveActions.addCustomContractor({
                  id: nanoid(),
                  name: "",
                  workType: "carrier",
                })
              )
            }
          >
            <Plus />
            Weitere Fremdfirma hinzufügen
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Staff);
