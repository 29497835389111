import {
  Button,
  createStyles,
  IconButton,
  MenuItem,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { Refresh } from "mdi-material-ui";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AsyncButton, OverflowMenu, useDialog } from "@wa/werkstoff-core";
import { nanoid } from "nanoid";
import clsx from "clsx";
import { Plugins } from "@capacitor/core";
import AppBar from "../../../components/AppBar";
import { useSynchronizedData } from "../../synchronization/SynchronizedDataContext";
import MoveCard from "../components/MoveCard";
import {
  LocalMove,
  RemoteMove,
} from "../../synchronization/database/IDatabase";
import { getMoves } from "../../synchronization/moveSynchronization";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useAuthenticationContext } from "../../core/AuthenticationContext";
import MovesTable from "../components/MovesTable";
import { getMoveTitle } from "../../move/components/Move";
import useIsOnline from "../../../hooks/useIsOnline";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: `calc(100vh - ${theme.spacing(
        5.5
      )}px - var(--safe-area-inset-top))`,
    },
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      background: theme.palette.background.default,
      "& > div > .MuiTypography-h6": {
        margin: theme.spacing(3, 0, 0, 3),
      },
      "& > div > div.MuiTypography-h6": {
        display: "flex",
        justifyContent: "space-between",
        height: theme.spacing(4),
      },
      transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeIn,
      }),
    },
    allMoves: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      paddingBottom: theme.spacing(3),
    },
    recent: {
      display: "flex",
      overflowX: "auto",
      "& $move:first-child": {
        marginLeft: theme.spacing(3),
      },
      "& $move:last-child": {
        marginRight: 0,
      },
      "& div:last-child": {
        minWidth: theme.spacing(2),
      },
    },
    all: {
      display: "block",
      flex: 1,
      overflowY: "auto",
      "& > *": {
        float: "left",
      },
    },
    move: {
      margin: theme.spacing(1),
      width: theme.spacing(32),
      minWidth: theme.spacing(32),
      maxWidth: theme.spacing(32),
    },
    headerMenu: {
      alignSelf: "flex-end",
      marginRight: theme.spacing(3),
      "& > .MuiIconButton-root": {
        padding: theme.spacing(0.5),
      },
      "& > button": {
        marginLeft: theme.spacing(),
      },
    },
    movesTable: {
      margin: theme.spacing(1, 3, 0),
      width: `calc(100% - ${theme.spacing(6)}px)`,
      flex: 1,
      "& tr > td:first-child": {
        width: 18,
        paddingRight: 0,
        "& svg": {
          color: theme.palette.action.active,
        },
      },
    },
    hide: {
      opacity: 0,
    },
    overflowMenu: {
      padding: theme.spacing(1.25),
      marginRight: theme.spacing(-3),
    },
  });

function MovesContainer({ classes }: WithStyles<typeof styles>) {
  const snackbar = useSnackbar();
  const { push } = useHistory();
  const {
    database,
    configuration,
    isConfigurationLoaded,
    synchronize,
  } = useSynchronizedData();
  const { showLoginDialog, isLoggedIn, logout } = useAuthenticationContext();
  const [initialLoading, setInitialLoading] = useState(true);
  const triedInitialSync = useRef(false);
  const [recentMoves, setRecentMoves] = useState<LocalMove[]>([]);
  const [moves, setMoves] = useState<Array<LocalMove | RemoteMove> | null>();

  useEffect(() => {
    // The router is asynchronous, so we hide the splashscreen here (not in the ReactDOM.render() callback)
    // to prevent black flickering on iPad
    Plugins.SplashScreen.hide();
  }, []);

  const isOnline = useIsOnline();

  useEffect(() => {
    // Initial synchronized data download (or once every day)
    async function trySynchronizeData() {
      try {
        await synchronize();
        snackbar.showMessage("Die Kalkulationssätze wurden aktualisiert.");
      } catch (e) {
        if (e.extensions?.exception?.status === 401) {
          snackbar.showMessage(
            "Zum Abrufen der Kalkulationssätze ist eine Anmeldung erforderlich."
          );
          showLoginDialog(trySynchronizeData);
        } else {
          snackbar.showMessage(
            "Die Kalkulationssätze konnten nicht aktualisiert werden."
          );
        }
      }
    }

    if (isOnline && !triedInitialSync.current && isConfigurationLoaded) {
      triedInitialSync.current = true;
      if (
        configuration.lastUpdated == null ||
        new Date().toDateString() !== configuration.lastUpdated.toDateString()
      ) {
        trySynchronizeData();
      }
    }
  }, [
    configuration,
    isConfigurationLoaded,
    synchronize,
    isOnline,
    showLoginDialog,
    snackbar,
  ]);

  const handleRefreshRemoteMoves = useCallback(
    async (quiet: boolean = false) => {
      try {
        const remoteMoves = await getMoves();
        await database.setRemoteMoves(remoteMoves);
        const allMoves = await database.getMoves();
        setMoves(allMoves);
        if (!quiet) {
          snackbar.showMessage("Die Umzüge wurden aktualisiert.");
        }
      } catch (e) {
        if (e.extensions?.exception?.status === 401) {
          snackbar.showMessage(
            "Zum Abrufen von Umzügen ist eine Anmeldung erforderlich."
          );
          showLoginDialog(handleRefreshRemoteMoves);
        } else if (!quiet) {
          snackbar.showMessage("Die Umzüge konnten nicht aktualisiert werden.");
        }
      }
    },
    [snackbar, database, showLoginDialog]
  );

  const handleSynchronizeData = useCallback(async () => {
    async function trySynchronizeData() {
      try {
        await synchronize();
        snackbar.showMessage("Die Kalkulationssätze wurden aktualisiert.");
      } catch (e) {
        if (e.extensions?.exception?.status === 401) {
          snackbar.showMessage(
            "Zum Abrufen der Kalkulationssätze ist eine Anmeldung erforderlich."
          );
          showLoginDialog(trySynchronizeData);
        } else {
          snackbar.showMessage(
            "Die Kalkulationssätze konnten nicht aktualisiert werden."
          );
        }
      }
    }
    trySynchronizeData();
  }, [snackbar, showLoginDialog, synchronize]);

  useEffect(() => {
    let stale = false;
    async function loadLocalMoves() {
      const recentMoves = await database.getRecentMoves(5);
      if (!stale) {
        setRecentMoves(recentMoves);
      }
    }
    async function loadKnownRemoteMoves() {
      if (!isOnline) return;

      let allMoves = await database.getMoves();
      if (!stale) {
        setMoves(allMoves);
      }
      (async () => {
        try {
          const remoteMoves = await getMoves();
          if (stale) return;
          await database.setRemoteMoves(remoteMoves);
          if (stale) return;
          const allMoves = await database.getMoves();
          if (!stale) {
            setMoves(allMoves);
          }
        } catch (e) {
          if (e.extensions?.exception?.status === 401) {
            showLoginDialog(() => handleRefreshRemoteMoves(true));
          }
        }
      })();
    }
    Promise.all([loadLocalMoves(), loadKnownRemoteMoves()]).finally(() => {
      if (!stale) {
        setInitialLoading(false);
      }
    });
    return () => {
      stale = true;
    };
  }, [database, handleRefreshRemoteMoves, showLoginDialog, isOnline]);

  const openMove = useCallback(
    (move: LocalMove | RemoteMove) => {
      push(`/moves/${move.id}`, {
        loadingTitle: getMoveTitle(move), // used by MoveLoading to show the title in the app bar while loading
      });
    },
    [push]
  );

  const showDialog = useDialog();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleImport = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = async (e) => {
          const loadedMove = JSON.parse(e.target?.result as string);
          if (loadedMove.id && loadedMove.data) {
            if (
              moves?.some(
                (move) =>
                  move.id === loadedMove.id ||
                  ("remoteId" in move &&
                    move.remoteId != null &&
                    move.remoteId === loadedMove.remoteId)
              )
            ) {
              showDialog({
                title: "Umzug importieren",
                message:
                  "Dieser Umzug existiert bereits. Möchten Sie den bestehenden Umzug überschreiben oder stattdessen eine Kopie erstellen (empfohlen)?",
                actions: [
                  { label: "Abbrechen", action: "close" },
                  {
                    label: "Überschreiben",
                    action: async () => {
                      await database.setMoveSynchronized(loadedMove);
                      push(`/moves/${loadedMove.id}`);
                      snackbar.showMessage("Der Umzug wurde importiert");
                    },
                  },
                  {
                    action: async () => {
                      const id = nanoid();
                      await database.setMoveSynchronized({
                        ...loadedMove,
                        remoteId: null,
                        id,
                      });
                      push(`/moves/${id}`);
                      snackbar.showMessage(
                        "Der Umzug wurde als Kopie importiert"
                      );
                    },
                    label: "Kopie erstellen",
                    color: "primary",
                  },
                ],
              });
            } else {
              await database.setMoveSynchronized(loadedMove);
              push(`/moves/${loadedMove.id}`);
              snackbar.showMessage("Der Umzug wurde importiert");
            }
          }
        };
        reader.readAsText(file);
        e.target.value = "";
      }
    },
    [moves, showDialog, database, push, snackbar]
  );

  return (
    <div className={classes.root}>
      <AppBar title="Umzüge">
        <OverflowMenu className={classes.overflowMenu}>
          <MenuItem disabled={!isOnline} onClick={handleSynchronizeData}>
            Kalkulationssätze aktualisieren
          </MenuItem>
          {isLoggedIn ? (
            <MenuItem onClick={logout}>Abmelden</MenuItem>
          ) : (
            <MenuItem onClick={() => showLoginDialog()}>Anmelden</MenuItem>
          )}
          <MenuItem onClick={() => fileInputRef.current?.click()}>
            Importieren
          </MenuItem>
        </OverflowMenu>
      </AppBar>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleImport}
        style={{ display: "none" }}
        accept=".json"
      />
      <div
        className={clsx(classes.container, { [classes.hide]: initialLoading })}
      >
        {recentMoves.length > 0 && (
          <div>
            <Typography variant="h6">Zuletzt geöffnet</Typography>
            <div className={classes.recent}>
              {recentMoves.map((move) => (
                <MoveCard
                  key={move.id}
                  className={classes.move}
                  onClick={() => openMove(move)}
                  move={move}
                />
              ))}
              {/* right margin fix */}
              <div />
            </div>
          </div>
        )}
        <div className={classes.allMoves}>
          <Typography variant="h6" component="div">
            {recentMoves.length > 0 ? <div>Alle Umzüge</div> : <div />}
            <div className={classes.headerMenu}>
              <AsyncButton
                component={IconButton}
                onClick={() => handleRefreshRemoteMoves()}
              >
                <Refresh />
              </AsyncButton>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                onClick={() => push("/moves/new")}
              >
                Neuer Umzug
              </Button>
            </div>
          </Typography>
          <MovesTable
            data={moves}
            onRowClick={(e: any, id: string, row: LocalMove | RemoteMove) =>
              openMove(row)
            }
            className={classes.movesTable}
            placeholder={
              <Typography variant="body2">
                Es wurden keine Umzüge gefunden.
                <br />
                Klicken Sie auf &bdquo;Neuer Umzug&ldquo;, um einen neuen Umzug
                anzulegen.
              </Typography>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(MovesContainer);
