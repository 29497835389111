import React from "react";
import {
  createStyles,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  IconButton,
} from "@material-ui/core";
import { Plus, Minus } from "mdi-material-ui";
import { moveActions } from "@wurzel/uzb-sync";
import { useCurrentMove } from "../../containers/Move";
import { useSynchronizedData } from "../../../synchronization/SynchronizedDataContext";
import { getUnit } from "./units";
import { getPackingMaterial } from "@wurzel/uzb-calculation/dist/calculation";
import { ApplyOnBlurTextField } from "../../../../components/ApplyOnBlurTextField";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    tableHead: {
      "& th": {
        paddingRight: 0,
      },
    },
  });

interface PackingMaterialProps {
  children: React.ReactNode;
}

const PlusMinusInput = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: 140,
    marginRight: theme.spacing(-1),
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
      "& input": {
        textAlign: "center",
        lineHeight: `${theme.spacing(5)}px`,
        height: theme.spacing(5),
        padding: 0,
      },
    },
    "& .MuiIconButton-root": {
      padding: theme.spacing(1),
    },
  },
}))(
  ({
    classes,
    value,
    defaultValue,
    onChange,
    min,
    max,
  }: {
    classes: any;
    value: number | null;
    defaultValue: number;
    onChange: (value: number | null) => void;
    min?: number | null;
    max?: number | null;
  }) => {
    const effectiveValue =
      max != null
        ? Math.max(min ?? 0, Math.min(max, value ?? defaultValue))
        : value ?? defaultValue;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onChange(Math.max(0, (effectiveValue ?? 0) - 1));
          }}
          disabled={effectiveValue === 0}
        >
          <Minus />
        </IconButton>
        <ApplyOnBlurTextField
          value={
            value != null ? (max != null ? Math.min(max, value) : value) : ""
          }
          onChange={(newValue) => {
            if (typeof newValue === "string") {
              // only happens if the value is not a number (or empty)
              onChange(null);
            } else {
              if (max != null) {
                onChange(Math.max(min ?? 0, Math.min(max, newValue)));
              } else {
                onChange(Math.max(min ?? 0, newValue));
              }
            }
          }}
          type="number"
          InputProps={{
            disableUnderline: true,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          placeholder={effectiveValue.toLocaleString()}
        />
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onChange(effectiveValue + 1);
          }}
          disabled={max != null && effectiveValue === max}
        >
          <Plus />
        </IconButton>
      </div>
    );
  }
);

function PackingMaterial({
  classes,
}: PackingMaterialProps & WithStyles<typeof styles>) {
  const {
    move: { data: move },
    update,
  } = useCurrentMove();
  const { configuration } = useSynchronizedData();

  const calculatedPackingMaterial = getPackingMaterial(
    move,
    configuration,
    true
  );
  const allPackingMaterials = configuration.packingMaterials;

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary">
        Packmaterial
      </Typography>
      <Table size="small">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Packmaterial</TableCell>
            <TableCell>Menge</TableCell>
            <TableCell>Vorab liefern</TableCell>
            <TableCell padding="none" />
          </TableRow>
        </TableHead>
        <TableBody>
          {allPackingMaterials.map((packingMaterial) => {
            const calculated = calculatedPackingMaterial.find(
              (p) => p.packingMaterial.id === packingMaterial.id
            );
            const override = move.packingMaterialOverrides?.find(
              (o) => o.id === packingMaterial.id
            );
            return (
              <TableRow key={packingMaterial.id}>
                <TableCell>
                  {packingMaterial.name}
                  <br />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    align="center"
                    onClick={() => {
                      if (
                        override?.amount != null ||
                        override?.inAdvance != null
                      ) {
                        update(
                          moveActions.setPackingMaterialAmountOverride({
                            amount: null,
                            inAdvance: null,
                            packingMaterialId: packingMaterial.id,
                          })
                        );
                      }
                    }}
                  >
                    Nach Umzugsgutliste:{" "}
                    {calculated?.amount.toLocaleString() ?? 0}
                    {(calculated?.amount ?? 0) > 0 &&
                      (calculated?.inAdvance ?? 0) > 0 &&
                      ` (${(
                        calculated?.inAdvance ?? 0
                      ).toLocaleString()} vorab liefern)`}
                  </Typography>
                </TableCell>
                <TableCell padding="none">
                  <PlusMinusInput
                    value={override?.amount ?? null}
                    defaultValue={calculated?.amount ?? 0}
                    onChange={(overrideValue) => {
                      update(
                        moveActions.setPackingMaterialAmountOverride({
                          amount: overrideValue,
                          packingMaterialId: packingMaterial.id,
                        })
                      );
                    }}
                  />
                </TableCell>
                <TableCell padding="none">
                  <PlusMinusInput
                    value={override?.inAdvance ?? null}
                    defaultValue={calculated?.inAdvance ?? 0}
                    max={override?.amount ?? calculated?.amount ?? 0}
                    onChange={(overrideValue) => {
                      update(
                        moveActions.setPackingMaterialAmountOverride({
                          inAdvance: overrideValue,
                          packingMaterialId: packingMaterial.id,
                        })
                      );
                    }}
                  />
                </TableCell>
                <TableCell padding="none">
                  {getUnit(packingMaterial.unit)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default withStyles(styles)(PackingMaterial);
