import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";

export default class EnhancedDateFnsUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: number | Date) {
    // Fr., 28. Sep. (like in Google Calendar)
    return format(date, "EEEEEE., dd. LLL.", { locale: this.locale });
  }

  getDateTimePickerHeaderText(date: number | Date) {
    return format(date, "dd.MM.", { locale: this.locale });
  }
}
