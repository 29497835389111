import React, { useMemo } from "react";
import {
  Button,
  Checkbox,
  createStyles,
  FilledInput,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { useCurrentMove } from "../containers/Move";
import { ApplyOnBlurTextField as TextField } from "../../../components/ApplyOnBlurTextField";
import { calculate } from "@wurzel/uzb-calculation/dist/calculation";
import { useSynchronizedData } from "../../synchronization/SynchronizedDataContext";
import { recalculate } from "@wurzel/uzb-calculation/dist/recalculation";
import { Plus, TrashCanOutline } from "mdi-material-ui";
import { moveActions } from "@wurzel/uzb-sync";
import { nanoid } from "nanoid";
import { TooltipIconButton } from "@wa/werkstoff-core";
import clsx from "clsx";
import { useSnackbar } from "material-ui-snackbar-provider";
import { euro, hours } from "../../../utils/textUtil";

const getUnit = (u: string) => {
  switch (u) {
    case "meter":
      return "m";
    case "kilogram":
      return "kg";
    case "piece":
    default:
      return "Stk.";
  }
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      "& table": {
        borderSpacing: 0,
        "& td": {
          padding: 0,
        },
      },
      "& table td:nth-child(1)": {
        width: theme.spacing(15 * 2 + 3),
      },
      "& table td:nth-child(2):not($formCell)": {
        minWidth: 90,
        textAlign: "right",
        fontVariantNumeric: "tabular-nums",
      },
      "& table $checkbox": {
        marginLeft: theme.spacing(2),
      },
    },
    formSection: {
      display: "flex",
      marginBottom: theme.spacing(3),
      "& > *:first-child": {
        width: theme.spacing(16),
        textAlign: "right",
        paddingRight: theme.spacing(3),
        lineHeight: 1,
        paddingTop: theme.spacing(1),
        "& > .MuiTypography-root": {
          lineHeight: 1,
        },
      },
      "& > div:first-child": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      },
      "& > *:last-child": {
        paddingTop: theme.spacing(0.5),
        flex: 1,
      },
    },
    result: {
      fontWeight: "bold",
    },
    shortInput: {
      width: theme.spacing(15),
      maxWidth: theme.spacing(15),
    },
    mediumInput: {
      width: theme.spacing(15 * 2 + 1.5),
      maxWidth: theme.spacing(15 * 2 + 1.5),
    },
    fieldRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1.5),
      },
    },
    checkbox: {},
    contractorName: {
      width: theme.spacing(15 * 2 + 3),
      maxWidth: theme.spacing(15 * 2 + 3),
      overflow: "none",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    contractorNameInput: {
      width: theme.spacing(15 * 2 + 1.5),
      maxWidth: theme.spacing(15 * 2 + 1.5),
      marginRight: theme.spacing(1.5),
    },
    contractorInput: {
      marginRight: theme.spacing(2),
    },
    removeContractorButton: {
      marginTop: theme.spacing(),
    },
    formCell: {
      verticalAlign: "top",
      "&$fieldRow": {
        flexWrap: "wrap",
      },
    },
    bad: {
      color: theme.palette.error.dark,
    },
    subheaderCaption: {
      marginTop: theme.spacing(1.5),
    },
  });

function Recalculation({ classes }: WithStyles<typeof styles>) {
  const {
    move: { data: move },
    update,
  } = useCurrentMove();
  const { configuration } = useSynchronizedData();
  const snackbar = useSnackbar();

  const calculation = useMemo(() => calculate(move, configuration), [
    move,
    configuration,
  ]);
  const recalculation = useMemo(
    () => recalculate(move, configuration, calculation),
    [move, configuration, calculation]
  );

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary">
        Nachkalkulation
      </Typography>
      <div className={classes.formSection}>
        <Typography variant="overline">Personal Wurzel</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>
                  <TextField
                    label="Berater"
                    type="number"
                    variant="filled"
                    margin="dense"
                    value={move.recalculation?.staff?.consultantTime ?? ""}
                    onChange={(value) =>
                      update({
                        type: "setField",
                        payload: {
                          field: "recalculation.staff.consultantTime",
                          value,
                        },
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Std.</InputAdornment>
                      ),
                    }}
                    className={classes.shortInput}
                  />
                </td>
                <td>{euro(recalculation.consultant.price)}</td>
              </tr>
              <tr>
                <td>PKW Berater:</td>
                <td>
                  {recalculation.consultantCar != null
                    ? euro(recalculation.consultantCar)
                    : "–"}
                </td>
              </tr>
              <tr>
                <td>
                  <div className={classes.fieldRow}>
                    <TextField
                      label="Teamleiter"
                      type="number"
                      variant="filled"
                      margin="dense"
                      value={move.recalculation?.staff?.teamLeaders ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "recalculation.staff.teamLeaders",
                            value,
                          },
                        })
                      }
                      className={classes.shortInput}
                    />
                    <TextField
                      label="Zeit"
                      type="number"
                      variant="filled"
                      margin="dense"
                      value={move.recalculation?.staff?.teamLeadersTime ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "recalculation.staff.teamLeadersTime",
                            value,
                          },
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Std.</InputAdornment>
                        ),
                      }}
                      className={classes.shortInput}
                    />
                  </div>
                </td>
                <td>{euro(recalculation.teamLeader.price)}</td>
              </tr>
              <tr>
                <td>
                  <div className={classes.fieldRow}>
                    <TextField
                      label="Träger"
                      type="number"
                      variant="filled"
                      margin="dense"
                      value={move.recalculation?.staff?.carriers ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "recalculation.staff.carriers",
                            value,
                          },
                        })
                      }
                      className={classes.shortInput}
                    />
                    <TextField
                      label="Zeit"
                      type="number"
                      variant="filled"
                      margin="dense"
                      value={move.recalculation?.staff?.carriersTime ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "recalculation.staff.carriersTime",
                            value,
                          },
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Std.</InputAdornment>
                        ),
                      }}
                      className={classes.shortInput}
                    />
                  </div>
                </td>
                <td>{euro(recalculation.carrier.price)}</td>
              </tr>
              <tr>
                <td>
                  <div className={classes.fieldRow}>
                    <TextField
                      label="Aushilfen"
                      type="number"
                      variant="filled"
                      margin="dense"
                      value={
                        move.recalculation?.staff?.temporaryEmployees ?? ""
                      }
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "recalculation.staff.temporaryEmployees",
                            value,
                          },
                        })
                      }
                      className={classes.shortInput}
                    />
                    <TextField
                      label="Zeit"
                      type="number"
                      variant="filled"
                      margin="dense"
                      value={
                        move.recalculation?.staff?.temporaryEmployeesTime ?? ""
                      }
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "recalculation.staff.temporaryEmployeesTime",
                            value,
                          },
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Std.</InputAdornment>
                        ),
                      }}
                      className={classes.shortInput}
                    />
                  </div>
                </td>
                <td>{euro(recalculation.temporaryEmployee.price)}</td>
              </tr>
              <tr>
                <td>
                  <div className={classes.fieldRow}>
                    <TextField
                      label="Azubis"
                      type="number"
                      variant="filled"
                      margin="dense"
                      value={move.recalculation?.staff?.trainees ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "recalculation.staff.trainees",
                            value,
                          },
                        })
                      }
                      className={classes.shortInput}
                    />
                    <TextField
                      label="Zeit"
                      type="number"
                      variant="filled"
                      margin="dense"
                      value={move.recalculation?.staff?.traineesTime ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "recalculation.staff.traineesTime",
                            value,
                          },
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Std.</InputAdornment>
                        ),
                      }}
                      className={classes.shortInput}
                    />
                  </div>
                </td>
                <td>{euro(recalculation.trainee.price)}</td>
              </tr>
              <tr>
                <td>
                  <div className={classes.fieldRow}>
                    <TextField
                      label="Glaspacker"
                      type="number"
                      variant="filled"
                      margin="dense"
                      value={move.recalculation?.staff?.glassPackers ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "recalculation.staff.glassPackers",
                            value,
                          },
                        })
                      }
                      className={classes.shortInput}
                    />
                    <TextField
                      label="Zeit"
                      type="number"
                      variant="filled"
                      margin="dense"
                      value={move.recalculation?.staff?.glassPackerTime ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "recalculation.staff.glassPackerTime",
                            value,
                          },
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Std.</InputAdornment>
                        ),
                      }}
                      className={classes.shortInput}
                    />
                  </div>
                </td>
                <td>{euro(recalculation.glassPacker.price)}</td>
              </tr>
              <tr>
                <td>LKW:</td>
                <td>{euro(recalculation.truck)}</td>
                <td>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox color="primary" />}
                    label="Nur Möbelwagen"
                    checked={
                      !(
                        move.recalculation?.isTrailerRequired ??
                        move.recalculation?.isWithTruck ??
                        calculation.isTrailerRequired
                      )
                    }
                    onChange={(e, checked) => {
                      update({
                        type: "setField",
                        payload: {
                          field: "recalculation.isTrailerRequired",
                          value: !checked,
                        },
                      });

                      // remove old field
                      update({
                        type: "setField",
                        payload: {
                          field: "recalculation.isWithTruck",
                          value: null,
                        },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>PKW:</td>
                <td>{euro(recalculation.car)}</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    label="Maut"
                    type="number"
                    variant="filled"
                    margin="dense"
                    value={move.recalculation?.toll ?? ""}
                    onChange={(value) =>
                      update({
                        type: "setField",
                        payload: {
                          field: "recalculation.toll",
                          value,
                        },
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.shortInput}
                    placeholder={(recalculation?.toll || 0).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>Pauschale Außenaufzug:</td>
                <td>{euro(calculation.lift)}</td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Personal Fremdfirmen</Typography>
        <div>
          <Typography variant="body2">
            <table>
              {configuration.contractors
                .filter(
                  (contractor) =>
                    contractor.workType !== "other" &&
                    move.contractors?.some(
                      (c) => c.id === contractor.id && (c.count || 0) > 0
                    )
                )
                .map((contractor) => (
                  <tr key={contractor.id}>
                    <td className={classes.contractorName}>
                      {contractor.name}
                    </td>
                    <td className={clsx(classes.formCell, classes.fieldRow)}>
                      <TextField
                        fullWidth
                        label="Kosten"
                        variant="filled"
                        margin="dense"
                        className={classes.shortInput}
                        value={
                          move.recalculation?.contractors?.find(
                            (c) => c.id === contractor.id
                          )?.cost ?? ""
                        }
                        onChange={(value) =>
                          update(
                            moveActions.updateRecalculationContractor({
                              contractorId: contractor.id,
                              cost: value as number,
                            })
                          )
                        }
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                      />
                      {contractor.workType !== "other" && (
                        <TextField
                          fullWidth
                          label={
                            contractor.workType === "assembly"
                              ? "Monteure"
                              : "Träger"
                          }
                          variant="filled"
                          margin="dense"
                          className={classes.shortInput}
                          value={
                            move.recalculation?.contractors?.find(
                              (c) => c.id === contractor.id
                            )?.count ?? ""
                          }
                          onChange={(value) =>
                            update(
                              moveActions.updateRecalculationContractor({
                                contractorId: contractor.id,
                                count: value as number,
                              })
                            )
                          }
                          type="number"
                        />
                      )}
                      <TextField
                        fullWidth
                        label="Zeit"
                        variant="filled"
                        margin="dense"
                        className={clsx(
                          classes.shortInput,
                          classes.contractorInput
                        )}
                        value={
                          move.recalculation?.contractors?.find(
                            (c) => c.id === contractor.id
                          )?.time ?? ""
                        }
                        onChange={(value) =>
                          update(
                            moveActions.updateRecalculationContractor({
                              contractorId: contractor.id,
                              time: value as number,
                            })
                          )
                        }
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Std.</InputAdornment>
                          ),
                        }}
                      />
                    </td>
                  </tr>
                ))}
              {move.recalculation?.customContractors?.map((contractor) => (
                <tr key={contractor.id}>
                  <td className={classes.formCell}>
                    <TextField
                      fullWidth
                      label="Firma"
                      variant="filled"
                      margin="dense"
                      className={classes.contractorNameInput}
                      value={contractor.name}
                      onChange={(value) =>
                        update(
                          moveActions.updateRecalculationCustomContractor({
                            id: contractor.id,
                            name: `${value}`,
                          })
                        )
                      }
                    />
                  </td>
                  <td className={clsx(classes.formCell, classes.fieldRow)}>
                    <TextField
                      fullWidth
                      label="Kosten"
                      variant="filled"
                      margin="dense"
                      className={clsx(
                        classes.shortInput,
                        classes.contractorInput
                      )}
                      value={contractor.cost ?? ""}
                      onChange={(value) =>
                        update(
                          moveActions.updateRecalculationCustomContractor({
                            id: contractor.id,
                            cost: value as number,
                          })
                        )
                      }
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                    />
                    <FormControl
                      variant="filled"
                      margin="dense"
                      fullWidth
                      className={clsx(
                        classes.shortInput,
                        classes.contractorInput
                      )}
                    >
                      <InputLabel>Art</InputLabel>
                      <Select
                        input={<FilledInput />}
                        value={contractor.workType}
                        onChange={(e) =>
                          update(
                            moveActions.updateRecalculationCustomContractor({
                              id: contractor.id,
                              workType: e.target.value as
                                | "carrier"
                                | "assembly"
                                | "other",
                            })
                          )
                        }
                      >
                        <MenuItem value="carrier">Träger</MenuItem>
                        <MenuItem value="assembly">Montage</MenuItem>
                        <MenuItem value="other">Sonstige</MenuItem>
                      </Select>
                    </FormControl>
                    {contractor.workType !== "other" && (
                      <TextField
                        fullWidth
                        label="Anzahl"
                        variant="filled"
                        margin="dense"
                        className={clsx(
                          classes.shortInput,
                          classes.contractorInput
                        )}
                        value={contractor.count ?? ""}
                        onChange={(value) =>
                          update(
                            moveActions.updateRecalculationCustomContractor({
                              id: contractor.id,
                              count: value as number,
                            })
                          )
                        }
                        type="number"
                      />
                    )}
                    <TextField
                      fullWidth
                      label="Zeit"
                      variant="filled"
                      margin="dense"
                      className={clsx(
                        classes.shortInput,
                        classes.contractorInput
                      )}
                      value={contractor.time ?? ""}
                      onChange={(value) =>
                        update(
                          moveActions.updateRecalculationCustomContractor({
                            id: contractor.id,
                            time: value as number,
                          })
                        )
                      }
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Std.</InputAdornment>
                        ),
                      }}
                    />
                    <TooltipIconButton
                      className={classes.removeContractorButton}
                      tooltip="Fremdfirma entfernen"
                      onClick={() => {
                        update(
                          moveActions.removeRecalculationCustomContractor({
                            id: contractor.id,
                          })
                        );
                        snackbar.showMessage(
                          `Die Fremdfirma ${
                            contractor.name ? `"${contractor.name}"` : ""
                          }wurde entfernt.`,
                          "Rückgängig",
                          () => {
                            update(
                              moveActions.addRecalculationCustomContractor(
                                contractor
                              )
                            );
                          }
                        );
                      }}
                    >
                      <TrashCanOutline />
                    </TooltipIconButton>
                  </td>
                </tr>
              ))}
            </table>
            <Button
              variant="text"
              color="primary"
              onClick={() =>
                update(
                  moveActions.addRecalculationCustomContractor({
                    id: nanoid(),
                    name: "",
                    workType: "carrier",
                  })
                )
              }
            >
              <Plus />
              Weitere Fremdfirma hinzufügen
            </Button>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Beladung</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>Laderaum:</td>
                <td>
                  {calculation.totalVolume.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}{" "}
                  m³
                </td>
              </tr>
              <tr>
                <td>Möbelwagenmeter:</td>
                <td>
                  {calculation.totalVolumeMWM.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}{" "}
                  m
                </td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Zeiten</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>Montagezeit:</td>
                <td>{hours(calculation.assemblyTime)}</td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Verpackungs&shy;material</Typography>
        <div>
          <Typography variant="body2">
            <table>
              {calculation.packingMaterial.map((packingMaterial) => {
                const amount = move.recalculation?.packingMaterial?.find(
                  (p) => p.id === packingMaterial.packingMaterial.id
                )?.amount;

                return (
                  <tr key={packingMaterial.packingMaterial.id}>
                    <td>
                      <TextField
                        label={packingMaterial.packingMaterial.name}
                        type="number"
                        variant="filled"
                        margin="dense"
                        value={amount ?? ""}
                        onChange={(value) =>
                          update(
                            moveActions.setRecalculationPackingMaterialAmount({
                              amount: value as number,
                              packingMaterialId:
                                packingMaterial.packingMaterial.id,
                            })
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {getUnit(packingMaterial.packingMaterial.unit)}
                            </InputAdornment>
                          ),
                        }}
                        className={classes.mediumInput}
                      />
                    </td>
                    <td>
                      {euro(
                        (amount || 0) * packingMaterial.packingMaterial.price
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>Anlieferung Packmaterial:</td>
                <td>
                  {calculation.deliveryPackingMaterial == null
                    ? "(entfällt)"
                    : euro(calculation.deliveryPackingMaterial)}
                </td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Halte&shy;verbots&shy;zonen</Typography>
        <div>
          <Typography variant="body2">
            <table>
              {((move.loadingAddresses.length > 0
                ? move.loadingAddresses
                : [{ id: "default" }]) as Array<{ id: string }>).map(
                (address, i, addresses) => {
                  const fees = move.recalculation?.noParkingZones?.find(
                    (p) =>
                      "loadingAddressId" in p &&
                      p.loadingAddressId === address.id
                  );
                  return (
                    <React.Fragment key={address.id}>
                      <tr>
                        <td>
                          <Typography
                            variant="caption"
                            component="div"
                            className={
                              i > 0 ? classes.subheaderCaption : undefined
                            }
                          >
                            {addresses.length > 1
                              ? `${i + 1}. Beladestelle`
                              : "Beladestelle"}
                          </Typography>
                          <TextField
                            label={"Halteverbotszone"}
                            type="number"
                            variant="filled"
                            margin="dense"
                            value={fees?.price ?? ""}
                            onChange={(value) =>
                              update(
                                moveActions.setRecalculationNoParkingZoneFees({
                                  loadingAddressId: address.id,
                                  price: value as number,
                                })
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            className={classes.mediumInput}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                            label={"Gebühren"}
                            type="number"
                            variant="filled"
                            margin="dense"
                            value={fees?.fees ?? ""}
                            onChange={(value) =>
                              update(
                                moveActions.setRecalculationNoParkingZoneFees({
                                  loadingAddressId: address.id,
                                  fees: value as number,
                                })
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            className={classes.mediumInput}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }
              )}
              {((move.unloadingAddresses.length > 0
                ? move.unloadingAddresses
                : [{ id: "default" }]) as Array<{ id: string }>).map(
                (address, i, addresses) => {
                  const fees = move.recalculation?.noParkingZones?.find(
                    (p) =>
                      "unloadingAddressId" in p &&
                      p.unloadingAddressId === address.id
                  );
                  return (
                    <React.Fragment key={address.id}>
                      <tr>
                        <td>
                          <Typography
                            variant="caption"
                            component="div"
                            className={classes.subheaderCaption}
                          >
                            {addresses.length > 1
                              ? `${i + 1}. Entladestelle`
                              : "Entladestelle"}
                          </Typography>
                          <TextField
                            label={"Halteverbotszone"}
                            type="number"
                            variant="filled"
                            margin="dense"
                            value={fees?.price ?? ""}
                            onChange={(value) =>
                              update(
                                moveActions.setRecalculationNoParkingZoneFees({
                                  unloadingAddressId: address.id,
                                  price: value as number,
                                })
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            className={classes.mediumInput}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                            label={"Gebühren"}
                            type="number"
                            variant="filled"
                            margin="dense"
                            value={fees?.fees ?? ""}
                            onChange={(value) =>
                              update(
                                moveActions.setRecalculationNoParkingZoneFees({
                                  unloadingAddressId: address.id,
                                  fees: value as number,
                                })
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            className={classes.mediumInput}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }
              )}
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Entsorgungs&shy;kosten</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>
                  <TextField
                    label="Entsorgungskosten"
                    type="number"
                    variant="filled"
                    margin="dense"
                    value={move.recalculation?.disposal?.disposalCost ?? ""}
                    onChange={(value) =>
                      update({
                        type: "setField",
                        payload: {
                          field: "recalculation.disposal.disposalCost",
                          value,
                        },
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    className={classes.mediumInput}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    label="Gestellung Container"
                    type="number"
                    variant="filled"
                    margin="dense"
                    value={move.recalculation?.disposal?.container ?? ""}
                    onChange={(value) =>
                      update({
                        type: "setField",
                        payload: {
                          field: "recalculation.disposal.container",
                          value,
                        },
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    className={classes.mediumInput}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    label="An- und Abfahrt Container"
                    type="number"
                    variant="filled"
                    margin="dense"
                    value={
                      move.recalculation?.disposal?.containerTransport ?? ""
                    }
                    onChange={(value) =>
                      update({
                        type: "setField",
                        payload: {
                          field: "recalculation.disposal.containerTransport",
                          value,
                        },
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    className={classes.mediumInput}
                  />
                </td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Lager&shy;kosten</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>
                  <TextField
                    label="Lagermiete"
                    type="number"
                    variant="filled"
                    margin="dense"
                    value={move.recalculation?.storage?.rent ?? ""}
                    onChange={(value) =>
                      update({
                        type: "setField",
                        payload: {
                          field: "recalculation.storage.rent",
                          value,
                        },
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Tage</InputAdornment>
                      ),
                    }}
                    className={classes.mediumInput}
                  />
                </td>
                <td>{euro(recalculation.storageRent)}</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    label="Miete Container"
                    type="number"
                    variant="filled"
                    margin="dense"
                    value={move.recalculation?.storage?.containerRent ?? ""}
                    onChange={(value) =>
                      update({
                        type: "setField",
                        payload: {
                          field: "recalculation.storage.containerRent",
                          value,
                        },
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Tage</InputAdornment>
                      ),
                    }}
                    className={classes.mediumInput}
                  />
                </td>
                <td>{euro(recalculation.containerRent)}</td>
              </tr>
              <tr>
                <td>Container Handling:</td>
                <td>{euro(recalculation.containerHandling)}</td>
                <td>&nbsp;(in + out)</td>
              </tr>
              <tr>
                <td>An- und Abfahrt Container:</td>
                <td>{euro(recalculation.containerTransport)}</td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Sonstiges</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>Versicherung:</td>
                <td>{euro(calculation.insuranceFee)}</td>
              </tr>
            </table>
            <table>
              <tr>
                <td>
                  <TextField
                    label="Schwergutzuschlag"
                    type="number"
                    variant="filled"
                    margin="dense"
                    value={move.recalculation?.heavyLiftSurcharge ?? ""}
                    onChange={(value) =>
                      update({
                        type: "setField",
                        payload: {
                          field: "recalculation.heavyLiftSurcharge",
                          value,
                        },
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    className={classes.mediumInput}
                  />
                </td>
              </tr>
              {configuration.contractors
                .filter((contractor) => contractor.workType === "other")
                ?.map((contractor) => (
                  <tr key={contractor.id}>
                    <td className={classes.contractorName}>
                      {contractor.name}
                    </td>
                    <td className={clsx(classes.formCell, classes.fieldRow)}>
                      <TextField
                        fullWidth
                        label="Kosten"
                        variant="filled"
                        margin="dense"
                        className={clsx(
                          classes.shortInput,
                          classes.contractorInput
                        )}
                        value={
                          move.recalculation?.contractors?.find(
                            (c) => c.id === contractor.id
                          )?.cost ?? ""
                        }
                        onChange={(value) =>
                          update(
                            moveActions.updateRecalculationContractor({
                              contractorId: contractor.id,
                              cost: value as number,
                            })
                          )
                        }
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                      />
                    </td>
                  </tr>
                ))}
              {move.recalculation?.other?.map((other) => (
                <tr key={other.id}>
                  <td className={classes.formCell}>
                    <TextField
                      fullWidth
                      label="Bezeichnung"
                      variant="filled"
                      margin="dense"
                      className={classes.contractorNameInput}
                      value={other.name ?? ""}
                      onChange={(value) =>
                        update(
                          moveActions.updateRecalculationOtherPosition({
                            id: other.id,
                            name: `${value}`,
                          })
                        )
                      }
                    />
                  </td>
                  <td className={clsx(classes.formCell, classes.fieldRow)}>
                    <TextField
                      fullWidth
                      label="Kosten"
                      variant="filled"
                      margin="dense"
                      className={clsx(
                        classes.shortInput,
                        classes.contractorInput
                      )}
                      value={other.price ?? ""}
                      onChange={(value) =>
                        update(
                          moveActions.updateRecalculationOtherPosition({
                            id: other.id,
                            price: value as number,
                          })
                        )
                      }
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                    />
                    <TooltipIconButton
                      className={classes.removeContractorButton}
                      tooltip="Position entfernen"
                      onClick={() => {
                        update(
                          moveActions.removeRecalculationOtherPosition({
                            id: other.id,
                          })
                        );
                        snackbar.showMessage(
                          `Die Position ${
                            other.name ? `"${other.name}"` : ""
                          } wurde entfernt.`,
                          "Rückgängig",
                          () => {
                            update(
                              moveActions.addRecalculationOtherPosition(other)
                            );
                          }
                        );
                      }}
                    >
                      <TrashCanOutline />
                    </TooltipIconButton>
                  </td>
                </tr>
              ))}
            </table>
            <Button
              variant="text"
              color="primary"
              onClick={() =>
                update(
                  moveActions.addRecalculationOtherPosition({
                    id: nanoid(),
                  })
                )
              }
            >
              <Plus />
              Weitere Position hinzufügen
            </Button>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Summe</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr className={classes.result}>
                <td>Summe Einzelkosten gesamt:</td>
                <td>{euro(recalculation.total)}</td>
              </tr>
              <tr>
                <td>Ursprünglich kalkuliert:</td>
                <td>{euro(calculation.total)}</td>
              </tr>
              <tr>
                <td>Differenz:</td>
                <td
                  className={clsx({
                    [classes.bad]: calculation.total < recalculation.total,
                  })}
                >
                  {euro(calculation.total - recalculation.total)}
                </td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Recalculation);
