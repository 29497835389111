import React, { useCallback, useMemo, useRef, useState } from "react";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { DialogProvider } from "@wa/werkstoff-core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocale from "date-fns/locale/de";
import JWT from "jwt-client";
import * as Sentry from "@sentry/react";
import theme from "../theme";
import LoginDialog from "./LoginDialog";
import { AuthenticationContext, User } from "../AuthenticationContext";
import EnhancedDateFnsUtils from "../util/EnhancedGermanDateFnsUtils";

class Props {
  children?: React.ReactNode;
}

export default function Application({ children }: Props) {
  const [isLoggedIn, setIsLoggedIn] = useState(JWT.get() != null);
  const [user, setUser] = useState<User>(
    JSON.parse(localStorage.getItem("uzb_user") || "null") || null
  );
  // note: it is possible to have a user but not be logged in when using the app offline
  const [showLoginDialog, setShowLoginDialog] = useState(false);

  const loginCallback = useRef<Function | null>();

  const handleHideLoginDialog = useCallback(() => {
    setShowLoginDialog(false);
    loginCallback.current = null;
  }, []);

  const handleLogin = useCallback((user: User) => {
    setShowLoginDialog(false);
    setIsLoggedIn(true);
    setUser(user);
    localStorage.setItem("uzb_user", JSON.stringify(user));
    loginCallback.current?.();
    loginCallback.current = null;
    Sentry.setUser({
      id: user.id,
      username: user.username,
    });
  }, []);

  const handleShowLoginDialog = useCallback((callback?: () => void) => {
    setIsLoggedIn(false);
    const oldCallback = loginCallback.current;
    if (oldCallback != null) {
      loginCallback.current = () => {
        oldCallback();
        callback?.();
      };
    } else {
      loginCallback.current = callback;
      setShowLoginDialog(true);
    }
  }, []);

  const handleLogout = useCallback(() => {
    JWT.forget();
    setIsLoggedIn(false);
    Sentry.configureScope((scope) => scope.setUser(null));
  }, []);

  const authenticationContextValue = useMemo(
    () => ({
      showLoginDialog: handleShowLoginDialog,
      isLoggedIn,
      logout: handleLogout,
      user,
    }),
    [isLoggedIn, handleShowLoginDialog, handleLogout, user]
  );

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={EnhancedDateFnsUtils} locale={deLocale}>
        <SnackbarProvider>
          <DialogProvider>
            <AuthenticationContext.Provider value={authenticationContextValue}>
              <CssBaseline />
              {children}
              <LoginDialog
                open={showLoginDialog}
                onClose={handleHideLoginDialog}
                onLoggedIn={handleLogin}
              />
            </AuthenticationContext.Provider>
          </DialogProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}
