import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useRouteMatch,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router";
import { nanoid } from "nanoid";
import { moveReducer } from "@wurzel/uzb-sync";
import * as Sentry from "@sentry/react";
import Addresses from "../components/Addresses";
import Furniture from "../components/Furniture";
import BaseData from "../components/BaseData";
import Events from "../components/Events";
import Calculation from "../components/Calculation";
import MoveView from "../components/Move";
import { useSynchronizedData } from "../../synchronization/SynchronizedDataContext";
import MoveLoading from "../components/MoveLoading";
import { LocalMove } from "../../synchronization/database/IDatabase";
import { useAuthenticationContext } from "../../core/AuthenticationContext";
import { useSnackbar } from "material-ui-snackbar-provider";
import {
  fetchMove,
  synchronizeMove,
} from "../../synchronization/moveSynchronization";
import Staff from "../components/Staff";
import PackingMaterial from "../components/PackingMaterial";
import Documents from "../components/Documents";
import Recalculation from "../components/Recalculation";
import { shareFile } from "../components/Documents/template";

export interface IMoveContext {
  move: LocalMove | null;
  update: (action: { type: string; payload: any }) => void;
  synchronize: () => Promise<{ failedAttachments: string[] }>;
  /**
   * Get a temporary url for the given blob that is cached until another move
   * is opened.
   * @param attachment.id Unique ID
   * @param attachment.blob Blob
   */
  getTemporaryBlobUrl(attachment: { id: string; blob: Blob }): string;
}

export const MoveContext = createContext<IMoveContext | null>(null);

export interface MoveContainerProps {
  children: React.ReactNode;
}

function createNewMove(): LocalMove {
  return {
    id: nanoid(),
    lastOpened: new Date(),
    lastEdited: new Date(),
    data: {
      createdAt: new Date(),
      customer: {
        formOfAddress: "",
        firstname: "",
        lastname: "",
        address: "",
        city: "",
        zipCode: "",
        email: "",
        phoneNumber: "",
      },
      invoiceAddressType: "unloadingAddress",
      paymentMethod: "invoice",
      loadingDates: [],
      unloadingDates: [],
      loadingAddresses: [],
      unloadingAddresses: [
        {
          id: "default",
          formOfAddress: "",
          firstname: "",
          lastname: "",
          address: "",
          city: "",
          zipCode: "",
          floor: 0,
        },
      ],
      drivenDistances: [],
      rooms: [],
      surcharge: 25,
      surchargeType: "percentage",
    },
  };
}

export default function MoveContainer({ children }: MoveContainerProps) {
  const { params, path } = useRouteMatch<{ id: string }>();
  const { replace } = useHistory();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [move, setMove] = useState<LocalMove | null>(null);
  const moveRef = useRef<LocalMove | null>(null);

  useEffect(() => {
    moveRef.current = move;
  }, [move]);

  const db = useSynchronizedData();
  const { showLoginDialog } = useAuthenticationContext();
  const snackbar = useSnackbar();

  const attachmentBlobUrls = useRef(new Map<string, string>());
  useEffect(() => {
    // cleanup cached blob urls on unmount or move change
    const attachments = attachmentBlobUrls.current;
    return () => {
      for (const blobUrl of attachments.values()) {
        URL.revokeObjectURL(blobUrl);
      }
      attachments.clear();
    };
  }, [params.id]);

  const handleLoadMove = useCallback(() => {
    setLoading(true);
    return fetchMove(params.id)
      .then((m) => {
        if (m) {
          setMove(m);
          db.database.setMoveSynchronized(m).catch((e) => {
            snackbar.showMessage(
              "Der Umzug konnte nicht auf diesem Gerät gespeichert werden."
            );
          });
        }
      })
      .catch((e) => {
        if (e.extensions?.exception?.status === 401) {
          snackbar.showMessage(
            "Zum Abrufen von Umzügen ist eine Anmeldung erforderlich."
          );
          showLoginDialog(handleLoadMove);
        } else {
          snackbar.showMessage("Der Umzug konnte nicht abgerufen werden.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.id, db, showLoginDialog, snackbar]);

  useEffect(() => {
    setLoading(true);
    if (params.id === "new") {
      const move = createNewMove();
      db.database
        .setMoveSynchronized(move)
        .then(() => {
          setMove(move);
          replace(`/moves/${move.id}`);
        })
        .catch((e) => {
          snackbar.showMessage("Der neue Umzug konnte nicht erstellt werden.");
          Sentry.captureException(e, (scope) =>
            scope.setTags({
              "move.remoteId": move.remoteId,
              "move.id": move.id,
            })
          );
        });
    } else {
      db.database
        .getMove(params.id)
        .then(async (m) => {
          if (m) {
            setMove(m);
            db.database.setMoveLastOpened(m, new Date()).catch((e) => {
              console.error("Database error", e);
              Sentry.captureException(e, (scope) =>
                scope.setTags({
                  "move.remoteId": m.remoteId,
                  "move.id": m.id,
                })
              );
            });
            if (m.remoteId != null && navigator.onLine) {
              try {
                const { move: synchronizedMove } = await synchronizeMove(
                  m,
                  db.database
                );
                if (synchronizedMove.id === m.id) {
                  setMove(synchronizedMove);
                }
              } catch (e) {
                console.error("Silent synchronization failed", e);
                Sentry.captureException(e, (scope) =>
                  scope.setTags({
                    "move.remoteId": m.remoteId,
                    "move.id": m.id,
                  })
                );
              }
            }
          } else {
            // move not available locally, download it
            return handleLoadMove();
          }
        })
        .finally(() => setLoading(false));
    }
  }, [params.id, db.database, replace, handleLoadMove, snackbar]);

  const getTemporaryBlobUrl = useCallback(
    ({ id, blob }: { id: string; blob: Blob }) => {
      let blobUrl = attachmentBlobUrls.current.get(id);
      if (!blobUrl) {
        blobUrl = URL.createObjectURL(blob);
        attachmentBlobUrls.current.set(id, blobUrl);
      }
      return blobUrl;
    },
    []
  );

  const update = useCallback(
    (action: { type: string; payload: any }) => {
      const move = moveRef.current;

      if (move != null) {
        if (process.env.NODE_ENV !== "production") {
          console.log("[Move Action]", action);
        }
        const updatedData = moveReducer(move.data, action);
        let updatedMove = move;
        if (updatedData !== move.data) {
          updatedMove = {
            ...move,
            data: updatedData,
            lastEdited: new Date(),
          };
          db.updateMove(updatedMove, action).catch((e) => {
            Sentry.captureException(e, (scope) =>
              scope
                .setExtra("action", action)
                .setTags({
                  "move.remoteId": move.remoteId,
                  "move.id": move.id,
                })
                .setLevel(Sentry.Severity.Critical)
            );
            console.error("Database error", e);
          });
        }
        setMove(updatedMove);
        moveRef.current = updatedMove;
      }
    },
    [db]
  );

  const synchronize = useCallback(async () => {
    const move = moveRef.current;
    if (move == null) return { failedAttachments: [] };
    const { move: synchronizedMove, failedAttachments } = await synchronizeMove(
      move,
      db.database
    );
    if (synchronizedMove.id === move.id) {
      setMove(synchronizedMove);
    }
    return { failedAttachments };
  }, [db]);

  const contextValue = useMemo(
    () => ({
      move,
      update,
      synchronize,
      getTemporaryBlobUrl,
    }),
    [move, update, synchronize, getTemporaryBlobUrl]
  );

  const handleCopyMove = useCallback(async () => {
    const move = moveRef.current;
    if (move != null) {
      const { id, remoteId, ...data } = move;
      const copy = { id: nanoid(), ...data };
      db.database
        .setMoveSynchronized(copy)
        .then(() => {
          setMove(move);
          replace(`/moves/${copy.id}`);
          snackbar.showMessage("Sie bearbeiten jetzt eine Kopie des Umzugs.");
        })
        .catch((e) => {
          snackbar.showMessage("Der Umzug konnte nicht kopiert werden.");
          Sentry.captureException(e, (scope) =>
            scope.setTags({
              "move.remoteId": move.remoteId,
              "move.id": move.id,
            })
          );
        });
    }
  }, [db, snackbar, replace]);

  const handleExportMove = useCallback(async () => {
    const move = moveRef.current;
    if (move) {
      await shareFile(
        new Blob([JSON.stringify(move, null, 2)], {
          type: "application/json",
        }),
        `${move?.remoteId || move.id}.json`,
        `${move?.remoteId || move.id}.json`
      );
    }
  }, []);

  return (
    <MoveContext.Provider value={contextValue}>
      {move != null ? (
        <MoveView onCreateCopy={handleCopyMove} onExport={handleExportMove}>
          <Switch>
            <Route exact path={path} component={BaseData} />
            <Route exact path={`${path}/furniture`} component={Furniture} />
            <Route exact path={`${path}/addresses`} component={Addresses} />
            <Route exact path={`${path}/events`} component={Events} />
            <Route exact path={`${path}/staff`} component={Staff} />
            <Route exact path={`${path}/calculation`} component={Calculation} />
            <Route
              exact
              path={`${path}/recalculation`}
              component={Recalculation}
            />
            <Route
              path={`${path}/packingMaterial`}
              component={PackingMaterial}
            />
            <Route path={`${path}/documents`} component={Documents} />
          </Switch>
        </MoveView>
      ) : (
        <MoveLoading
          loading={loading}
          isNew={params.id === "new"}
          onRetryLoading={handleLoadMove}
          title={(state as any)?.loadingTitle}
        />
      )}
    </MoveContext.Provider>
  );
}

export function useCurrentMove(): Omit<IMoveContext, "move"> & {
  move: LocalMove;
} {
  const move = useContext(MoveContext);
  if (move == null) {
    throw new Error("useCurrentMove may only be used inside the MoveContainer");
  }
  if (!move.move) {
    throw new Error("useCurrentMove may only be used if a move is selected");
  }
  return move as Omit<IMoveContext, "move"> & { move: LocalMove };
}
