import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ServerError,
  concat,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import JWT from "jwt-client";
import omitDeep from "@wertarbyte/omit-deep";
import { getServerUrl } from "../core/api";
import { DocumentNode, OperationDefinitionNode } from "graphql";
import { cordovaHttpFetchImpl } from "../../utils/cordovaFetch";

let apolloClient: ApolloClient<any>;

export async function getApolloClient() {
  if (apolloClient != null) {
    return apolloClient;
  }

  const serverUrl = await getServerUrl();

  const authLink = setContext((_, { headers }) => {
    const token = JWT.get();
    return {
      headers: {
        ...headers,
        Authorization: token ?? "",
      },
    };
  });

  const cleanTypenameLink = new ApolloLink((operation, forward) => {
    const keysToOmit = ["__typename"]; // more keys like timestamps could be included here

    const definition = getMainDefinition(operation.query);
    if ((definition as OperationDefinitionNode)?.operation === "mutation") {
      operation.variables = omitDeep(operation.variables, keysToOmit, false);
    }
    return forward ? forward(operation) : null;
  });

  const httpLink = createHttpLink({
    uri: `${serverUrl}/graphql`,
    fetch: cordovaHttpFetchImpl,
  });

  const cache = new InMemoryCache();

  apolloClient = new ApolloClient({
    /* eslint-disable no-console */
    link: ApolloLink.from([
      cleanTypenameLink,
      onError(({ networkError, graphQLErrors }) => {
        if (
          (networkError as ServerError)?.statusCode === 401 ||
          graphQLErrors?.some((e) => e.extensions?.exception?.status === 401)
        ) {
          // not logged in
          JWT.forget();
        }
      }),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map((error) => console.error("GraphQL error", error));
        }
        if (networkError) console.error("Network error", networkError);
      }),
      concat(authLink, httpLink),
    ]),
    cache,
  });

  return apolloClient;
}

export async function clearCache() {
  return apolloClient.cache.reset();
}

/**
 * Execute the given GraphQL query. Errors are thrown.
 * @param query Query
 * @returns Data returned by the server
 */
export async function executeQuery(query: DocumentNode) {
  const apolloClient = await getApolloClient();
  const { data, errors } = await apolloClient?.query({
    query,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  if ((errors?.length ?? 0) > 0) {
    throw errors?.[0];
  }
  return data;
}
