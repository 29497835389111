import {
  ButtonBase,
  createStyles,
  Paper,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { Move } from "@wurzel/uzb-sync";
import clsx from "clsx";
import { CloudOffOutline } from "mdi-material-ui";
import React, { MouseEventHandler } from "react";
import {
  LocalMove,
  RemoteMove,
} from "../../synchronization/database/IDatabase";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.paper,
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      overflow: "hidden",
      "& p": {
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "100%",
      },
    },
    statusIcon: {
      position: "absolute",
      bottom: theme.spacing(0.5),
      right: theme.spacing(0.5),
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  });

interface MoveCardProps {
  move: RemoteMove | LocalMove;
  className?: string;
  onClick?: MouseEventHandler;
}

function MoveCard({
  classes,
  className,
  move,
  onClick,
}: MoveCardProps & WithStyles<typeof styles>) {
  const isLocalOnly = !("customer" in move) && move.remoteId == null;
  const moveData: Move | RemoteMove = "customer" in move ? move : move.data;

  return (
    <Paper
      className={clsx(className, classes.root)}
      component={ButtonBase}
      onClick={onClick}
    >
      <Typography variant="subtitle1" color="primary">
        {moveData.customer?.firstname || moveData.customer?.lastname ? (
          <>
            {`${moveData.customer?.firstname ?? ""} ${moveData.customer
              ?.lastname ?? ""}`.trim()}
          </>
        ) : (
          "(Unbekannter Kunde)"
        )}
      </Typography>
      <Typography variant="body2">
        Von:{" "}
        {(moveData.loadingAddresses as Array<{ city?: string }>)
          .map((address) => address.city)
          .filter((city) => !!city)
          .join("/") || "(unbekannt)"}
      </Typography>
      <Typography variant="body2">
        Nach:{" "}
        {(moveData.unloadingAddresses as Array<{ city?: string }>)
          .map((address) => address.city)
          .filter((city) => !!city)
          .join("/") || "(unbekannt)"}
      </Typography>
      {isLocalOnly && <CloudOffOutline className={classes.statusIcon} />}
    </Paper>
  );
}

export default withStyles(styles)(MoveCard);
