import React from "react";
import {
  Checkbox,
  createStyles,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { ApplyOnBlurTextField as TextField } from "../../../components/ApplyOnBlurTextField";
import { useCurrentMove } from "../containers/Move";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    formSection: {
      display: "flex",
      marginBottom: theme.spacing(3),
      "& > *:first-child": {
        width: theme.spacing(16),
        textAlign: "right",
        paddingRight: theme.spacing(3),
        lineHeight: 1,
        paddingTop: theme.spacing(1),
      },
      "& > *:last-child": {
        flex: 1,
      },
    },
    flexRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1.5),
      },
    },
    shortInput: {
      maxWidth: theme.spacing(15),
    },
    checkboxLabel: {
      marginRight: theme.spacing(3),
    },
    mediumInput: {
      maxWidth: theme.spacing(25),
    },
  });

interface BaseDataProps {
  children: React.ReactNode;
}

function BaseData({ classes }: BaseDataProps & WithStyles<typeof styles>) {
  const {
    move: { data: move },
    update,
  } = useCurrentMove();

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary">
        Umzugsdaten
      </Typography>
      <div className={classes.formSection}>
        <Typography variant="overline">Kunde</Typography>
        <div>
          <div className={classes.flexRow}>
            <FormControl
              variant="filled"
              margin="dense"
              fullWidth
              className={classes.shortInput}
            >
              <InputLabel>Anrede</InputLabel>
              <Select
                input={<FilledInput />}
                value={move.customer.formOfAddress}
                onChange={(e) =>
                  update({
                    type: "setField",
                    payload: {
                      field: "customer.formOfAddress",
                      value: e.target.value,
                    },
                  })
                }
              >
                <MenuItem value="Frau">Frau</MenuItem>
                <MenuItem value="Herr">Herr</MenuItem>
                <MenuItem value="Firma">Firma</MenuItem>
                <MenuItem value="">Keine Angabe</MenuItem>
              </Select>
            </FormControl>
            {move.customer.formOfAddress === "Firma" && (
              <TextField
                label="Firma"
                variant="filled"
                margin="dense"
                fullWidth
                value={move.customer.company ?? ""}
                onChange={(value) =>
                  update({
                    type: "setField",
                    payload: {
                      field: "customer.company",
                      value,
                    },
                  })
                }
              />
            )}
            <TextField
              label="Vorname"
              variant="filled"
              margin="dense"
              fullWidth
              value={move.customer.firstname}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "customer.firstname",
                    value,
                  },
                })
              }
            />
            <TextField
              label="Nachname"
              variant="filled"
              margin="dense"
              fullWidth
              value={move.customer.lastname}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "customer.lastname",
                    value,
                  },
                })
              }
            />
          </div>
          <TextField
            label="Anschrift"
            variant="filled"
            margin="dense"
            fullWidth
            multiline
            rowsMax={2}
            value={move.customer.address}
            onChange={(value) =>
              update({
                type: "setField",
                payload: {
                  field: "customer.address",
                  value: `${value}`.trim(),
                },
              })
            }
          />
          <div className={classes.flexRow}>
            <TextField
              label="Postleitzahl"
              variant="filled"
              margin="dense"
              fullWidth
              className={classes.shortInput}
              value={move.customer.zipCode}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "customer.zipCode",
                    value,
                  },
                })
              }
            />
            <TextField
              label="Ort"
              variant="filled"
              margin="dense"
              fullWidth
              value={move.customer.city}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "customer.city",
                    value,
                  },
                })
              }
            />
          </div>
          <div className={classes.flexRow}>
            <TextField
              label="Telefonnummer"
              variant="filled"
              margin="dense"
              fullWidth
              type="tel"
              value={move.customer.phoneNumber}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "customer.phoneNumber",
                    value,
                  },
                })
              }
            />
            <TextField
              label="E-Mail"
              variant="filled"
              margin="dense"
              fullWidth
              type="email"
              value={move.customer.email}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "customer.email",
                    value,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Rechnungs&shy;anschrift</Typography>
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="invoiceAddressType"
              name="invoiceAddressType"
              row
              value={move.invoiceAddressType}
              onChange={(e) =>
                update({
                  type: "setField",
                  payload: {
                    field: "invoiceAddressType",
                    value: e.target.value,
                  },
                })
              }
            >
              <FormControlLabel
                value="customerAddress"
                control={<Radio color="primary" />}
                label="Wie oben"
              />
              <FormControlLabel
                value="unloadingAddress"
                control={<Radio color="primary" />}
                label="Entladestelle"
              />
              <FormControlLabel
                value="other"
                control={<Radio color="primary" />}
                label="Abweichend"
              />
            </RadioGroup>
          </FormControl>
          {move.invoiceAddressType === "other" && (
            <>
              <div className={classes.flexRow}>
                <FormControl
                  variant="filled"
                  margin="dense"
                  fullWidth
                  className={classes.shortInput}
                >
                  <InputLabel>Anrede</InputLabel>
                  <Select
                    input={<FilledInput />}
                    value={move.invoiceAddress?.formOfAddress ?? ""}
                    onChange={(e) =>
                      update({
                        type: "setField",
                        payload: {
                          field: "invoiceAddress.formOfAddress",
                          value: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="Frau">Frau</MenuItem>
                    <MenuItem value="Herr">Herr</MenuItem>
                    <MenuItem value="Firma">Firma</MenuItem>
                    <MenuItem value="">Keine Angabe</MenuItem>
                  </Select>
                </FormControl>
                {move.invoiceAddress?.formOfAddress === "Firma" && (
                  <TextField
                    label="Firma"
                    variant="filled"
                    margin="dense"
                    fullWidth
                    value={move.invoiceAddress?.company ?? ""}
                    onChange={(value) =>
                      update({
                        type: "setField",
                        payload: {
                          field: "invoiceAddress.company",
                          value,
                        },
                      })
                    }
                  />
                )}
                <TextField
                  label="Vorname"
                  variant="filled"
                  margin="dense"
                  fullWidth
                  value={move.invoiceAddress?.firstname ?? ""}
                  onChange={(value) =>
                    update({
                      type: "setField",
                      payload: {
                        field: "invoiceAddress.firstname",
                        value,
                      },
                    })
                  }
                />
                <TextField
                  label="Nachname"
                  variant="filled"
                  margin="dense"
                  fullWidth
                  value={move.invoiceAddress?.lastname ?? ""}
                  onChange={(value) =>
                    update({
                      type: "setField",
                      payload: {
                        field: "invoiceAddress.lastname",
                        value,
                      },
                    })
                  }
                />
              </div>
              <TextField
                label="Anschrift"
                variant="filled"
                margin="dense"
                fullWidth
                multiline
                rowsMax={2}
                value={move.invoiceAddress?.address}
                onChange={(value) =>
                  update({
                    type: "setField",
                    payload: {
                      field: "invoiceAddress.address",
                      value: `${value}`.trim(),
                    },
                  })
                }
              />
              <div className={classes.flexRow}>
                <TextField
                  label="Postleitzahl"
                  variant="filled"
                  margin="dense"
                  fullWidth
                  className={classes.shortInput}
                  value={move.invoiceAddress?.zipCode ?? ""}
                  onChange={(value) =>
                    update({
                      type: "setField",
                      payload: {
                        field: "invoiceAddress.zipCode",
                        value,
                      },
                    })
                  }
                />
                <TextField
                  label="Ort"
                  variant="filled"
                  margin="dense"
                  fullWidth
                  value={move.invoiceAddress?.city ?? ""}
                  onChange={(value) =>
                    update({
                      type: "setField",
                      payload: {
                        field: "invoiceAddress.city",
                        value,
                      },
                    })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Zahlungs&shy;weise</Typography>
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="paymentMethod"
              name="paymentMethod"
              row
              value={move.paymentMethod}
              onChange={(e) =>
                update({
                  type: "setField",
                  payload: {
                    field: "paymentMethod",
                    value: e.target.value,
                  },
                })
              }
            >
              <FormControlLabel
                value="invoice"
                control={<Radio color="primary" />}
                label="Auf Rechnung"
              />
              <FormControlLabel
                value="cash"
                control={<Radio color="primary" />}
                label="Barzahlung"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Einlagerung</Typography>
        <div>
          <div className={classes.flexRow}>
            <TextField
              type="number"
              label="Miete Lager"
              variant="filled"
              margin="dense"
              value={move.storage?.rent ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "storage.rent",
                    value,
                  },
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Tage</InputAdornment>
                ),
              }}
              className={classes.mediumInput}
            />
            <TextField
              type="number"
              label="Miete Container"
              variant="filled"
              margin="dense"
              value={move.storage?.containerRent ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "storage.containerRent",
                    value,
                  },
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Tage</InputAdornment>
                ),
              }}
              className={classes.mediumInput}
            />
          </div>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Handling Container"
              checked={!!move.storage?.containerHandling}
              onChange={(e, checked) =>
                update({
                  type: "setField",
                  payload: {
                    field: "storage.containerHandling",
                    value: checked,
                  },
                })
              }
              className={classes.checkboxLabel}
            />
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="An- und Abfahrt Container"
              checked={!!move.storage?.containerTransport}
              onChange={(e, checked) =>
                update({
                  type: "setField",
                  payload: {
                    field: "storage.containerTransport",
                    value: checked,
                  },
                })
              }
              className={classes.checkboxLabel}
            />
          </FormGroup>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Sonstiges</Typography>
        <div>
          <div className={classes.flexRow}>
            <TextField
              type="number"
              label="Schwergutzuschlag"
              variant="filled"
              margin="dense"
              value={move.heavyLiftSurcharge ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "heavyLiftSurcharge",
                    value,
                  },
                })
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              className={classes.mediumInput}
            />
            <TextField
              type="number"
              label="Neuwert"
              helperText="(bei gewünschter Versicherung)"
              variant="filled"
              margin="dense"
              value={move.insuranceReplacementValue ?? ""}
              onChange={(value) =>
                update({
                  type: "setField",
                  payload: {
                    field: "insuranceReplacementValue",
                    value,
                  },
                })
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              className={classes.mediumInput}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(BaseData);
