import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@material-ui/core";
import { RoomItem } from "@wurzel/uzb-sync";
import FurnitureForm, { RoomItemFormValue } from "./FurnitureForm";

interface EditFurnitureDialogProps extends DialogProps {
  item?: RoomItem | null;
  onEditItem: (
    item: Partial<RoomItem> & {
      id: string;
      photos: Array<{ id: string; blob?: Blob }>;
    }
  ) => void;
  onClose: () => void;
}

export default function EditFurnitureDialog({
  classes,
  onEditItem,
  onClose,
  open,
  item,
  ...other
}: EditFurnitureDialogProps) {
  const [value, setValue] = useState<RoomItemFormValue | null | undefined>(
    item
  );

  useEffect(() => {
    if (open) {
      setValue(item);
    }
  }, [open, item]);

  const handleEditFurniture = useCallback(() => {
    if (value && item) {
      onEditItem({
        id: item.id,
        name: value.name,
        size: parseFloat(`${value.size}`) || 1,
        assemblyTime: parseFloat(`${value.assemblyTime}`) || 0,
        disassemblyTime: parseFloat(`${value.disassemblyTime}`) || 0,
        assembly: value.assembly,
        disassembly: value.disassembly,
        disposal: value.disposal,
        note: value.note,
        photos: value.photos ?? [],
      });
    }
  }, [onEditItem, value, item]);

  return value ? (
    <Dialog {...other} open={open} onClose={onClose}>
      <DialogTitle>Gegenstand bearbeiten</DialogTitle>
      <DialogContent>
        <FurnitureForm value={value} onChange={setValue} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} tabIndex={1}>
          Abbrechen
        </Button>
        <Button
          onClick={handleEditFurniture}
          disabled={
            value.name.trim().length === 0 ||
            (parseInt(`${value.size}`, 10) || 0) <= 0
          }
          color="primary"
          tabIndex={0}
        >
          Übernehmen
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
