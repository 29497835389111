import React, { useCallback, useState } from "react";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import {
  getVariables,
  TemplateType,
} from "@wurzel/uzb-templates/dist/generateVariables";
import { appendWorksheet } from "@wurzel/uzb-templates/dist/excelUtil";
import { applyTemplateToWorkbook } from "@wurzel/uzb-templates/dist/templateEngine";
import { useCurrentMove } from "../../containers/Move";
import { useSynchronizedData } from "../../../synchronization/SynchronizedDataContext";
import { useSnackbar } from "material-ui-snackbar-provider";
import {
  getWorkbook,
  shareFile,
  shareWorkbook,
  getTemplateName,
} from "./template";
import clsx from "clsx";
import { documents } from "./documentTypes";
import { useAuthenticationContext } from "../../../core/AuthenticationContext";
import { useSearchParamDialogOpen } from "../../../../hooks/useDialogOpen";
import { calculate } from "@wurzel/uzb-calculation/dist/calculation";
import { calculateOffer } from "@wurzel/uzb-calculation/dist/offer";
import { cordovaHttpFetchImpl } from "../../../../utils/cordovaFetch";
import { getAuthorizationHeader, getServerUrl } from "../../../core/api";
import useIsOnline from "../../../../hooks/useIsOnline";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    documents: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
      gridGap: theme.spacing(2),
    },
    documentCard: {
      padding: theme.spacing(2),
      position: "relative",
      cursor: "pointer",
      "&:hover": {
        boxShadow: theme.shadows[5],
      },
    },
    disabled: {
      "& > *": {
        opacity: 0.5,
        pointerEvents: "none",
      },
    },
    loader: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginLeft: -20,
      marginTop: -20,
      opacity: 1,
    },
    dialogTitle: {
      paddingBottom: 0,
    },
  });

interface DocumentsProps {
  children: React.ReactNode;
}

function Documents({ classes }: DocumentsProps & WithStyles<typeof styles>) {
  const {
    move: { data: move },
  } = useCurrentMove();
  const customizeDocumentDialog = useSearchParamDialogOpen("document");
  const showCustomizeDocumentDialog = customizeDocumentDialog.handleOpen;
  const customizedDocument = documents.find(
    (d) => d.type === customizeDocumentDialog.data
  );
  const [customizationData, setCustomizationData] = useState<any>();

  const { database, updateTemplate, configuration } = useSynchronizedData();
  const snackbar = useSnackbar();
  const { user, showLoginDialog } = useAuthenticationContext();
  const isOnline = useIsOnline();

  const [loading, setLoading] = useState<string | null>(null);

  const generateDocument = useCallback(
    async (type: TemplateType) => {
      let template = await database.getTemplate(type);
      if (template == null) {
        // template isn't available, try to silently download it first
        try {
          template = await updateTemplate(type);
        } catch (e) {
          snackbar.showMessage(
            "Die Vorlage für dieses Dokument konnte nicht heruntergeladen werden."
          );
          return;
        }
        if (template == null) {
          snackbar.showMessage(
            "Die Vorlage für dieses Dokument ist nicht verfügbar."
          );
          return;
        }
      }

      const workbook = await getWorkbook(template);
      const calculation = calculate(move, configuration);
      const offer = calculateOffer(calculation, move, configuration);
      applyTemplateToWorkbook(
        workbook,
        getVariables(
          type,
          move,
          configuration,
          user ?? { fullName: "", signaturePrefix: "" },
          calculation,
          offer
        ),
        { hideUnknownVariables: true }
      );
      return { workbook, template };
    },
    [snackbar, configuration, database, move, updateTemplate, user]
  );

  const handleExportDocument = useCallback(
    async (
      type: TemplateType,
      targetElement?: HTMLElement,
      force: boolean = false
    ) => {
      // const documentType = documents.find((d) => d.type === type);
      if (!force) {
        setCustomizationData(null);
        showCustomizeDocumentDialog(type);
        return;
      }

      setLoading(type);
      try {
        if (type === TemplateType.Offer && customizationData?.withLetter) {
          const letter = await generateDocument(TemplateType.OfferLetter);
          const offer = await generateDocument(TemplateType.Offer);
          if (offer && letter) {
            // join the two worksheets, this didn't work: https://github.com/exceljs/exceljs/issues/591
            const { workbook, template } = offer;
            const { workbook: letterWorkbook } = letter;
            appendWorksheet(workbook.worksheets[0], letterWorkbook);
            letterWorkbook.worksheets[0].name = "Anschreiben";
            letterWorkbook.worksheets[1].name = "Umzugsvertrag";
            await shareWorkbook(letterWorkbook, template, targetElement);
          }
        } else {
          const result = await generateDocument(type);
          if (result) {
            const { workbook, template } = result;
            await shareWorkbook(workbook, template, targetElement);
          }
        }
      } catch (e) {
        snackbar.showMessage("Das Dokument konnte nicht erstellt werden.");
        console.error(e);
      } finally {
        setLoading(null);
      }
    },
    [generateDocument, showCustomizeDocumentDialog, customizationData, snackbar]
  );

  const handleExportPdfDocument = useCallback(
    async (type: TemplateType, targetElement?: HTMLElement) => {
      setLoading(type);
      try {
        const templateTypes = [type];
        if (type === TemplateType.Offer && customizationData?.withLetter) {
          templateTypes.unshift(TemplateType.OfferLetter);
        }
        const calculation = calculate(move, configuration);
        const offer = calculateOffer(calculation, move, configuration);
        const res = await cordovaHttpFetchImpl(
          `${await getServerUrl()}/api/documents/generate`,
          {
            mode: "cors",
            method: "POST",
            headers: {
              Authorization: getAuthorizationHeader(),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              move,
              calculation,
              offer,
              configuration,
              templateTypes,
            }),
          }
        );
        if (res.status !== 200) {
          if (res.status === 401) {
            snackbar.showMessage(
              "Zum Erstellen von PDF-Dokumenten ist eine Anmeldung erforderlich."
            );
            showLoginDialog(() => handleExportPdfDocument(type, targetElement));
            return;
          }
          throw new Error(`${res.status} ${res.statusText}`);
        }
        const blob = await res.blob();
        await shareFile(
          blob,
          `${getTemplateName(type)}.pdf`,
          `${getTemplateName(type)}-${Date.now()}.pdf`,
          targetElement
        );
      } catch (e) {
        snackbar.showMessage("Das PDF-Dokument konnte nicht erstellt werden.");
        console.error(e);
      } finally {
        setLoading(null);
      }
    },
    [customizationData, snackbar, configuration, move, showLoginDialog]
  );

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary">
        Dokumente
      </Typography>
      <div className={classes.documents}>
        {documents.map(({ type, title, description }) => (
          <Paper
            key={type}
            className={clsx(classes.documentCard, {
              [classes.disabled]: loading != null,
            })}
            onClick={(e) => handleExportDocument(type, e.currentTarget)}
          >
            <Typography variant="subtitle1" color="primary">
              {title}
            </Typography>
            <Typography variant="body2">{description}</Typography>
            {loading === type && (
              <CircularProgress className={classes.loader} disableShrink />
            )}
          </Paper>
        ))}
      </div>
      <Dialog
        open={customizeDocumentDialog.open}
        onClose={customizeDocumentDialog.handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className={classes.dialogTitle}>
          {customizedDocument?.title}
        </DialogTitle>
        <DialogContent>
          {customizedDocument?.configurationForm && (
            <customizedDocument.configurationForm
              value={customizationData}
              onChange={setCustomizationData}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={customizeDocumentDialog.handleClose}>
            Abbrechen
          </Button>
          <Button
            color="primary"
            onClick={(e) => {
              handleExportDocument(
                customizeDocumentDialog.data as TemplateType,
                e.currentTarget,
                true
              );
              customizeDocumentDialog.handleClose();
            }}
          >
            Dokument erstellen (Excel)
          </Button>
          <Button
            color="primary"
            onClick={(e) => {
              handleExportPdfDocument(
                customizeDocumentDialog.data as TemplateType,
                e.currentTarget
              );
              customizeDocumentDialog.handleClose();
            }}
            disabled={!isOnline}
          >
            Dokument versenden (PDF)
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(Documents);
