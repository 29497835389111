import { Plugins } from "@capacitor/core";
import JWT from "jwt-client";
import { clearCache } from "../synchronization/apolloClient";
import { cordovaHttpFetchImpl as fetch } from "../../utils/cordovaFetch";
import { User } from "./AuthenticationContext";

const { Storage } = Plugins;

/**
 * Gets the server url. On iOS, this uses UserDefaults and thus can be
 * configured from the settings using an iOS Settings Bundle.
 * @see https://developer.apple.com/library/archive/documentation/Cocoa/Conceptual/UserDefaults/Preferences/Preferences.html
 * @see https://capacitorjs.com/docs/apis/storage
 */
export async function getServerUrl(): Promise<string | null> {
  if (process.env.NODE_ENV !== "production") {
    return `http://${window.location.hostname}:8080`;
  }
  const { value } = await Storage.get({ key: "serverUrl" });
  return value || "https://umzug.wertarbyte.app";
}

export function getAuthorizationHeader() {
  return JWT.get() ?? "";
}

/**
 * Authenticate with the given credentials and store the JWT token.
 * @param username Username
 * @param password Password
 * @returns The user
 */
export async function login(username: string, password: string): Promise<User> {
  const response = await fetch(`${await getServerUrl()}/api/authentication`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username,
      password,
    }),
  });
  if (response.status !== 201) {
    throw new Error("Not authenticated");
  }

  const {
    accessToken,
    user,
  }: { accessToken: string; user: User } = await response.json();

  try {
    JWT.keep(JWT.read(accessToken));
  } catch (e) {
    JWT.forget();
    throw e;
  }

  return user;
}

export function logout() {
  JWT.forget();
  clearCache();
}
