import { PackingMaterial } from "@wurzel/uzb-sync/dist/Configuration";

export function getUnit(unit?: PackingMaterial["unit"]) {
  return (unit ? getUnits()[unit] : null) ?? "Stk.";
}

export function getUnits() {
  return {
    kilogram: "kg",
    meter: "m",
    piece: "Stk.",
  };
}
