import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { ApplyOnBlurTextField as TextField } from "../../../../components/ApplyOnBlurTextField";
import { useCurrentMove } from "../../containers/Move";
import LocalizedDatePicker from "../LocalizedDatePicker";

const styles = (theme: Theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
});

function OfferForm({
  classes,
  value,
  onChange,
  mode,
}: {
  value: any;
  onChange: (newValue: any) => void;
  mode?: "letter" | "offer";
} & WithStyles<typeof styles>) {
  const {
    move: { data: move },
    update,
  } = useCurrentMove();

  return (
    <div>
      <LocalizedDatePicker
        autoOk
        label="Angebotsdatum"
        inputVariant="filled"
        margin="dense"
        value={move.documentData?.offer?.date ?? null}
        onChange={(date) => {
          update({
            type: "setField",
            payload: {
              field: "documentData.offer.date",
              value: date,
            },
          });
        }}
        clearable
        className={classes.textField}
      />
      {mode !== "letter" && (
        <>
          <TextField
            variant="filled"
            margin="dense"
            multiline
            rows={1}
            rowsMax={5}
            label="Besonderheiten"
            fullWidth
            className={classes.textField}
            value={move.documentData?.offer?.notes ?? ""}
            onChange={(value) =>
              update({
                type: "setField",
                payload: {
                  field: "documentData.offer.notes",
                  value,
                },
              })
            }
          />
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="Mit Anschreiben"
            checked={!!value?.withLetter}
            onChange={(e, checked) => {
              onChange({ withLetter: checked });
            }}
          />
        </>
      )}
    </div>
  );
}

export default withStyles(styles)(OfferForm);
