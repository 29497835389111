import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonBase,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { AsyncButton } from "@wa/werkstoff-core";
import { useSynchronizedData } from "../../synchronization/SynchronizedDataContext";
import { RoomItem } from "@wurzel/uzb-sync";
import { CloudSyncOutline } from "mdi-material-ui";
import { useSnackbar } from "material-ui-snackbar-provider";
import useIsOnline from "../../../hooks/useIsOnline";
import { useAuthenticationContext } from "../../core/AuthenticationContext";
import FurnitureForm, { RoomItemFormValue } from "./FurnitureForm";

const styles = (theme: Theme) =>
  createStyles({
    roomList: {
      display: "grid",
      gridGap: theme.spacing(2),
      gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
      overflowY: "auto",
    },
    roomListItem: {
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
      height: theme.spacing(7),
      textAlign: "left",
      justifyContent: "left",
      alignItems: "center",
      display: "flex",
      ...theme.typography.body2,
      "& div:first-child": {
        flex: 1,
        marginRight: theme.spacing(2),
      },
      "& div:nth-child(2)": {
        color: theme.palette.text.secondary,
      },
    },
    dialogTitleButton: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  });

interface AddFurnitureDialogProps extends DialogProps {
  predefinedRoomId?: string;
  onAddFurniture: (
    item: RoomItem & { photos: Array<{ id: string; blob?: Blob }> }
  ) => void;
  onClose: () => void;
}

const defaultCustomItemValue: Omit<RoomItemFormValue, "predefinedId"> = {
  name: "",
  size: "",
  assembly: false,
  disposal: false,
  disassembly: false,
};

function AddFurnitureDialog({
  classes,
  onAddFurniture,
  onClose,
  open,
  predefinedRoomId,
  ...other
}: AddFurnitureDialogProps & WithStyles<typeof styles>) {
  const snackbar = useSnackbar();
  const { configuration, synchronize } = useSynchronizedData();
  const isOnline = useIsOnline();

  const predefinedItems = useMemo(
    () =>
      predefinedRoomId != null
        ? configuration.rooms.find((r) => r.id === predefinedRoomId)?.items ??
          []
        : [],
    [configuration, predefinedRoomId]
  );

  const handleSelectPredefinedItem = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const predefinedId = e.currentTarget.dataset.item;
      if (predefinedId != null) {
        const predefinedItem = predefinedItems.find(
          (i) => i.id === predefinedId
        );
        if (predefinedItem != null) {
          setValue({
            ...predefinedItem,
            predefinedId: predefinedItem.id,
            assemblyTime: predefinedItem.assemblyTime ?? "",
            disassemblyTime: predefinedItem.disassemblyTime ?? "",
            assembly: false,
            disassembly: false,
            disposal: false,
          });
        }
      }
    },
    [predefinedItems]
  );

  const [value, setValue] = useState<RoomItemFormValue | null | undefined>();

  useEffect(() => {
    if (open) {
      setValue(null);
    }
  }, [open]);
  useEffect(() => {
    if (open && predefinedItems.length === 0) {
      setValue(defaultCustomItemValue);
    }
  }, [predefinedItems, open]);

  const handleAddFurniture = useCallback(() => {
    if (value) {
      onAddFurniture({
        id: uuidv4(),
        predefinedId: value.predefinedId,
        name: value.name,
        amount: 1,
        size: parseFloat(`${value.size}`) || 1,
        assemblyTime: parseFloat(`${value.assemblyTime}`) || 0,
        disassemblyTime: parseFloat(`${value.disassemblyTime}`) || 0,
        assembly: value.assembly,
        disassembly: value.disassembly,
        disposal: value.disposal,
        note: value.note,
        photos: value.photos ?? [],
      });
    }
  }, [value, onAddFurniture]);

  const auth = useAuthenticationContext();

  const handleUpdateFurniture = useCallback(async () => {
    try {
      await synchronize();
      snackbar.showMessage(
        "Die vordefinierte Möbelstücke wurden aktualisiert."
      );
    } catch (e) {
      if (e.extensions?.exception?.status === 401) {
        snackbar.showMessage(
          "Zur Aktualisierung der vordefinierten Möbelstücke ist eine Anmeldung erforderlich."
        );
        auth.showLoginDialog(handleUpdateFurniture);
      } else {
        snackbar.showMessage(
          "Die vordefinierten Möbelstücke konnten nicht aktualisiert werden."
        );
      }
    }
  }, [synchronize, snackbar, auth]);

  return (
    <Dialog {...other} open={open} onClose={onClose}>
      <DialogTitle>
        Gegenstand hinzufügen
        <AsyncButton
          component={IconButton}
          onClick={handleUpdateFurniture}
          className={classes.dialogTitleButton}
          disabled={!isOnline}
        >
          <CloudSyncOutline />
        </AsyncButton>
      </DialogTitle>
      <DialogContent className={classes.roomList}>
        {value ? (
          <FurnitureForm value={value} onChange={setValue} isNew />
        ) : (
          <>
            {[...predefinedItems]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <ButtonBase
                  key={item.id}
                  className={classes.roomListItem}
                  onClick={handleSelectPredefinedItem}
                  data-item={item.id}
                  component="button"
                >
                  <div>{item.name}</div>
                  <div>{item.size.toLocaleString()} RE</div>
                </ButtonBase>
              ))}
            <ButtonBase
              className={classes.roomListItem}
              onClick={() => {
                setValue(defaultCustomItemValue);
              }}
            >
              Etwas anderes…
            </ButtonBase>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} tabIndex={1}>
          Abbrechen
        </Button>
        <Button
          onClick={handleAddFurniture}
          disabled={
            value?.name.trim().length === 0 ||
            (parseInt(`${value?.size}`, 10) || 0) <= 0
          }
          color="primary"
          tabIndex={0}
        >
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(AddFurnitureDialog);
