import React, { useMemo } from "react";
import {
  createStyles,
  InputAdornment,
  MenuItem,
  Select,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { useCurrentMove } from "../containers/Move";
import { ApplyOnBlurTextField as TextField } from "../../../components/ApplyOnBlurTextField";
import { calculate } from "@wurzel/uzb-calculation/dist/calculation";
import { useSynchronizedData } from "../../synchronization/SynchronizedDataContext";
import { PopoverIconButton } from "@wa/werkstoff-core";
import { Pencil } from "mdi-material-ui";
import { calculateOffer } from "@wurzel/uzb-calculation/dist/offer";
import { euro, hours, percentage } from "../../../utils/textUtil";

const getUnit = (u: string) => {
  switch (u) {
    case "meter":
      return "m";
    case "kilogram":
      return "kg";
    case "piece":
    default:
      return "Stk.";
  }
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      "& table td:nth-child(1)": {
        width: 200,
      },
      "& table td:nth-child(2)": {
        minWidth: 90,
        textAlign: "right",
        fontVariantNumeric: "tabular-nums",
      },
    },
    formSection: {
      display: "flex",
      marginBottom: theme.spacing(3),
      "& > *:first-child": {
        width: theme.spacing(16),
        textAlign: "right",
        paddingRight: theme.spacing(3),
        lineHeight: 1,
        paddingTop: theme.spacing(1),
        "& > .MuiTypography-root": {
          lineHeight: 1,
        },
      },
      "& > div:first-child": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      },
      "& > *:last-child": {
        paddingTop: theme.spacing(0.5),
        flex: 1,
      },
    },
    result: {
      fontWeight: "bold",
    },
    buttonCell: {
      position: "relative",
    },
    surchargeButton: {
      position: "absolute",
      padding: theme.spacing(1),
      transform: `translateY(${theme.spacing(-2.5)}px)`,
    },
    surchargePopover: {
      padding: theme.spacing(2),
      width: 200,
    },
  });

function Calculation({ classes }: WithStyles<typeof styles>) {
  const {
    move: { data: move },
    update,
  } = useCurrentMove();
  const { configuration } = useSynchronizedData();

  const results = useMemo(() => calculate(move, configuration), [
    move,
    configuration,
  ]);
  const offer = useMemo(() => calculateOffer(results, move, configuration), [
    results,
    move,
    configuration,
  ]);

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary">
        Kalkulation
      </Typography>
      <div className={classes.formSection}>
        <Typography variant="overline">Personal Wurzel</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>Berater:</td>
                <td>{euro(results.consultant.price)}</td>
              </tr>
              <tr>
                <td>PKW Berater:</td>
                <td>
                  {results.consultantCar != null
                    ? euro(results.consultantCar)
                    : "–"}
                </td>
              </tr>
              <tr>
                <td>Teamleiter:</td>
                <td>{euro(results.teamLeader.price)}</td>
                <td>
                  ({results.teamLeader.count} à {hours(results.teamLeader.time)}
                  {results.teamLeader.assemblyTime > 0 &&
                    ` + ${hours(results.teamLeader.assemblyTime)} Montage`}
                  )
                </td>
              </tr>
              <tr>
                <td>Träger:</td>
                <td>{euro(results.carrier.price)}</td>
                <td>
                  ({results.carrier.count} à {hours(results.carrier.time)})
                </td>
              </tr>
              <tr>
                <td>Aushilfen:</td>
                <td>{euro(results.temporaryEmployee.price)}</td>
                <td>
                  ({results.temporaryEmployee.count} à{" "}
                  {hours(results.temporaryEmployee.time)})
                </td>
              </tr>
              <tr>
                <td>Azubis:</td>
                <td>{euro(results.trainee.price)}</td>
                <td>
                  ({results.trainee.count} à {hours(results.trainee.time)})
                </td>
              </tr>
              <tr>
                <td>Glaspacker:</td>
                <td>{euro(results.glassPacker.price)}</td>
                <td>
                  ({results.glassPacker.count} à{" "}
                  {hours(results.glassPacker.time)})
                </td>
              </tr>
              <tr>
                <td>LKW:</td>
                <td>{euro(results.truck)}</td>
              </tr>
              <tr>
                <td>PKW:</td>
                <td>{euro(results.car)}</td>
              </tr>
              <tr>
                <td>Maut:</td>
                <td>{euro(results.toll)}</td>
              </tr>
              <tr>
                <td>Pauschale Außenaufzug:</td>
                <td>{euro(results.lift)}</td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      {results.contractors.some((c) => c.workType !== "other") && (
        <div className={classes.formSection}>
          <Typography variant="overline">Personal Fremdfirmen</Typography>
          <div>
            <Typography variant="body2">
              <table>
                {results.contractors
                  .filter((c) => c.workType !== "other")
                  .map((contractor) => (
                    <tr id={contractor.id}>
                      <td>{contractor.name}</td>
                      <td>{euro(contractor.cost)}</td>
                      <td>
                        ({contractor.count}{" "}
                        {contractor.workType === "assembly"
                          ? contractor.count === 1
                            ? "Monteur"
                            : "Monteure"
                          : "Träger"}
                        )
                      </td>
                    </tr>
                  ))}
              </table>
            </Typography>
          </div>
        </div>
      )}
      <div className={classes.formSection}>
        <Typography variant="overline">Beladung</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>Laderaum:</td>
                <td>
                  {results.totalVolume.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}{" "}
                  m³
                </td>
              </tr>
              <tr>
                <td>Möbelwagenmeter:</td>
                <td>
                  {results.totalVolumeMWM.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}{" "}
                  m
                </td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Zeiten</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>Packzeit</td>
                <td>{hours(results.packingTime)}</td>
                <td>({results.packingStaffCount} Mitarbeiter)</td>
              </tr>
              <tr>
                <td>Montagezeit</td>
                <td>{hours(results.assemblyTime)}</td>
              </tr>
              <tr>
                <td>Gesamteinsatzzeit Nahverkehr</td>
                <td>{hours(results.totalTimeNear)}</td>
              </tr>
              <tr>
                <td>Gesamteinsatzzeit Fernverkehr</td>
                <td>{hours(results.totalTimeFar)}</td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Verpackungs&shy;material</Typography>
        <div>
          <Typography variant="body2">
            <table>
              {results.packingMaterial.map((packingMaterial) => (
                <tr id={packingMaterial.packingMaterial.id}>
                  <td>{packingMaterial.packingMaterial.name}</td>
                  <td>{euro(packingMaterial.price)}</td>
                  <td>
                    ({packingMaterial.amount}{" "}
                    {getUnit(packingMaterial.packingMaterial.unit)})
                  </td>
                </tr>
              ))}
              <tr>
                <td>Anlieferung Packmaterial:</td>
                <td>
                  {results.deliveryPackingMaterial == null
                    ? "(entfällt)"
                    : euro(results.deliveryPackingMaterial)}
                </td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Lager&shy;kosten</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>Lagermiete:</td>
                <td>{euro(results.storageRent)}</td>
              </tr>
              <tr>
                <td>Miete Container:</td>
                <td>{euro(results.containerRent)}</td>
              </tr>
              <tr>
                <td>Container Handling:</td>
                <td>{euro(results.containerHandling)}</td>
                <td>(in + out)</td>
              </tr>
              <tr>
                <td>An- und Abfahrt Container:</td>
                <td>{euro(results.containerTransport)}</td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Sonstiges</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>Halteverbotszonen:</td>
                <td>{euro(results.noParkingZones)}</td>
              </tr>
              <tr>
                <td>Schwergutzuschlag:</td>
                <td>{euro(move.heavyLiftSurcharge)}</td>
              </tr>
              <tr>
                <td>Versicherung:</td>
                <td>{euro(results.insuranceFee)}</td>
              </tr>
              {results.contractors
                .filter((c) => c.workType === "other")
                .map((contractor) => (
                  <tr key={contractor.id}>
                    <td>{contractor.name}:</td>
                    <td>{euro(contractor.cost)}</td>
                  </tr>
                ))}
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Summe</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>Summe Einzelkosten gesamt:</td>
                <td>{euro(results.total)}</td>
              </tr>
              <tr>
                <td>Aufschlag:</td>
                <td>{euro(results.absoluteSurcharge)}</td>
                <td>({percentage(results.relativeSurcharge)})</td>
                <td className={classes.buttonCell}>
                  <PopoverIconButton
                    icon={Pencil}
                    PopoverProps={{
                      classes: { paper: classes.surchargePopover },
                    }}
                    className={classes.surchargeButton}
                  >
                    <TextField
                      fullWidth
                      label="Aufschlag"
                      variant="filled"
                      margin="dense"
                      value={move.surcharge ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "surcharge",
                            value,
                          },
                        })
                      }
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Select
                              value={move.surchargeType || "percentage"}
                              disableUnderline
                              onChange={(e) => {
                                update({
                                  type: "setField",
                                  payload: {
                                    field: "surchargeType",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            >
                              <MenuItem value="percentage" dense>
                                %
                              </MenuItem>
                              <MenuItem value="absolute" dense>
                                €
                              </MenuItem>
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </PopoverIconButton>
                </td>
              </tr>
              <tr className={classes.result}>
                <td>Preis für den Umzug:</td>
                <td>{euro(results.totalWithSurcharge)}</td>
              </tr>
              <tr>
                <td>zzgl. Kleinteilepauschale:</td>
                <td>{euro(move.smallParts)}</td>
                <td className={classes.buttonCell}>
                  <PopoverIconButton
                    icon={Pencil}
                    PopoverProps={{
                      classes: { paper: classes.surchargePopover },
                    }}
                    className={classes.surchargeButton}
                  >
                    <TextField
                      fullWidth
                      autoFocus
                      type="number"
                      label="Kleinteilepauschale"
                      variant="filled"
                      margin="dense"
                      value={move.smallParts ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "smallParts",
                            value,
                          },
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                    />
                  </PopoverIconButton>
                </td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Angebot</Typography>
        <div>
          <Typography variant="body2">
            <table>
              <tr>
                <td>Angebotspreis:</td>
                <td>{euro(offer.totalWithoutDiscount)}</td>
              </tr>
              <tr>
                <td>Kundenrabatt:</td>
                <td>
                  {offer.absoluteDiscount > 0
                    ? `− ${euro(offer.absoluteDiscount)}`
                    : "–"}
                </td>
                <td>
                  {offer.absoluteDiscount > 0
                    ? `(${percentage(offer.relativeDiscount)})`
                    : ""}
                </td>
                <td className={classes.buttonCell}>
                  <PopoverIconButton
                    icon={Pencil}
                    PopoverProps={{
                      classes: { paper: classes.surchargePopover },
                    }}
                    className={classes.surchargeButton}
                  >
                    <TextField
                      fullWidth
                      label="Kundenrabatt"
                      variant="filled"
                      margin="dense"
                      value={move.discount ?? ""}
                      onChange={(value) =>
                        update({
                          type: "setField",
                          payload: {
                            field: "discount",
                            value,
                          },
                        })
                      }
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Select
                              value={move.discountType || "percentage"}
                              disableUnderline
                              onChange={(e) => {
                                update({
                                  type: "setField",
                                  payload: {
                                    field: "discountType",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            >
                              <MenuItem value="percentage" dense>
                                %
                              </MenuItem>
                              <MenuItem value="absolute" dense>
                                €
                              </MenuItem>
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </PopoverIconButton>
                </td>
              </tr>
              <tr className={classes.result}>
                <td>Voraussichtlicher Endbetrag:</td>
                <td>{euro(offer.total)}</td>
              </tr>
              <tr className={classes.result}>
                <td>inkl. {percentage(offer.vatRate, 0)} MwSt.:</td>
                <td>{euro(offer.totalGross)}</td>
              </tr>
            </table>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Calculation);
