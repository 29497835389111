import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
  CircularProgress,
} from "@material-ui/core";
import ChevronLeft from "mdi-material-ui/ChevronLeft";
import DeleteOutline from "mdi-material-ui/DeleteOutline";
import { Plugins, StatusBarStyle } from "@capacitor/core";
import FullscreenDialog from "material-ui-fullscreen-dialog/lib/FullscreenDialogFrame";
import AppBar from "../../../../components/AppBar";
import clsx from "clsx";
import { useAttachmentPhoto } from "../AttachmentPhoto";
const { StatusBar } = Plugins;

interface Props {
  photo?: { id: string; url?: string };
  onClose: () => void;
  onDelete?: () => void;
  open: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    immersiveAppBar: {
      color: "#fff",
      background:
        "linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,0.4)) !important",
      boxShadow: "none",
      position: "absolute",
      minHeight: theme.spacing(6.5),
    },
    dialogPaper: {
      backgroundColor: "#000",
    },
    photo: {
      width: "100%",
      height: "100%",
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundColor: "#000",
      backgroundRepeat: "no-repeat",
      opacity: 0,
      transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeIn,
      }),
    },
    loaded: {
      opacity: 1,
    },
    loader: {
      position: "absolute",
      left: "50%",
      top: "50%",
      marginLeft: -20,
      marginTop: -20,
    },
  });

function PhotoDialog({
  classes,
  photo,
  open,
  onClose,
  onDelete,
}: Props & WithStyles<typeof styles>) {
  // use the dark statusbar style if the photo dialog is open
  React.useEffect(() => {
    if (open) {
      StatusBar.setStyle({ style: StatusBarStyle.Dark }).catch(()=>{});
    }
    return () => {
      StatusBar.setStyle({ style: StatusBarStyle.Light }).catch(()=>{});
    };
  }, [open]);

  const attachment = useAttachmentPhoto({
    id: photo?.id ?? "",
    skip: photo == null || photo.url != null,
  });

  const photoUrl = photo?.url || attachment.url;

  return (
    <FullscreenDialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <div
        className={clsx(classes.photo, {
          [classes.loaded]: !attachment.loading || photo?.url != null,
        })}
        style={{
          backgroundImage: photoUrl != null ? `url(${photoUrl})` : undefined,
        }}
      />
      <AppBar
        leftIcon={ChevronLeft}
        onButtonClick={onClose}
        rightIcon={onDelete ? DeleteOutline : undefined}
        onRightButtonClick={onDelete}
        className={classes.immersiveAppBar}
      />
      {attachment.loading && (
        <CircularProgress className={classes.loader} disableShrink />
      )}
    </FullscreenDialog>
  );
}

export default withStyles(styles)(PhotoDialog);
