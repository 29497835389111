import React from "react";
import ReactDOM from "react-dom";
import { Plugins } from "@capacitor/core";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { isNative } from "./utils/capacitor";

if (process.env.NODE_ENV === "production") {
  const { Offline: OfflineIntegration } = require("@sentry/integrations");

  const capacitorConfig = {
    dsn:
      "https://dc6093e3a54d4192a03ab1393440b00c@o1042094.ingest.sentry.io/6011074",
    release: "1.0 (14)",
    integrations: [
      new OfflineIntegration({
        maxStoredEvents: 42,
      }),
    ],
  };

  const SentryReact = require("@sentry/react");
  if (isNative()) {
    // TODO with Capacitor v3, we can get the actual app version (https://capacitorjs.com/docs/apis/app#appinfo)
    const SentryCapacitor = require("@sentry/capacitor");
    SentryCapacitor.init(capacitorConfig, SentryReact.init);
  } else {
    SentryReact.init(capacitorConfig);
  }
  try {
    const user = JSON.parse(localStorage.getItem("uzb_user"));
    SentryReact.setUser({
      id: user.id,
      username: user.username,
    });
  } catch (e) {}
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
  () => {
    if (isNative()) {
      Plugins.Keyboard.setScroll({ isDisabled: false });
      Plugins.Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
  }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
