import React from "react";
import {
  Button,
  Checkbox,
  createStyles,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { Plus, TrashCanOutline } from "mdi-material-ui";
import { v4 as uuidv4 } from "uuid";
import { Move, moveActions } from "@wurzel/uzb-sync";
import { useCurrentMove } from "../containers/Move";
import { useSnackbar } from "material-ui-snackbar-provider";
import { ApplyOnBlurTextField as TextField } from "../../../components/ApplyOnBlurTextField";
import Photos from "./photos/Photos";
import { useSynchronizedData } from "../../synchronization/SynchronizedDataContext";

function hasDuplicates(array: string[]) {
  const set = new Set<string>();
  for (const item of array) {
    if (set.has(item)) {
      return true;
    }
    set.add(item);
  }
  return false;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    formSection: {
      display: "flex",
      marginBottom: theme.spacing(3),
      "& > *:first-child": {
        width: theme.spacing(16),
        textAlign: "right",
        paddingRight: theme.spacing(3),
        lineHeight: 1,
        paddingTop: theme.spacing(1),
        "& > .MuiTypography-root": {
          lineHeight: 1,
        },
      },
      "& > div:first-child": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      },
      "& > *:last-child": {
        flex: 1,
      },
    },
    flexRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1.5),
      },
    },
    mediumInput: {
      maxWidth: theme.spacing(25),
    },
    shortInput: {
      maxWidth: theme.spacing(15),
    },
    checkboxLabel: {
      marginRight: theme.spacing(3),
    },
    addAddressButton: {
      alignSelf: "flex-start",
      margin: theme.spacing(-2, 0, 3, 16),
    },
    distanceCell: {
      width: 80,
    },
    distanceField: {
      width: 80,
      "& input": {
        paddingTop: theme.spacing(1),
      },
    },
    photos: {
      marginTop: theme.spacing(1),
    },
  });

interface AddressesProps {
  children: React.ReactNode;
}

function Addresses({ classes }: AddressesProps & WithStyles<typeof styles>) {
  const snackbar = useSnackbar();
  const { move: localMove, update } = useCurrentMove();
  const { data: move } = localMove;
  const { database } = useSynchronizedData();

  const loadingAddresses: Move["loadingAddresses"] =
    move.loadingAddresses?.length > 0
      ? move.loadingAddresses
      : [{ id: "default", ...move.customer }];

  const isCityAmbiguous = hasDuplicates([
    ...loadingAddresses.map((address) => address.city),
    ...move.unloadingAddresses.map((address) => address.city),
  ]);

  const setTotalDistance = (
    from: {
      type: "loadingAddress" | "unloadingAddress" | "company";
      id?: string;
    },
    to: {
      type: "loadingAddress" | "unloadingAddress" | "company";
      id?: string;
    },
    value: number | string
  ) => {
    update({
      type: "setDrivenDistance",
      payload: { from, to, distance: parseFloat(`${value}`) || 0 },
    });
  };

  const setTollDistance = (
    from: {
      type: "loadingAddress" | "unloadingAddress" | "company";
      id?: string;
    },
    to: {
      type: "loadingAddress" | "unloadingAddress" | "company";
      id?: string;
    },
    value: number | string
  ) => {
    update({
      type: "setDrivenDistance",
      payload: { from, to, tollDistance: parseFloat(`${value}`) || 0 },
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary">
        Adressen
      </Typography>
      {loadingAddresses.map((address, index) => (
        <div className={classes.formSection} key={address.id}>
          <div>
            <Typography variant="overline">
              {loadingAddresses.length > 1 && `${index + 1}. `}
              Be&shy;lade&shy;stelle
            </Typography>
            {loadingAddresses.length > 1 && (
              <IconButton
                onClick={() => {
                  update({ type: "removeLoadingAddress", payload: address.id });
                  snackbar.showMessage(
                    "Die Beladestelle wurde entfernt.",
                    "Rückgängig",
                    () => {
                      update({ type: "addLoadingAddress", payload: address });
                    },
                    undefined,
                    () => {
                      Promise.all(
                        (address.photos ?? []).map((photo) =>
                          database.removeAttachment(photo.id)
                        )
                      ).catch(() =>
                        console.warn(
                          "Could not remove all photos of a removed address"
                        )
                      );
                    }
                  );
                }}
              >
                <TrashCanOutline />
              </IconButton>
            )}
          </div>
          <div>
            <div className={classes.flexRow}>
              <FormControl
                variant="filled"
                margin="dense"
                fullWidth
                className={classes.shortInput}
              >
                <InputLabel>Anrede</InputLabel>
                <Select
                  input={<FilledInput />}
                  value={address.formOfAddress}
                  onChange={(e) =>
                    update({
                      type: "setLoadingAddressField",
                      payload: {
                        id: address.id,
                        field: "formOfAddress",
                        value: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value="Frau">Frau</MenuItem>
                  <MenuItem value="Herr">Herr</MenuItem>
                  <MenuItem value="Firma">Firma</MenuItem>
                  <MenuItem value="">Keine Angabe</MenuItem>
                </Select>
              </FormControl>
              {address.formOfAddress === "Firma" && (
                <TextField
                  label="Firma"
                  variant="filled"
                  margin="dense"
                  fullWidth
                  value={address.company ?? ""}
                  onChange={(value) =>
                    update({
                      type: "setLoadingAddressField",
                      payload: {
                        id: address.id,
                        field: "company",
                        value,
                      },
                    })
                  }
                />
              )}
              <TextField
                label="Vorname"
                variant="filled"
                margin="dense"
                fullWidth
                value={address.firstname}
                onChange={(value) =>
                  update({
                    type: "setLoadingAddressField",
                    payload: {
                      id: address.id,
                      field: "firstname",
                      value,
                    },
                  })
                }
              />
              <TextField
                label="Nachname"
                variant="filled"
                margin="dense"
                fullWidth
                value={address.lastname}
                onChange={(value) =>
                  update({
                    type: "setLoadingAddressField",
                    payload: {
                      id: address.id,
                      field: "lastname",
                      value,
                    },
                  })
                }
              />
            </div>
            <div className={classes.flexRow}>
              <TextField
                label="Anschrift"
                variant="filled"
                margin="dense"
                fullWidth
                multiline
                rowsMax={2}
                value={address.address}
                onChange={(value) =>
                  update({
                    type: "setLoadingAddressField",
                    payload: {
                      id: address.id,
                      field: "address",
                      value: `${value}`.trim(),
                    },
                  })
                }
              />
            </div>
            <div className={classes.flexRow}>
              <TextField
                label="Postleitzahl"
                variant="filled"
                margin="dense"
                fullWidth
                className={classes.shortInput}
                value={address.zipCode}
                onChange={(value) =>
                  update({
                    type: "setLoadingAddressField",
                    payload: {
                      id: address.id,
                      field: "zipCode",
                      value,
                    },
                  })
                }
              />
              <TextField
                label="Ort"
                variant="filled"
                margin="dense"
                fullWidth
                value={address.city}
                onChange={(value) =>
                  update({
                    type: "setLoadingAddressField",
                    payload: {
                      id: address.id,
                      field: "city",
                      value,
                    },
                  })
                }
              />
            </div>
            <div className={classes.flexRow}>
              <TextField
                label="Telefonnummer"
                variant="filled"
                margin="dense"
                fullWidth
                type="tel"
                value={address.phoneNumber ?? ""}
                onChange={(value) =>
                  update({
                    type: "setLoadingAddressField",
                    payload: {
                      id: address.id,
                      field: "phoneNumber",
                      value,
                    },
                  })
                }
              />
              <TextField
                type="number"
                label="Etage"
                variant="filled"
                margin="dense"
                fullWidth
                className={classes.shortInput}
                value={address.floor ?? ""}
                onChange={(value) =>
                  update({
                    type: "setLoadingAddressField",
                    payload: {
                      id: address.id,
                      field: "floor",
                      value,
                    },
                  })
                }
              />
              <TextField
                type="number"
                label="Weg zum Haus"
                variant="filled"
                margin="dense"
                fullWidth
                className={classes.mediumInput}
                value={address.walkingDistance ?? ""}
                onChange={(value) =>
                  update({
                    type: "setLoadingAddressField",
                    payload: {
                      id: address.id,
                      field: "walkingDistance",
                      value,
                    },
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.flexRow}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Außenaufzug"
                  checked={!!address.elevator}
                  onChange={(e, checked) =>
                    update({
                      type: "setLoadingAddressField",
                      payload: {
                        id: address.id,
                        field: "elevator",
                        value: checked,
                      },
                    })
                  }
                  className={classes.checkboxLabel}
                />
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Halteverbotszone"
                  checked={!!address.noParkingZone}
                  onChange={(e, checked) =>
                    update({
                      type: "setLoadingAddressField",
                      payload: {
                        id: address.id,
                        field: "noParkingZone",
                        value: checked,
                      },
                    })
                  }
                  className={classes.checkboxLabel}
                />
              </FormGroup>
              <TextField
                fullWidth
                label="Gebühr für HV-Zone"
                variant="filled"
                margin="dense"
                className={classes.mediumInput}
                value={
                  address.noParkingZone ? address.noParkingZoneFees ?? "" : ""
                }
                disabled={!address.noParkingZone}
                onChange={(value) =>
                  update({
                    type: "setLoadingAddressField",
                    payload: {
                      id: address.id,
                      field: "noParkingZoneFees",
                      value,
                    },
                  })
                }
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </div>
            <TextField
              variant="filled"
              margin="dense"
              label="Notizen"
              placeholder="Notizen oder Anmerkungen zu dieser Beladestelle…"
              value={address.note ?? ""}
              onChange={(value) =>
                update({
                  type: "setLoadingAddressField",
                  payload: { id: address.id, field: "note", value },
                })
              }
              fullWidth
              multiline
            />
            <Photos
              className={classes.photos}
              photos={address.photos ?? []}
              onAddPhoto={async (photo) => {
                try {
                  const attachment = await database.addAttachment(
                    localMove,
                    photo.blob
                  );
                  update(
                    moveActions.addLoadingAddressPhoto({
                      id: address.id,
                      attachmentId: attachment.id,
                    })
                  );
                } catch (e) {
                  snackbar.showMessage(
                    "Das Foto konnte nicht hinzugefügt werden."
                  );
                }
              }}
              onRemovePhoto={async (id) => {
                try {
                  await database.removeAttachment(id);
                  update({
                    type: "removeLoadingAddressPhoto",
                    payload: { id: address.id, attachmentId: id },
                  });
                } catch (e) {
                  throw new Error("Das Foto konnte nicht entfernt werden.");
                }
              }}
              searchParamId="loadingPhoto"
            />
          </div>
        </div>
      ))}
      <Button
        variant="text"
        color="primary"
        className={classes.addAddressButton}
        onClick={() =>
          update({
            type: "addLoadingAddress",
            payload: {
              id: uuidv4(),
              formOfAddress: "",
              firstname: "",
              lastname: "",
              address: "",
              city: "",
              zipCode: "",
            },
          })
        }
      >
        <Plus />
        Weitere Beladestelle hinzufügen
      </Button>
      {move.unloadingAddresses.map((address, index) => (
        <div className={classes.formSection} key={address.id}>
          <div>
            <Typography variant="overline">
              {move.unloadingAddresses.length > 1 && `${index + 1}. `}
              Ent&shy;lade&shy;stelle
            </Typography>
            {move.unloadingAddresses.length > 1 && (
              <IconButton
                onClick={() => {
                  update({
                    type: "removeUnloadingAddress",
                    payload: address.id,
                  });
                  snackbar.showMessage(
                    "Die Entladestelle wurde entfernt.",
                    "Rückgängig",
                    () => {
                      update({ type: "addUnloadingAddress", payload: address });
                    },
                    undefined,
                    () => {
                      Promise.all(
                        (address.photos ?? []).map((photo) =>
                          database.removeAttachment(photo.id)
                        )
                      ).catch(() =>
                        console.warn(
                          "Could not remove all photos of a removed address"
                        )
                      );
                    }
                  );
                }}
              >
                <TrashCanOutline />
              </IconButton>
            )}
          </div>
          <div>
            <div className={classes.flexRow}>
              <FormControl
                variant="filled"
                margin="dense"
                fullWidth
                className={classes.shortInput}
              >
                <InputLabel>Anrede</InputLabel>
                <Select
                  input={<FilledInput />}
                  value={address.formOfAddress}
                  onChange={(e) =>
                    update({
                      type: "setUnloadingAddressField",
                      payload: {
                        id: address.id,
                        field: "formOfAddress",
                        value: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value="Frau">Frau</MenuItem>
                  <MenuItem value="Herr">Herr</MenuItem>
                  <MenuItem value="Firma">Firma</MenuItem>
                  <MenuItem value="">Keine Angabe</MenuItem>
                </Select>
              </FormControl>
              {address.formOfAddress === "Firma" && (
                <TextField
                  label="Firma"
                  variant="filled"
                  margin="dense"
                  fullWidth
                  value={address.company ?? ""}
                  onChange={(value) =>
                    update({
                      type: "setUnloadingAddressField",
                      payload: {
                        id: address.id,
                        field: "company",
                        value,
                      },
                    })
                  }
                />
              )}
              <TextField
                label="Vorname"
                variant="filled"
                margin="dense"
                fullWidth
                value={address.firstname}
                onChange={(value) =>
                  update({
                    type: "setUnloadingAddressField",
                    payload: {
                      id: address.id,
                      field: "firstname",
                      value,
                    },
                  })
                }
              />
              <TextField
                label="Nachname"
                variant="filled"
                margin="dense"
                fullWidth
                value={address.lastname}
                onChange={(value) =>
                  update({
                    type: "setUnloadingAddressField",
                    payload: {
                      id: address.id,
                      field: "lastname",
                      value,
                    },
                  })
                }
              />
            </div>
            <div className={classes.flexRow}>
              <TextField
                label="Anschrift"
                variant="filled"
                margin="dense"
                fullWidth
                multiline
                rowsMax={2}
                value={address.address}
                onChange={(value) =>
                  update({
                    type: "setUnloadingAddressField",
                    payload: {
                      id: address.id,
                      field: "address",
                      value: `${value}`.trim(),
                    },
                  })
                }
              />
            </div>
            <div className={classes.flexRow}>
              <TextField
                label="Postleitzahl"
                variant="filled"
                margin="dense"
                fullWidth
                className={classes.shortInput}
                value={address.zipCode}
                onChange={(value) =>
                  update({
                    type: "setUnloadingAddressField",
                    payload: {
                      id: address.id,
                      field: "zipCode",
                      value,
                    },
                  })
                }
              />
              <TextField
                label="Ort"
                variant="filled"
                margin="dense"
                fullWidth
                value={address.city}
                onChange={(value) =>
                  update({
                    type: "setUnloadingAddressField",
                    payload: {
                      id: address.id,
                      field: "city",
                      value,
                    },
                  })
                }
              />
            </div>
            <div className={classes.flexRow}>
              <TextField
                label="Telefonnummer"
                variant="filled"
                margin="dense"
                fullWidth
                type="tel"
                value={address.phoneNumber ?? ""}
                onChange={(value) =>
                  update({
                    type: "setUnloadingAddressField",
                    payload: {
                      id: address.id,
                      field: "phoneNumber",
                      value,
                    },
                  })
                }
              />
              <TextField
                type="number"
                label="Etage"
                variant="filled"
                margin="dense"
                fullWidth
                className={classes.shortInput}
                value={address.floor}
                onChange={(value) =>
                  update({
                    type: "setUnloadingAddressField",
                    payload: {
                      id: address.id,
                      field: "floor",
                      value,
                    },
                  })
                }
              />
              <TextField
                type="number"
                label="Weg zum Haus"
                variant="filled"
                margin="dense"
                fullWidth
                className={classes.mediumInput}
                value={address.walkingDistance ?? ""}
                onChange={(value) =>
                  update({
                    type: "setUnloadingAddressField",
                    payload: {
                      id: address.id,
                      field: "walkingDistance",
                      value,
                    },
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.flexRow}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Außenaufzug"
                  checked={!!address.elevator}
                  onChange={(e, checked) =>
                    update({
                      type: "setUnloadingAddressField",
                      payload: {
                        id: address.id,
                        field: "elevator",
                        value: checked,
                      },
                    })
                  }
                  className={classes.checkboxLabel}
                />
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Halteverbotszone"
                  checked={!!address.noParkingZone}
                  onChange={(e, checked) =>
                    update({
                      type: "setUnloadingAddressField",
                      payload: {
                        id: address.id,
                        field: "noParkingZone",
                        value: checked,
                      },
                    })
                  }
                  className={classes.checkboxLabel}
                />
              </FormGroup>
              <TextField
                fullWidth
                label="Gebühr für HV-Zone"
                variant="filled"
                margin="dense"
                className={classes.mediumInput}
                value={
                  address.noParkingZone ? address.noParkingZoneFees ?? "" : ""
                }
                disabled={!address.noParkingZone}
                onChange={(value) =>
                  update({
                    type: "setUnloadingAddressField",
                    payload: {
                      id: address.id,
                      field: "noParkingZoneFees",
                      value,
                    },
                  })
                }
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </div>
            <TextField
              variant="filled"
              margin="dense"
              label="Notizen"
              placeholder="Notizen oder Anmerkungen zu dieser Entladestelle…"
              value={address.note ?? ""}
              onChange={(value) =>
                update({
                  type: "setUnloadingAddressField",
                  payload: { id: address.id, field: "note", value },
                })
              }
              fullWidth
              multiline
            />
            <Photos
              className={classes.photos}
              photos={address.photos ?? []}
              onAddPhoto={async (photo) => {
                try {
                  const attachment = await database.addAttachment(
                    localMove,
                    photo.blob
                  );
                  update(
                    moveActions.addUnloadingAddressPhoto({
                      id: address.id,
                      attachmentId: attachment.id,
                    })
                  );
                } catch (e) {
                  snackbar.showMessage(
                    "Das Foto konnte nicht hinzugefügt werden."
                  );
                }
              }}
              onRemovePhoto={async (id) => {
                try {
                  await database.removeAttachment(id);
                  update({
                    type: "removeUnloadingAddressPhoto",
                    payload: { id: address.id, attachmentId: id },
                  });
                } catch (e) {
                  throw new Error("Das Foto konnte nicht entfernt werden.");
                }
              }}
              searchParamId="unloadingPhoto"
            />
          </div>
        </div>
      ))}
      <Button
        variant="text"
        color="primary"
        className={classes.addAddressButton}
        onClick={() =>
          update({
            type: "addUnloadingAddress",
            payload: {
              id: uuidv4(),
              formOfAddress: "",
              firstname: "",
              lastname: "",
              address: "",
              city: "",
              zipCode: "",
            },
          })
        }
      >
        <Plus />
        Weitere Entladestelle hinzufügen
      </Button>
      <div className={classes.formSection}>
        <Typography variant="overline">Gefahrene Kilometer</Typography>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Von</TableCell>
                <TableCell>Nach</TableCell>
                <TableCell>Kilometer</TableCell>
                <TableCell>mautpflichtig</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingAddresses.map((address, i) => {
                const distance = move.drivenDistances.find(
                  (d) =>
                    d.from.type === "company" &&
                    d.to.id === address.id &&
                    d.to.type === "loadingAddress"
                ) ?? {
                  distance: 0,
                  tollDistance: 0,
                  from: { type: "company" },
                  to: { type: "loadingAddress", id: address.id },
                };
                return (
                  <TableRow key={address.id}>
                    <TableCell>Elze (Fa. Wurzel)</TableCell>
                    <TableCell>
                      {address.city}
                      {isCityAmbiguous &&
                        ` (${
                          loadingAddresses.length > 1 ? `${i + 1}. ` : ""
                        }Beladestelle)`}
                    </TableCell>
                    <TableCell className={classes.distanceCell}>
                      <TextField
                        fullWidth
                        margin="none"
                        variant="filled"
                        size="small"
                        className={classes.distanceField}
                        value={distance.distance}
                        onChange={(value) =>
                          setTotalDistance(distance.from, distance.to, value)
                        }
                        type="number"
                      />
                    </TableCell>
                    <TableCell className={classes.distanceCell}>
                      <TextField
                        fullWidth
                        margin="none"
                        variant="filled"
                        size="small"
                        className={classes.distanceField}
                        value={distance.tollDistance}
                        onChange={(value) =>
                          setTollDistance(distance.from, distance.to, value)
                        }
                        type="number"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {loadingAddresses.flatMap((address, i) =>
                move.unloadingAddresses.map((unloadingAddress, j) => {
                  const distance = move.drivenDistances.find(
                    (d) =>
                      d.from.type === "loadingAddress" &&
                      d.from.id === address.id &&
                      d.to.id === unloadingAddress.id &&
                      d.to.type === "unloadingAddress"
                  ) ?? {
                    distance: 0,
                    tollDistance: 0,
                    from: { type: "loadingAddress", id: address.id },
                    to: { type: "unloadingAddress", id: unloadingAddress.id },
                  };
                  return (
                    <TableRow key={`${address.id}-${unloadingAddress.id}`}>
                      <TableCell>
                        {address.city}
                        {isCityAmbiguous &&
                          ` (${
                            loadingAddresses.length > 1 ? `${i + 1}. ` : ""
                          }Beladestelle)`}
                      </TableCell>
                      <TableCell>
                        {unloadingAddress.city}
                        {isCityAmbiguous &&
                          ` (${
                            move.unloadingAddresses.length > 1
                              ? `${j + 1}. `
                              : ""
                          }Entladestelle)`}
                      </TableCell>
                      <TableCell className={classes.distanceCell}>
                        <TextField
                          fullWidth
                          margin="none"
                          variant="filled"
                          size="small"
                          className={classes.distanceField}
                          value={distance.distance}
                          onChange={(value) =>
                            setTotalDistance(distance.from, distance.to, value)
                          }
                          type="number"
                        />
                      </TableCell>
                      <TableCell className={classes.distanceCell}>
                        <TextField
                          fullWidth
                          margin="none"
                          variant="filled"
                          size="small"
                          className={classes.distanceField}
                          value={distance.tollDistance}
                          onChange={(value) =>
                            setTollDistance(distance.from, distance.to, value)
                          }
                          type="number"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
              {move.unloadingAddresses.map((address, i) => {
                const distance = move.drivenDistances.find(
                  (d) =>
                    d.from.id === address.id &&
                    d.from.type === "unloadingAddress" &&
                    d.to.type === "company"
                ) ?? {
                  distance: 0,
                  tollDistance: 0,
                  from: { type: "unloadingAddress", id: address.id },
                  to: { type: "company" },
                };
                return (
                  <TableRow key={address.id}>
                    <TableCell>
                      {address.city}
                      {isCityAmbiguous &&
                        ` (${
                          move.unloadingAddresses.length > 1 ? `${i + 1}. ` : ""
                        }Entladestelle)`}
                    </TableCell>
                    <TableCell>Elze (Fa. Wurzel)</TableCell>
                    <TableCell className={classes.distanceCell}>
                      <TextField
                        fullWidth
                        margin="none"
                        variant="filled"
                        size="small"
                        className={classes.distanceField}
                        value={distance.distance}
                        onChange={(value) =>
                          setTotalDistance(distance.from, distance.to, value)
                        }
                        type="number"
                      />
                    </TableCell>
                    <TableCell className={classes.distanceCell}>
                      <TextField
                        fullWidth
                        margin="none"
                        variant="filled"
                        size="small"
                        className={classes.distanceField}
                        value={distance.tollDistance}
                        onChange={(value) =>
                          setTollDistance(
                            distance.from,
                            distance.to,
                            parseFloat(`${value}`) || 0
                          )
                        }
                        type="number"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={2} align="right">
                  Gesamt:
                </TableCell>
                <TableCell align="right">
                  {move.drivenDistances
                    .reduce((sum, distance) => sum + distance.distance, 0)
                    .toLocaleString()}{" "}
                  km
                </TableCell>
                <TableCell align="right">
                  {move.drivenDistances
                    .reduce((sum, distance) => sum + distance.tollDistance, 0)
                    .toLocaleString()}{" "}
                  km
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Addresses);
