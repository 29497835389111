import { Plugins } from "@capacitor/core";
import { useEffect, useState } from "react";

const { Network } = Plugins;

export default function useIsOnline() {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    let stale = false;
    (async () => {
      const status = await Network.getStatus();
      if (!stale) {
        setIsOnline(status.connected);
      }
    })();
    const listener = Network.addListener("networkStatusChange", (status) => {
      setIsOnline(status.connected);
    });
    return () => {
      listener.remove();
      stale = true;
    };
  }, []);

  return isOnline;
}
