import React from "react";
import {
  Button,
  Checkbox,
  createStyles,
  FilledInput,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { ApplyOnBlurTextField as TextField } from "../../../../components/ApplyOnBlurTextField";
import { useCurrentMove } from "../../containers/Move";
import LocalizedDatePicker from "../LocalizedDatePicker";
import { useSynchronizedData } from "../../../synchronization/SynchronizedDataContext";
import { nanoid } from "nanoid";
import { moveActions } from "@wurzel/uzb-sync";
import { TooltipIconButton } from "@wa/werkstoff-core";
import { TrashCanOutline } from "mdi-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: theme.spacing(2),
      flex: 1,
    },
    checkbox: {
      marginBottom: theme.spacing(2),
      flex: 1,
      display: "flex",
    },
    flexRow: {
      display: "flex",
      "& > *:not(button)": {
        flex: 1,
      },
      "& > button": {
        alignSelf: "center",
      },
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1.5),
      },
    },
    shortInput: {
      maxWidth: 140,
    },
    price: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *": {
        textAlign: "right",
        flex: 1,
      },
    },
    additionalServices: {
      width: "100%",
    },
    addAdditionalServiceButton: {
      float: "right",
    },
  });

function OfferForm({ classes }: WithStyles<typeof styles>) {
  const {
    move: { data: move },
    update,
  } = useCurrentMove();
  const { configuration } = useSynchronizedData();

  return (
    <div>
      <div className={classes.flexRow}>
        <LocalizedDatePicker
          autoOk
          label="Rechnungsdatum"
          inputVariant="filled"
          margin="dense"
          value={move.documentData?.invoice?.date ?? null}
          onChange={(date) => {
            update({
              type: "setField",
              payload: {
                field: "documentData.invoice.date",
                value: date,
              },
            });
          }}
          clearable
          className={classes.textField}
        />
        <TextField
          variant="filled"
          margin="dense"
          label="Rechnungsnummer"
          fullWidth
          className={classes.textField}
          value={move.documentData?.invoice?.number ?? ""}
          onChange={(value) =>
            update({
              type: "setField",
              payload: {
                field: "documentData.invoice.number",
                value,
              },
            })
          }
        />
      </div>
      <TextField
        variant="filled"
        margin="dense"
        label="Sendungsnummer"
        fullWidth
        className={classes.textField}
        value={move.documentData?.invoice?.sendungsnummer ?? ""}
        onChange={(value) =>
          update({
            type: "setField",
            payload: {
              field: "documentData.invoice.sendungsnummer",
              value,
            },
          })
        }
      />
      <FormControlLabel
        className={classes.checkbox}
        control={<Checkbox color="primary" />}
        label="Faltkartons abholen"
        checked={!!move.documentData?.invoice?.pickupFoldingBoxes}
        onChange={(e, checked) =>
          update({
            type: "setField",
            payload: {
              field: "documentData.invoice.pickupFoldingBoxes",
              value: checked,
            },
          })
        }
      />
      <Typography variant="overline" color="primary">
        Zusätzliche Leistungen
      </Typography>
      <div className={classes.additionalServices}>
        {move.documentData?.invoice?.additionalServices?.map((service) => {
          const item = configuration.invoiceItems?.find(
            (i) => i.id === service.predefinedId
          );
          return (
            <div className={classes.flexRow} key={service.id}>
              <FormControl fullWidth margin="dense" variant="filled">
                <InputLabel>Bezeichnung</InputLabel>
                <Select
                  fullWidth
                  input={<FilledInput />}
                  value={service.predefinedId ?? ""}
                  onChange={(e) =>
                    update(
                      moveActions.updateInvoicePosition({
                        id: service.id,
                        predefinedId: e.target.value as string,
                      })
                    )
                  }
                >
                  {configuration.invoiceItems?.map((invoiceItem) => (
                    <MenuItem value={invoiceItem.id}>
                      {invoiceItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                type="number"
                label="Menge"
                variant="filled"
                margin="dense"
                fullWidth
                value={item?.unit != null ? service.amount : ""}
                onChange={(value) =>
                  update(
                    moveActions.updateInvoicePosition({
                      id: service.id,
                      amount: value as number,
                    })
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {getUnit(item?.unit)}
                    </InputAdornment>
                  ),
                }}
                disabled={item?.unit == null}
                className={classes.shortInput}
              />
              {item?.price != null && item?.price > 0 ? (
                <div className={classes.price}>
                  <div>
                    {item.price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €/{getUnit(item.unit)}
                  </div>
                  <div>
                    {(item.price * (service.amount || 0)).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}{" "}
                    €
                  </div>
                </div>
              ) : (
                <TextField
                  type="number"
                  label="Preis"
                  variant="filled"
                  margin="dense"
                  fullWidth
                  value={service.price}
                  onChange={(value) =>
                    update(
                      moveActions.updateInvoicePosition({
                        id: service.id,
                        price: value as number,
                      })
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
              )}
              <TooltipIconButton
                tooltip="Entfernen"
                onClick={() =>
                  update(moveActions.removeInvoicePosition({ id: service.id }))
                }
              >
                <TrashCanOutline />
              </TooltipIconButton>
            </div>
          );
        })}
      </div>
      <Button
        variant="text"
        color="primary"
        className={classes.addAdditionalServiceButton}
        onClick={() => {
          update(
            moveActions.addInvoicePosition({
              id: nanoid(),
            })
          );
        }}
      >
        Leistung hinzufügen
      </Button>
    </div>
  );
}

export default withStyles(styles)(OfferForm);

function getUnit(unit: string | undefined | null) {
  switch (unit) {
    case "ton":
      return "t";
    case "kilogram":
      return "kg";
    case "meter":
      return "m";
    case "piece":
      return "Stk.";
    case "hour":
      return "Std.";
    default:
      return "";
  }
}
