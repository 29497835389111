import React from "react";
import { DateTimePicker, DateTimePickerProps } from "@material-ui/pickers";

export default function LocalizedDateTimePicker(props: DateTimePickerProps) {
  return (
    <DateTimePicker
      ampm={false}
      format="dd.MM.yyyy, HH:mm"
      clearLabel="Leeren"
      cancelLabel="Abbrechen"
      {...props}
    />
  );
}
