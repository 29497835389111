import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./App.css";
import Application from "./modules/core/components/Application";
import MovesContainer from "./modules/moves/containers/Moves";
import MoveContainer from "./modules/move/containers/Move";
import { SynchronizedDataProvider } from "./modules/synchronization/SynchronizedDataContext";

const history = createBrowserHistory();

function App() {
  return (
    <SynchronizedDataProvider>
      <Router history={history}>
        <Application>
          <Switch>
            <Route path="/" exact component={MovesContainer} />
            <Route path="/moves" exact component={MovesContainer} />
            <Route path="/moves/:id" component={MoveContainer} />
          </Switch>
        </Application>
      </Router>
    </SynchronizedDataProvider>
  );
}

export default App;
