import React, { useCallback } from "react";
import {
  createStyles,
  List,
  ListItemIcon,
  MenuItem,
  Theme,
  Tooltip,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import AppBar from "../../../components/AppBar";
import { useCurrentMove } from "../containers/Move";
import { useHistory } from "react-router-dom";
import {
  AccountDetailsOutline,
  SofaOutline,
  MapMarkerMultipleOutline,
  ChevronLeft,
  CalendarOutline,
  CalculatorVariantOutline,
  AccountGroupOutline,
  PackageVariant,
  FileDocumentOutline,
} from "mdi-material-ui";
import { OverflowMenu } from "@wa/werkstoff-core";
import { useAuthenticationContext } from "../../core/AuthenticationContext";
import { useSnackbar } from "material-ui-snackbar-provider";
import {
  LocalMove,
  RemoteMove,
} from "../../synchronization/database/IDatabase";
import { Move as IMove } from "@wurzel/uzb-sync";
import RecalculationIcon from "../../../components/RecalculationIcon";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: `calc(100vh - ${theme.spacing(
        5.5
      )}px - var(--safe-area-inset-top))`,
    },
    container: {
      flex: 1,
      display: "flex",
      overflow: "hidden",
      paddingLeft: theme.spacing(6),
    },
    overflowMenu: {
      padding: theme.spacing(1.25),
      marginRight: theme.spacing(-3),
    },
    sidebar: {
      width: theme.spacing(6),
      padding: theme.spacing(2, 0),
      position: "fixed",
      top: `calc(${theme.spacing(5.5)}px + var(--safe-area-inset-top))`,
      left: 0,
    },
    content: {
      flex: 1,
      padding: theme.spacing(3),
      overflowY: "auto",
    },
    menuItem: {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      padding: theme.spacing(1.5, 1.5),
      "&$selected": {
        "& .MuiListItemIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    },
    selected: {},
  });

interface MoveMenuItemProps {
  title: string;
  pathname: string;
  icon: React.ReactNode;
}

const MoveMenuItem = withStyles(styles)(
  ({
    classes,
    title,
    pathname,
    icon,
  }: MoveMenuItemProps & WithStyles<typeof styles>) => {
    const { move } = useCurrentMove();
    const { location, push } = useHistory();

    const handleNavigate = useCallback(
      (target: string) => {
        const newPath = `/moves/${move.id}${target}`;
        if (newPath !== location.pathname) {
          push(newPath);
        }
      },
      [push, move.id, location.pathname]
    );

    return (
      <Tooltip
        title={title}
        disableFocusListener
        placement="right"
        enterDelay={200}
      >
        <MenuItem
          onClick={() => handleNavigate(pathname)}
          selected={location.pathname.endsWith(`/${move.id}${pathname}`)}
          button
          classes={{
            root: classes.menuItem,
            selected: classes.selected,
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
        </MenuItem>
      </Tooltip>
    );
  }
);

interface MoveProps {
  children: React.ReactNode;
  onCreateCopy?: () => void;
  onExport?: () => void;
}

export function getMoveTitle(move: LocalMove | RemoteMove) {
  const moveData: IMove | RemoteMove = "customer" in move ? move : move.data;
  return moveData.customer.firstname || moveData.customer.lastname
    ? `Umzug ${moveData.customer.firstname ?? ""} ${moveData.customer
        .lastname ?? ""}`.trim()
    : "Neuer Umzug";
}

function Move({
  classes,
  children,
  onCreateCopy,
  onExport,
}: MoveProps & WithStyles<typeof styles>) {
  const { move, synchronize } = useCurrentMove();
  const auth = useAuthenticationContext();
  const { push } = useHistory();
  const snackbar = useSnackbar();

  const handleSynchronize = async () => {
    try {
      const { failedAttachments } = await synchronize();
      if (failedAttachments.length > 0) {
        snackbar.showMessage(
          "Der Umzug wurde synchronisiert, einige Fotos konnten nicht synchronisiert werden."
        );
      } else {
        snackbar.showMessage("Der Umzug wurde synchronisiert.");
      }
    } catch (e) {
      console.error("Synchronization failed", e);
      if (e.extensions?.exception?.status === 401) {
        snackbar.showMessage(
          "Zur Synchronisierung ist eine Anmeldung erforderlich."
        );
        auth.showLoginDialog(handleSynchronize);
      } else {
        snackbar.showMessage("Der Umzug konnte nicht synchronisiert werden.");
      }
    }
  };

  return (
    <div className={classes.root}>
      <AppBar
        title={getMoveTitle(move)}
        leftIcon={ChevronLeft}
        onButtonClick={() => push("/moves")}
      >
        <OverflowMenu className={classes.overflowMenu}>
          <MenuItem onClick={handleSynchronize}>Synchronisieren</MenuItem>
          {onCreateCopy && (
            <MenuItem onClick={onCreateCopy}>Kopie anlegen</MenuItem>
          )}
          {onExport && <MenuItem onClick={onExport}>Exportieren</MenuItem>}
        </OverflowMenu>
      </AppBar>
      <div className={classes.container}>
        <List className={classes.sidebar}>
          <MoveMenuItem
            pathname=""
            title="Umzugsdaten"
            icon={<AccountDetailsOutline />}
          />
          <MoveMenuItem
            pathname="/addresses"
            title="Adressen"
            icon={<MapMarkerMultipleOutline />}
          />
          <MoveMenuItem
            pathname="/events"
            title="Termine"
            icon={<CalendarOutline />}
          />
          <MoveMenuItem
            pathname="/furniture"
            title="Umzugsgutliste"
            icon={<SofaOutline />}
          />
          <MoveMenuItem
            pathname="/packingMaterial"
            title="Packmaterial"
            icon={<PackageVariant />}
          />
          <MoveMenuItem
            pathname="/staff"
            title="Personal"
            icon={<AccountGroupOutline />}
          />
          <MoveMenuItem
            pathname="/calculation"
            title="Kalkulation"
            icon={<CalculatorVariantOutline />}
          />
          <MoveMenuItem
            pathname="/recalculation"
            title="Nachkalkulation"
            icon={<RecalculationIcon />}
          />
          <MoveMenuItem
            pathname="/documents"
            title="Dokumente"
            icon={<FileDocumentOutline />}
          />
        </List>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Move);
