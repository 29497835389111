import { TextField, TextFieldProps } from "@material-ui/core";
import React, { ChangeEvent, FocusEvent, useCallback, useState } from "react";

interface ApplyOnBlurTextFieldProps
  extends Omit<TextFieldProps, "value" | "onChange"> {
  value: unknown;
  onChange: (
    newValue: string | number,
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export function ApplyOnBlurTextField({
  value,
  onChange,
  ...other
}: ApplyOnBlurTextFieldProps) {
  const [currentValue, setCurrentValue] = useState(value);

  const handleChangeValue = useCallback((e: ChangeEvent<any>) => {
    setCurrentValue(e.target.value);
  }, []);

  const [editing, setEditing] = useState(false);
  const handleStartEdit = useCallback(() => {
    setEditing(true);
    setCurrentValue(value);
  }, [value]);

  const handleEndEdit = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue =
        other.type === "number" && e.target.value.trim().length > 0
          ? parseFloat(e.target.value)
          : e.target.value;
      if (newValue !== value) {
        onChange(newValue, e);
      }
      setEditing(false);
    },
    [onChange, value, other.type]
  );

  return (
    <TextField
      value={editing ? currentValue : value}
      onChange={handleChangeValue}
      onFocus={handleStartEdit}
      onBlur={handleEndEdit}
      {...other}
    />
  );
}
