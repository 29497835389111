import React from "react";
import {
  Checkbox,
  createStyles,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import Photos from "./photos/Photos";
import { nanoid } from "nanoid";
import { useCurrentMove } from "../containers/Move";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
    },
    formField: {
      marginBottom: theme.spacing(1),
    },
    flexRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1.5),
      },
    },
    shortInput: {
      maxWidth: theme.spacing(15),
    },
    checkboxLabel: {
      marginRight: theme.spacing(3),
    },
    photos: {
      marginTop: theme.spacing(1),
    },
  });

export type RoomItemFormValue = {
  name: string;
  size: string | number;
  assemblyTime?: string | number;
  disassemblyTime?: string | number;
  assembly: boolean;
  disassembly: boolean;
  disposal: boolean;
  note?: string;
  photos?: Array<{ id: string; blob?: Blob; url?: string }>;
  predefinedId?: string;
};

interface FurnitureFormProps {
  isNew?: boolean;
  value: RoomItemFormValue;
  onChange: (newValue: RoomItemFormValue) => void;
}

function FurnitureForm({
  classes,
  value,
  onChange,
  isNew,
}: FurnitureFormProps & WithStyles<typeof styles>) {
  const { getTemporaryBlobUrl } = useCurrentMove();

  return (
    <div className={classes.form}>
      <div className={classes.flexRow}>
        <TextField
          variant="filled"
          margin="dense"
          label="Bezeichnung"
          value={value.name ?? ""}
          onChange={(e) => onChange({ ...value, name: e.target.value })}
          fullWidth
          autoFocus={isNew}
          className={classes.formField}
          required
        />
        <TextField
          variant="filled"
          margin="dense"
          label="Größe"
          type="number"
          value={value.size ?? ""}
          onChange={(e) => onChange({ ...value, size: e.target.value })}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">RE</InputAdornment>,
          }}
          className={classes.shortInput}
          required
        />
      </div>
      <div className={classes.flexRow}>
        <TextField
          variant="filled"
          margin="dense"
          label="Demontagezeit"
          type="number"
          value={value.disassemblyTime ?? ""}
          onChange={(e) =>
            onChange({ ...value, disassemblyTime: e.target.value })
          }
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">Std.</InputAdornment>,
          }}
          className={classes.formField}
        />
        <TextField
          variant="filled"
          margin="dense"
          label="Montagezeit"
          type="number"
          value={value.assemblyTime ?? ""}
          onChange={(e) => onChange({ ...value, assemblyTime: e.target.value })}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">Std.</InputAdornment>,
          }}
          className={classes.formField}
        />
      </div>
      <FormGroup row>
        <FormControlLabel
          value="top"
          control={<Checkbox color="primary" />}
          label="Demontage"
          checked={!!value.disassembly}
          onChange={(e, checked) =>
            onChange({ ...value, disassembly: checked })
          }
          className={classes.checkboxLabel}
        />
        <FormControlLabel
          value="top"
          control={<Checkbox color="primary" />}
          label="Montage"
          checked={!!value.assembly}
          onChange={(e, checked) =>
            onChange({
              ...value,
              assembly: checked,
              disposal: value.disposal && !checked,
            })
          }
          className={classes.checkboxLabel}
        />
        <FormControlLabel
          value="top"
          control={<Checkbox color="primary" />}
          label="Entsorgung"
          checked={!!value.disposal}
          onChange={(e, checked) =>
            onChange({
              ...value,
              disposal: checked,
              assembly: value.assembly && !checked,
            })
          }
          className={classes.checkboxLabel}
        />
      </FormGroup>
      <TextField
        variant="filled"
        margin="dense"
        label="Notizen"
        placeholder="Notizen oder Anmerkungen zu diesem Gegenstand…"
        value={value.note ?? ""}
        onChange={(e) => onChange({ ...value, note: e.target.value })}
        fullWidth
        multiline
        className={classes.formField}
      />
      <Photos
        className={classes.photos}
        photos={value.photos ?? []}
        onAddPhoto={({ blob }) => {
          const id = nanoid();
          onChange({
            ...value,
            photos: [
              ...(value.photos ?? []),
              { id, blob, url: getTemporaryBlobUrl({ id, blob }) },
            ],
          });
        }}
        onRemovePhoto={(id) => {
          onChange({
            ...value,
            photos: value.photos?.filter((p) => p.id !== id),
          });
        }}
      />
    </div>
  );
}

export default withStyles(styles)(FurnitureForm);
