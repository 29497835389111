import React, {
  MouseEvent,
  SyntheticEvent,
  HTMLProps,
  useState,
  useEffect,
} from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  ButtonBase,
  Theme,
  IconButton,
} from "@material-ui/core";
import CameraPlus from "mdi-material-ui/CameraPlus";
import Close from "mdi-material-ui/Close";
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import { useSnackbar } from "material-ui-snackbar-provider";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useMutableSearchParam } from "../../../../hooks/useSearchParams";
import PhotoDialog from "./PhotoDialog";
import { AttachmentPhoto } from "../AttachmentPhoto";
const { Camera } = Plugins;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflowX: "auto",
    },
    image: {
      width: 100,
      height: 100,
      margin: theme.spacing(0, 1, 1, 0),
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: theme.shape.borderRadius,
      backgroundColor:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.09)"
          : "rgba(255, 255, 255, 0.09)",
      position: "relative",
      flexShrink: 0,
    },
    newImage: {
      width: 100,
      height: 100,
      margin: theme.spacing(0, 1, 1, 0),
      background:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.09)"
          : "rgba(255, 255, 255, 0.09)",
      color: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      flexShrink: 0,
      "&$error": {
        border: `1px solid ${theme.palette.error.main}`,
        color: `${theme.palette.error.main} !important`,
      },
    },
    removeImage: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      padding: theme.spacing(0.5),
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      position: "absolute",
      right: -theme.spacing(0.5),
      bottom: -theme.spacing(0.5),
      zIndex: 2,
      "&:hover": {
        background: theme.palette.background.paper,
      },
      "& svg": {
        fontSize: `${theme.spacing(2.5)}px`,
      },
    },
    "@global": {
      "#_capacitor-camera-input": {
        width: 0,
        height: 0,
      },
    },
    error: {},
  });

interface Props extends HTMLProps<HTMLDivElement> {
  photos: Array<{ id: string; url?: string }>;
  onAddPhoto: (photo: { blob: Blob }) => void;
  onRemovePhoto: (id: string) => void;
  showErrors?: boolean;
  minCount?: number;
  maxCount?: number;
  /**
   * Name of the search parameter that is used for the dialog, must be unique per view (e.g. if
   * there are multiple <Photos/> components)
   */
  searchParamId?: string;
}

function Photos({
  classes,
  className,
  photos,
  onAddPhoto,
  onRemovePhoto,
  showErrors,
  minCount,
  maxCount,
  searchParamId = "photo",
  ...other
}: Props & WithStyles<typeof styles>) {
  const snackbar = useSnackbar();

  const handleAddPhoto = React.useCallback(async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 95,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Prompt,
        promptLabelCancel: "Abbrechen",
        promptLabelHeader: "Foto hinzufügen",
        promptLabelPhoto: "Vorhandenes Foto auswählen",
        promptLabelPicture: "Foto aufnehmen",
      });
      if (image.webPath) {
        const blob = await fetch(image.webPath).then((r) => r.blob());
        onAddPhoto({ blob });
      }
    } catch (e) {
      // ignore (user probably cancelled photos app, ie. WUM-145)
    }
  }, [onAddPhoto]);

  const handleRemovePhoto = React.useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const id = e.currentTarget.dataset["image"] ?? "";
      const removedPhoto = photos.find((p) => p.id === id);
      if (removedPhoto) {
        onRemovePhoto(removedPhoto.id);
        // TODO implement restoring removed photos
        snackbar.showMessage(
          "Foto entfernt" /*, "Rückgängig", () => {
          onAddPhoto(removedPhoto);
        }*/
        );
      }
    },
    [photos, onRemovePhoto, snackbar]
  );

  const preventPropagation = React.useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const [fullscreenPhotoId, setFullscreenPhotoId] = useMutableSearchParam(
    searchParamId
  );
  const [fullscreenPhoto, setFullscreenPhoto] = useState<{
    id: string;
    url?: string;
  }>();

  const handleShowPhoto = React.useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const id = e.currentTarget.dataset["image"] ?? "";
      setFullscreenPhotoId(`${id}`);
    },
    [setFullscreenPhotoId]
  );

  useEffect(() => {
    if (fullscreenPhotoId != null) {
      const photo = photos.find((p) => p.id === fullscreenPhotoId);
      if (photo != null) {
        setFullscreenPhoto(photo);
      }
    }
  }, [fullscreenPhotoId, photos]);

  const history = useHistory();
  const handleHidePhoto = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const handleRemoveSelectedPhoto = React.useCallback(() => {
    if (fullscreenPhotoId) {
      const removedPhoto = photos.find((p) => p.id === fullscreenPhotoId);
      if (removedPhoto) {
        onRemovePhoto(removedPhoto.id);
        // TODO implement restoring removed photos
        snackbar.showMessage(
          "Foto entfernt" /*, "Rückgängig", () => {
          onAddPhoto(removedPhoto);
        }*/
        );
        handleHidePhoto();
      }
    }
  }, [fullscreenPhotoId, handleHidePhoto, onRemovePhoto, snackbar, photos]);

  return (
    <div className={clsx(className, classes.root)} {...other}>
      {photos.map(({ id, url }) =>
        url ? (
          <ButtonBase
            key={id}
            className={classes.image}
            style={{
              backgroundImage: `url(${url})`,
            }}
            data-image={id}
            onClick={handleShowPhoto}
          >
            <IconButton
              className={classes.removeImage}
              onMouseDown={preventPropagation}
              onTouchStart={preventPropagation}
              onClick={handleRemovePhoto}
              data-image={id}
            >
              <Close />
            </IconButton>
          </ButtonBase>
        ) : (
          <AttachmentPhoto key={id} id={id}>
            {({ url }) => (
              <ButtonBase
                className={classes.image}
                style={{
                  backgroundImage: url != null ? `url(${url})` : undefined,
                }}
                data-image={id}
                onClick={handleShowPhoto}
              >
                <IconButton
                  className={classes.removeImage}
                  onMouseDown={preventPropagation}
                  onTouchStart={preventPropagation}
                  onClick={handleRemovePhoto}
                  data-image={id}
                >
                  <Close />
                </IconButton>
              </ButtonBase>
            )}
          </AttachmentPhoto>
        )
      )}
      {(maxCount == null || photos.length < maxCount) && (
        <ButtonBase
          className={clsx(classes.newImage, {
            [classes.error]: showErrors && photos.length < (minCount ?? 0),
          })}
          onClick={handleAddPhoto}
        >
          <CameraPlus />
        </ButtonBase>
      )}
      <PhotoDialog
        photo={fullscreenPhoto}
        onClose={handleHidePhoto}
        onDelete={handleRemoveSelectedPhoto}
        open={fullscreenPhotoId != null}
      />
    </div>
  );
}

export default withStyles(styles)(Photos);
