import React from "react";
import { TemplateType } from "@wurzel/uzb-templates";
import OfferForm from "./OfferForm";
import WorkInstructionsForm from "./WorkInstructionsForm";
import InvoiceForm from "./InvoiceForm";

export const documents = [
  {
    type: TemplateType.OfferLetter,
    title: "Anschreiben Umzugsvertrag",
    description: "Anschreiben an den Kunden",
    configurationForm: (props: any) => <OfferForm {...props} mode="letter" />,
  },
  {
    type: TemplateType.Offer,
    title: "Umzugsvertrag",
    description: "Umzugsvertrag, optional mit Anschreiben",
    configurationForm: OfferForm,
  },
  {
    type: TemplateType.OfferConfirmation,
    title: "Auftragsbestätigung",
    description: "Auftragsbestätigung für den Kunden",
  },
  {
    type: TemplateType.WorkInstructions,
    title: "Arbeitsanweisung",
    description: "Arbeitsanweisung für Be- und Entladung",
    configurationForm: WorkInstructionsForm,
  },
  {
    type: TemplateType.WorkSlip,
    title: "Arbeitsschein",
    description: "Arbeitsschein und Schadenprotokoll",
  },
  {
    type: TemplateType.PackingMaterialDelivery,
    title: "Anlieferung Packmaterial",
    description: "Anlieferung des Packmaterials vorab",
  },
  {
    type: TemplateType.FeedbackLetter,
    title: "Anschreiben Fragebogen",
    description: "Anschreiben für Fragebogen und Rechnung",
  },
  {
    type: TemplateType.Invoice,
    title: "Rechnung",
    description: "Rechnung für den Kunden",
    configurationForm: InvoiceForm,
  },
  {
    type: TemplateType.PackingMaterialPickup,
    title: "Abholung Packmaterial",
    description: "Abholung des Packmaterials",
  },
];
