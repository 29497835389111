import { Capacitor } from "@capacitor/core";

export function isNative() {
  return Capacitor.getPlatform() === "ios";
}

export function getCordovaPlugin(name: string) {
  if ("cordova" in window) {
    return (window as any).cordova.plugins[name];
  }
}
