import React from "react";
import { Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { ApplyOnBlurTextField as TextField } from "../../../../components/ApplyOnBlurTextField";
import { useCurrentMove } from "../../containers/Move";

const styles = (theme: Theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
});

function WorkInstructionsForm({ classes }: WithStyles<typeof styles>) {
  const {
    move: { data: move },
    update,
  } = useCurrentMove();

  return (
    <div>
      <Typography variant="overline" color="primary">
        Beladestelle
      </Typography>
      <TextField
        variant="filled"
        margin="dense"
        multiline
        rows={1}
        rowsMax={5}
        label="Ablaufbeschreibung"
        fullWidth
        className={classes.textField}
        value={
          move.documentData?.workInstructions?.processDescriptionLoading ?? ""
        }
        onChange={(value) =>
          update({
            type: "setField",
            payload: {
              field: "documentData.workInstructions.processDescriptionLoading",
              value,
            },
          })
        }
      />
      <TextField
        variant="filled"
        margin="dense"
        multiline
        rows={1}
        rowsMax={5}
        label="Montagearbeiten"
        fullWidth
        className={classes.textField}
        value={move.documentData?.workInstructions?.assemblyWorkLoading ?? ""}
        onChange={(value) =>
          update({
            type: "setField",
            payload: {
              field: "documentData.workInstructions.assemblyWorkLoading",
              value,
            },
          })
        }
      />
      <TextField
        variant="filled"
        margin="dense"
        multiline
        rows={1}
        rowsMax={5}
        label="Halteverbotszone"
        fullWidth
        className={classes.textField}
        value={move.documentData?.workInstructions?.noParkingZoneLoading ?? ""}
        onChange={(value) =>
          update({
            type: "setField",
            payload: {
              field: "documentData.workInstructions.noParkingZoneLoading",
              value,
            },
          })
        }
      />

      <Typography variant="overline" color="primary">
        Entladestelle
      </Typography>
      <TextField
        variant="filled"
        margin="dense"
        multiline
        rows={1}
        rowsMax={5}
        label="Ablaufbeschreibung"
        fullWidth
        className={classes.textField}
        value={
          move.documentData?.workInstructions?.processDescriptionUnloading ?? ""
        }
        onChange={(value) =>
          update({
            type: "setField",
            payload: {
              field:
                "documentData.workInstructions.processDescriptionUnloading",
              value,
            },
          })
        }
      />
      <TextField
        variant="filled"
        margin="dense"
        multiline
        rows={1}
        rowsMax={5}
        label="Montagearbeiten"
        fullWidth
        className={classes.textField}
        value={move.documentData?.workInstructions?.assemblyWorkUnloading ?? ""}
        onChange={(value) =>
          update({
            type: "setField",
            payload: {
              field: "documentData.workInstructions.assemblyWorkUnloading",
              value,
            },
          })
        }
      />
      <TextField
        variant="filled"
        margin="dense"
        multiline
        rows={1}
        rowsMax={5}
        label="Halteverbotszone"
        fullWidth
        className={classes.textField}
        value={
          move.documentData?.workInstructions?.noParkingZoneUnloading ?? ""
        }
        onChange={(value) =>
          update({
            type: "setField",
            payload: {
              field: "documentData.workInstructions.noParkingZoneUnloading",
              value,
            },
          })
        }
      />
    </div>
  );
}

export default withStyles(styles)(WorkInstructionsForm);
