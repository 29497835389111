import React from "react";
import {
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { Move } from "@wurzel/uzb-sync";
import { useCurrentMove } from "../containers/Move";
import LocalizedDateTimePicker from "./LocalizedDateTimePicker";
import LocalizedDatePicker from "./LocalizedDatePicker";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    formSection: {
      display: "flex",
      marginBottom: theme.spacing(3),
      "& > *:first-child": {
        width: theme.spacing(16),
        textAlign: "right",
        paddingRight: theme.spacing(3),
        lineHeight: 1,
        paddingTop: theme.spacing(1),
        "& > .MuiTypography-root": {
          lineHeight: 1,
        },
      },
      "& > div:first-child": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      },
      "& > *:last-child": {
        paddingTop: theme.spacing(0.5),
        flex: 1,
      },
    },
    address: {
      "&:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },
    flexRow: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1.5),
      },
    },
  });

interface EventsProps {
  children: React.ReactNode;
}

function floorDate(date: Date | null, floorToMinutes: number): Date | null {
  if (date == null) {
    return null;
  }
  const remove = date.getTime() % (floorToMinutes * 60 * 1000);
  return new Date(date.getTime() - remove);
}

function Events({ classes }: EventsProps & WithStyles<typeof styles>) {
  const {
    move: { data: move },
    update,
  } = useCurrentMove();

  const loadingAddresses: Move["loadingAddresses"] =
    move.loadingAddresses?.length > 0
      ? move.loadingAddresses
      : [{ id: "default", ...move.customer }];
  const unloadingAddresses = move.unloadingAddresses;

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary">
        Termine
      </Typography>
      <div className={classes.formSection}>
        <Typography variant="overline">Beladung</Typography>
        <div>
          {loadingAddresses.map((address, index) => (
            <div key={address.id} className={classes.address}>
              {loadingAddresses.length > 1 && (
                <Typography color="primary">
                  {index + 1}. Beladestelle{" "}
                  {address.city && ` (${address.city})`}
                </Typography>
              )}
              <div className={classes.flexRow}>
                <LocalizedDateTimePicker
                  autoOk
                  label="Datum/Uhrzeit"
                  inputVariant="filled"
                  margin="dense"
                  minutesStep={30}
                  value={
                    move.loadingDates?.find((d) => d.id === address.id)?.date ??
                    null
                  }
                  onChange={(date) => {
                    update({
                      type: "setLoadingDate",
                      payload: {
                        id: address.id,
                        date: floorDate(date, 30),
                      },
                    });
                  }}
                  clearable
                />
                <LocalizedDateTimePicker
                  autoOk
                  label="Alternativ-Termin"
                  inputVariant="filled"
                  margin="dense"
                  minutesStep={30}
                  value={
                    move.loadingDates?.find((d) => d.id === address.id)
                      ?.alternateDate ?? null
                  }
                  onChange={(alternateDate) => {
                    update({
                      type: "setLoadingDate",
                      payload: {
                        id: address.id,
                        alternateDate: floorDate(alternateDate, 30),
                      },
                    });
                  }}
                  clearable
                />
                <LocalizedDatePicker
                  autoOk
                  label="Anlieferung Packmaterial"
                  inputVariant="filled"
                  margin="dense"
                  value={
                    move.loadingDates?.find((d) => d.id === address.id)
                      ?.packingMaterialDate ?? null
                  }
                  onChange={(packingMaterialDate) => {
                    update({
                      type: "setLoadingDate",
                      payload: {
                        id: address.id,
                        packingMaterialDate,
                      },
                    });
                  }}
                  clearable
                  clearLabel="Auf Abruf"
                  placeholder="auf Abruf"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.formSection}>
        <Typography variant="overline">Entladung</Typography>
        <div>
          {unloadingAddresses.map((address, index) => (
            <div key={address.id} className={classes.address}>
              {unloadingAddresses.length > 1 && (
                <Typography color="primary">
                  {index + 1}. Entladestelle{" "}
                  {address.city && ` (${address.city})`}
                </Typography>
              )}
              <div className={classes.flexRow}>
                <LocalizedDateTimePicker
                  autoOk
                  label="Datum/Uhrzeit"
                  inputVariant="filled"
                  margin="dense"
                  minutesStep={30}
                  value={
                    move.unloadingDates?.find((d) => d.id === address.id)
                      ?.date ?? null
                  }
                  onChange={(date) => {
                    update({
                      type: "setUnloadingDate",
                      payload: {
                        id: address.id,
                        date: floorDate(date, 30),
                      },
                    });
                  }}
                  clearable
                />
                <LocalizedDateTimePicker
                  autoOk
                  label="Alternativ-Termin"
                  inputVariant="filled"
                  margin="dense"
                  minutesStep={30}
                  value={
                    move.unloadingDates?.find((d) => d.id === address.id)
                      ?.alternateDate ?? null
                  }
                  onChange={(alternateDate) => {
                    update({
                      type: "setUnloadingDate",
                      payload: {
                        id: address.id,
                        alternateDate: floorDate(alternateDate, 30),
                      },
                    });
                  }}
                  clearable
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Events);
