import HttpStatus from "http-status-codes";
import { getCordovaPlugin } from "./capacitor";

/**
 * A fetch implementations that uses cordova-plugin-advanced-http if available.
 * @see https://kwao.io/blog/how-to-set-up-an-apollo-graphql-client-to-use-a-native-http-client-in-an-ionic-react-app/
 * @see https://github.com/silkimen/cordova-plugin-advanced-http
 * @param url URL
 * @param options Fetch options
 */
export const cordovaHttpFetchImpl = async (
  url: string,
  options?: RequestInit
) => {
  const httpPlugin = getCordovaPlugin("http");
  // if cordova is not available use web fetch
  if (!httpPlugin) {
    return fetch(url, options);
  }

  if (options?.body instanceof FormData) {
    httpPlugin.setDataSerializer("multipart");
  } else {
    httpPlugin.setDataSerializer("json");
  }
  await new Promise((resolve, reject) => {
    httpPlugin.setServerTrustMode("nocheck", resolve, () =>
      reject(new Error("Could not enable certificate pinning"))
    );
  });

  let response: any = await new Promise((resolve, reject) => {
    httpPlugin.sendRequest(
      url,
      options
        ? {
            ...options,
            data:
              typeof options.body === "string"
                ? JSON.parse(options.body)
                : options.body,
            method: options?.method?.toLowerCase() ?? "get",
          }
        : {},
      resolve,
      reject
    );
  });
  const responseBody =
    typeof response.data === `object`
      ? JSON.stringify(response.data)
      : response.data;
  const headers = new Headers();
  Object.entries(response.headers).forEach(([key, value]) => {
    headers.append(key, value as string);
  });
  return new Response(responseBody, {
    status: response.status,
    statusText: HttpStatus.getStatusText(response.status),
    headers,
  });
};
