import { gql } from "graphql.macro";
import { Configuration } from "@wurzel/uzb-sync";
import { executeQuery } from "./apolloClient";
import { IDatabase } from "./database/IDatabase";

const GET_CONFIGURATION = gql`
  {
    rooms {
      id
      name
      items {
        id
        name
        size
        assemblyTime
        disassemblyTime
        packingMaterial {
          amount
          type {
            id
          }
          inAdvance
        }
      }
    }
    packingMaterials {
      id
      order
      name
      price
      unit
    }
    staffCategories {
      consultant {
        id
        hourlyRate
        dailyRate
      }
      teamLeader {
        id
        dailyRate
        hourlyRate
      }
      carrier {
        id
        dailyRate
        hourlyRate
      }
      trainee {
        id
        dailyRate
        hourlyRate
      }
      temporaryEmployee {
        id
        dailyRate
        hourlyRate
      }
      glassPacker {
        id
        dailyRate
        hourlyRate
      }
    }
    calculationParameters {
      storageRent
      containerRent
      containerHandling
      containerTransport
      noParkingZone
      bulkyWasteDisposal
      kilometerRatePackingMaterial
      trailerThreshold
      insuranceFactor
      insuranceMinimum
      truckToll
      truckTollWithTrailer
    }
    vehicles {
      consultantCar {
        id
        dailyRate
      }
      furnitureTruck {
        id
        dailyRate
        hourlyRate
        kilometerRate
      }
      trailer {
        id
        dailyRate
        hourlyRate
        kilometerRate
      }
      staffCar {
        id
        dailyRate
        hourlyRate
        kilometerRate
      }
      lift {
        id
        dailyRate
      }
    }
    contractors {
      id
      name
      travelCost
      travelCostType
      hourlyRate
      workType
    }
    invoiceItems {
      id
      name
      unit
      price
    }
  }
`;

export async function updateConfiguration(
  database: IDatabase
): Promise<Configuration> {
  const configuration = await executeQuery(GET_CONFIGURATION);
  await database.setConfiguration(configuration);
  return configuration.data;
}
